.restricted-bm__wrapper {
    margin: 0 auto;
    padding: 56px 128px;
    color: #00223d;
}

@media (max-width: 991px) {
    .restricted-bm__wrapper {
        padding: 35px 68px;
    }
}

@media (max-width: 576px) {
    .restricted-bm__wrapper {
        padding: 16px;
    }
}

.restricted-bm__title {
    margin-bottom: 40px;
    font-size: 31px;
    font-weight: 300;
}

@media (max-width: 576px) {
    .restricted-bm__title {
        margin: 8px 0 24px;
        font-size: 19px;
    }
}

.restricted-bm__description {
    font-size: 14px;
    font-weight: 300;
    max-width: 100%;
}

.restricted-bm__description span {
    color: #007bff;
    cursor: pointer;
}

.restricted-bm__description span:hover {
    color: #0056b3;
    text-decoration: underline;
}

.restricted-bm__description:last-of-type {
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 300;
    max-width: 100%;
}

@media (max-width: 576px) {
    .restricted-bm__description {
        font-size: 13px;
    }
}

.restricted-bm__body-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
}