.special_offer-campaign-edit__wrapper {
  margin: 0 auto;
  padding: 36px 0;
  width: 100%;
}

.special_offer-campaign-edit-page {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
  max-width: 1024px;
  max-height: 900px;
}

@media (max-width: 991px) {
  .special_offer-campaign-edit-page {
    max-height: initial;
  }
}
