.choose-campaign__wrapper {
  margin: 0 auto;
  padding: 56px 128px;
  color: #00223d;
}

@media (max-width: 991px) {
  .choose-campaign__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .choose-campaign__wrapper {
    padding: 16px;
  }
}

.choose-campaign__title {
  margin-bottom: 32px;
  font-size: 31px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .choose-campaign__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.choose-campaign__description {
  font-size: 14px;
  font-weight: 300;
  max-width: 450px;
  margin-left: 29px;
  margin-bottom: 24px;
}

@media (max-width: 576px) {
  .choose-campaign__description {
    font-size: 13px;
  }
}

.choose-campaign__body-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8px;
}

.choose-campaign__body-wrapper h6 {
  margin-top: 24px;
}

.choose-campaign__body-wrapper label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-left: 16px;
  cursor: pointer;
}
