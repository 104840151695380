.install-callback__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #00223D;
    margin: 32px 0 16px;
}

.install-callback__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #00223D;
    max-width: 330px;
    margin: 0 auto;
}

.install-callback__text + .install-callback__text {
    margin-top: 16px;
}