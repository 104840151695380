.user-bm-error__wrapper {
    margin: 0 auto;
    padding: 56px 128px;
    color: #00223d;
  }
  
  @media (max-width: 991px) {
    .user-bm-error__wrapper {
      padding: 35px 68px;
    }
  }
  
  @media (max-width: 576px) {
    .user-bm-error__wrapper {
      padding: 16px;
    }
  }
  
  .user-bm-error__title {
    margin-bottom: 40px;
    font-size: 31px;
    font-weight: 300;
  }
  
  @media (max-width: 576px) {
    .user-bm-error__title {
      margin: 8px 0 24px;
      font-size: 19px;
    }
  }
  
  .user-bm-error__description {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 300;
  }
  
  @media (max-width: 576px) {
    .user-bm-error__description {
      font-size: 13px;
    }
  }

  .user-bm-error__body-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  