.footer-wrapper {
  position: relative;
  padding: 16px 32px;
}

.footer-wrapper .main-container {
  justify-content: space-between;
}

@media (max-width: 480px) {
  .footer-wrapper {
    padding: 0;
  }

  .footer-wrapper .main-container {
    flex-direction: column;
  }
}

.footer__section {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

@media (max-width: 480px) {
  .footer__section {
    padding: 0 0 16px;
    border-bottom: 1px solid rgb(0 34 61 / 0.08);
  }

  .footer__section:last-child {
    padding: 0;
    border-bottom: none;
  }
}

@media (max-width: 480px) {
  .footer__section_nav {
    padding: 16px 0;
  }
}

.footer__section_with-fb-marketing-badge {
  justify-content: center;
}

@media (max-width: 991px) and (min-width: 481px) {
  .footer__section_with-fb-marketing-badge {
    right: 47px;
  }
}

@media (max-width: 480px) {
  .footer__section_with-fb-marketing-badge {
    position: relative;
    left: 0;
    padding: 16px 0;
  }
}

.footer__section_corp-info {
  display: none;
  position: relative;
  padding: 16px 0 16px 40px;
}

.footer__section_corp-info::before {
  content: "";
  position: absolute;
  display: block;
  margin-right: 12px;
  top: calc(50% - (27px / 2));
  left: 16px;
  width: 28px;
  height: 27px;
  background: url("retargetapp-logo.svg");
  background-size: 28px 27px;
}

@media (max-width: 480px) {
  .footer__section_corp-info {
    display: flex;
  }
}

.footer__section_back-to-top {
  display: none;
  justify-content: center;
}

@media (max-width: 480px) {
  .footer__section_back-to-top {
    display: flex;
  }
}

.footer-section__item {
  padding: 0 16px;
}

.footer-section__item:first-child {
  padding: 0 16px 0 0;
}

@media (max-width: 480px) {
  .footer-section__item:first-child {
    padding: 0 16px;
  }
}

@media (max-width: 480px) {
  .footer-section__item {
    width: 100%;
    line-height: 2em;
  }
}

.footer-section__item_with-fb-marketing-badge,
.footer-section__item_with-fb-marketing-badge:first-child {
  padding: 0;
}

@media (max-width: 480px) {
  .footer-section__item_with-fb-marketing-badge {
    width: initial;
  }
}

.footer-section-item__link-icon {
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-bottom;
  width: 21px;
  height: 20px;
  filter: grayscale(100%);
}

.footer-section-item__link:hover .footer-section-item__link-icon {
  filter: none;
}

@media (max-width: 480px) {
  .footer-section-item__link-icon {
    display: none;
  }
}

.footer-section__item_back-to-top,
.footer-section__item_back-to-top:first-child {
  padding: 15px 0 18px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background: #fff;
  border: none;
  cursor: pointer;
  outline: none;
}

.footer-section__item_back-to-top:focus,
.footer-section__item_back-to-top:hover,
.footer-section__item_back-to-top:active {
  outline: none;
}

.footer-section-item__description_corp-info {
  margin: 0;
  color: rgb(0 34 61 / 0.8);
  font-size: 11px;
  font-weight: 300;
  line-height: 1.5em;
}

.footer-section-item__link {
  color: rgb(0 34 61 / 0.87);
  font-size: 14px;
  font-weight: 500;
}

.footer-section-item__link:hover,
.footer-section-item__link:focus {
  color: rgb(0 34 61 / 1);
  text-decoration: none;
  cursor: pointer;
}

.footer-section-item__link:active {
  color: rgb(0 34 61 / 0.8);
  text-decoration: none;
}

@media (max-width: 480px) {
  .footer-section-item__link {
    font-size: 17px;
  }
}

.footer-section-item__link_with-fb-marketing-badge {
  display: flex;
  width: 98px;
  height: 30px;
  color: transparent;
  overflow: hidden;
}

.footer-section-item__link_with-fb-marketing-badge img {
  width: 100%;
  height: 100%;
}

.footer-section-item__link_with-google-marketing-badge {
  display: flex;
  align-items: center;
  width: 86px;
  height: 30px;
  padding-right: 8px;
  margin-right: 24px;
  border-right: 1px solid #D6DADF;;
}

.footer-section-item__link_with-google-marketing-badge img {
  width: 100%;
}

@media (max-width: 480px) {
  .footer-section-item__link_with-fb-marketing-badge {
    background-position: center;
  }
}

.footer-section-item__link_with-fb-marketing-badge:hover,
.footer-section-item__link_with-fb-marketing-badge:focus,
.footer-section-item__link_with-fb-marketing-badge:active {
  color: transparent;
}

.footer-section-item__button {
  line-height: 23px;
  font-size: 17px;
  vertical-align: middle;
}

.footer-section-item__button_with-arrow {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  border: none;
  background: none;
  color: rgb(0 34 61 / 0.9);
  font-size: 17px;
}

.footer-section-item__button_with-arrow:hover,
.footer-section-item__button_with-arrow:focus {
  color: rgb(0 34 61 / 0.7);
  cursor: pointer;
  outline: none;
}

.footer-section-item__button_with-arrow:active {
  color: rgb(0 34 61 / 0.4);
}

.footer-section-item__button_with-arrow::before {
  content: "\2191";
  display: inline-block;
  font-size: 17px;
  line-height: 17px;
  vertical-align: middle;
  text-decoration: none;
}
