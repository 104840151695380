/* base styles which use in whole app */

/* link styles */
a {
  box-shadow: none !important;
}

.primary-link {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  color: #1696ff;
  letter-spacing: 0.25px;
  line-height: 18px;
  width: auto;
}

.primary-link:hover {
  text-decoration: underline;
}

.link {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: rgb(0 34 61 / 0.54);
  text-align: left;
  letter-spacing: 0.25px;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
}

.link:hover,
.link:focus,
.link:active {
  color: rgb(0 34 61 / 1);
}

.link.with-arrow {
  text-decoration: none;
}

.link.with-arrow span {
  text-decoration: underline;
}

.link.link-white {
  color: rgb(255 255 255 / 0.87);
}

.link.link-white:hover {
  color: rgb(255 255 255 / 1);
}

.primary-link.in-text {
  font-weight: 500;
  font-size: 13px;
  color: #1696FF;
  letter-spacing: 0.25px;
  cursor: pointer;
}

.primary-link.in-text:hover {
  color: rgb(22 150 255 / 1);
  text-decoration: underline;
}

.secondary-link.in-text {
  font-weight: 400;
  font-size: 12px;
  color: rgb(0 34 61 / 0.54);
  letter-spacing: 0.5px;
  text-decoration: underline;
}

.secondary-link.in-text:hover {
  color: rgb(0 34 61 / 1);
}

/* Additional styles for optimization goals */

.mt-10px {
  margin-top: 10px;
}

.pdg-v-5px {
  padding: 5px 0;
}
