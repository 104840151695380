.gl-dp {
    width: 266px;
    height: 190px;
    margin: 0 auto;
    background: url("img/background.svg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
}

.gl-dp__p {
    position: absolute;
    border-radius: 3px;
    overflow: hidden;
}

.gl-dp__p img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gl-dp__p--p1 {
    width: 106px;
    height: 37px;
    top: 41px;
    left: 8px;
}

.gl-dp__p--p2 {
    width: 48px;
    height: 52px;
    top: 127px;
    left: 66px;
}

.gl-dp__p--p3 {
    width: 134px;
    height: 16px;
    top: 8px;
    right: 9px;
}