.trial-badge-extended-popover-body {
  position: absolute;
  background: #fff url("./popover-stars.png") right bottom no-repeat;
  border-radius: 4px;
  padding: 13px 22px;
  height: 100%;
  width: 100%;
}

.trial-badge-extended-popover-body__button span[role="img"] {
  font-size: 13px;
}

.trial-badge-extended-popover-body p span[role="img"] {
  font-size: 13px;
}

.trial-badge-extended-popover-body__title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 6px;
}

.custom-glyph__span {
  font-size: 16px;
}
