.edit-campaign-details__wrapper {
  margin: 24px 6px;
  max-width: 1138px;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgb(151 151 151 / 0.4);
}

@media (min-width: 1190px) {
  .edit-campaign-details__wrapper {
    margin: 24px auto;
  }
}

@media (max-width: 991px) {
  .edit-campaign-details__wrapper {
    margin: 24px 12px;
  }
}

.edit-campaign-details__wrapper .button:not(:first-child) {
  margin: 0 0 0 16px;
}

.edit-campaign-details-cta-button__tips-wrapper {
  max-width: 215px;
}

.edit-campaign-details-cta-button__tips-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  color: transparent;
}

.edit-campaign-details-cta-button__tips-icon::before {
  box-shadow: none;
  color: transparent;
  top: 10px;
}

.edit-campaign-details-contact-us_link {
  display: inline-block;
  padding: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
}

.edit-campaign-details-contact-us_link:hover {
  cursor: pointer;
  outline: none;
}
