.dropdown-widget__toggle {
  padding: 0 30px 0 17px;
  max-width: 223px;
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: none;
  border-radius: 0;
  text-align: left;
}

.dropdown-widget__toggle.disabled,
.dropdown-widget__toggle[disabled] {
  padding-right: 17px;
  background-color: #fff;
  opacity: 1;
}

.dropdown-widget__toggle_in-navbar {
  height: 60px;
}

.dropdown-widget__toggle_small {
  width: 195px;
}

.dropdown-widget__toggle:not(:disabled):not(.disabled):hover {
  background-color: #f1f2f6;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}

.dropdown-widget__toggle:not(:disabled):not(.disabled):focus,
.dropdown-widget__toggle.btn-secondary:not(:disabled):not(.disabled):active,
.dropdown-widget__toggle.btn-secondary:not(:disabled):not(.disabled).active,
.show > .dropdown-widget__toggle.btn-secondary.dropdown-toggle {
  background-color: #ecedf1;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}

.dropdown-widget__toggle-icon {
  margin-right: 13px;
}

.dropdown-widget__toggle__description {
  overflow: hidden;
}

.dropdown-widget__toggle-title {
  margin: 0;
  color: rgb(0 34 61 / 0.87);
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-widget__toggle-subtitle {
  margin: 0;
  color: rgb(0 34 61 / 0.54);
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-widget__menu {
  padding: 0;
  margin: 0;
  width: 300px;
  background-color: #fff;
  border: 1px solid #c4cdd5;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 0.1);
}

.dropdown-widget__menu_small {
  width: 195px;
}

.dropdown-widget__menu_borderfree {
  padding: 12px 0;
}

.dropdown-widget__menu-item-wrapper,
.dropdown-widget__menu-item-wrapper:hover,
.dropdown-widget__menu-item-wrapper:focus,
.dropdown-widget__menu-item-wrapper:active {
  padding: 0;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  text-align: left;
}

.dropdown-widget__menu-item-wrapper:not(:only-child):not(:last-child) {
  border-bottom: 1px solid #c4cdd5;
}

.dropdown-widget__menu-item {
  padding: 11px 16px 11px 56px;
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  text-decoration: none;
  text-align: left;
}

.dropdown-widget__menu-item:hover {
  background-color: #f1f2f6;
  border-color: transparent;
  text-decoration: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.dropdown-widget__menu-item:focus,
.dropdown-widget__menu-item:active,
.dropdown-widget__menu-item.active {
  background-color: #ecedf1;
  border-color: transparent;
  text-decoration: none;
  box-shadow: none;
  outline: none;
}

.dropdown-widget__menu-item:active::before,
.dropdown-widget__menu-item.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 16px;
  width: 25px;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="check-icon" x="0px" y="0px" viewBox="0 0 24 18" enable-background="new 0 0 24 18" xml:space="preserve"><polygon id="Path" fill="%231E2F4C" points="8.6,17.9 0,9.3 2.4,6.9 8.6,13 21.6,0 24,2.4 "/></svg>') 50% no-repeat;
  background-size: 15px;
}

.dropdown-widget__menu-item.dropdown-widget__menu-link {
  padding-left: 94px;
}

.dropdown-widget__menu-item.dropdown-widget__menu-link::after {
  left: 48px;
}

.dropdown-widget__menu_small .dropdown-widget__menu-item:active::before,
.dropdown-widget__menu_small .dropdown-widget__menu-item.active::before {
  display: none;
}

.dropdown-widget__menu_small .dropdown-widget__menu-item {
  padding: 12px 20px;
}

.dropdown-widget__menu_borderfree .dropdown-widget__menu-item-wrapper:not(:only-child):not(:last-child) {
  border: none;
}

.dropdown-widget__menu_borderfree .dropdown-widget__menu-item {
  padding: 8px 19px;
}

.dropdown-widget__menu-item_external-link:hover::after {
  content: "";
  position: absolute;
  top: -1px;
  right: 12px;
  width: 14px;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="external-link-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 14" enable-background="new 0 0 14 14" xml:space="preserve"><path id="arrow-in-rectangle" fill="%231F2F4D" d="M12.2,12.2H1.8V1.8H7V0.2H1.8c-0.8,0-1.5,0.7-1.5,1.5v10.5c0,0.8,0.7,1.5,1.5,1.5 h10.5c0.8,0,1.5-0.7,1.5-1.5V7h-1.5V12.2z M8.5,0.2v1.5h2.7L3.8,9.1l1.1,1.1l7.4-7.4v2.7h1.5V0.2H8.5z"/></svg> ') 50% no-repeat;
  background-size: 14px;
}

.dropdown-widget__menu-item__title {
  margin: 0;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.25px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-widget__menu-item__subtitle {
  margin: 0;
  color: rgb(0 34 61 / 0.54);
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 0.75px;
  line-height: 15px;
}
