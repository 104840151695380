.prospecting-preview-card {
  position: relative;
  text-align: center;
}

.prospecting-preview-card__header {
  padding: 16px 24px;
  border-bottom: 1px solid #d6dadf;
  display: flex;
  justify-content: space-between;
}

.prospecting-preview-card__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: 16px 24px;
}

.prospecting-preview-card__title-link {
  position: relative;
  display: inline-block;
  background: transparent;
  font-weight: normal;
  font-size: 12px;
  color: #1696ff;
  text-decoration: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  outline: none;
}

.prospecting-preview-card__title-link:hover,
.prospecting-preview-card__title-link:focus {
  color: #1378cc;
  cursor: pointer;
  outline: none;
  border: none;
}

.prospecting-preview-card__title-link:active {
  outline: none;
  border: none;
}

.prospecting-preview-card__subtitle,
.prospecting-preview-card__subtitle.card-subtitle {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5em;
  color: #1f2f4d;
  text-align: left;
  opacity: 0.8;
}

.prospecting-preview-card__description {
  box-sizing: border-box;
  padding: 15px 0 20px;
  background: #fafafa;
  border-top: 1px solid #f0f1f5;
}

.prospecting-preview-card__description-title {
  font-weight: 500;
  font-size: 13px;
  color: rgb(31 47 77 / 0.8);
}

.prospecting-preview-card__description-content {
  font-weight: 300;
  font-size: 13px;
  color: rgb(31 47 77 / 0.8);
  margin: 0 auto;
  max-width: 320px;
}

.prospecting-preview-card__description-link {
  font-weight: 300;
  font-size: 13px;
  color: rgb(31 47 77 / 0.6);
  position: relative;
  display: inline-block;
  margin-right: 16px;
  text-decoration: underline;
}

.prospecting-preview-card__description-link:hover {
  color: rgb(31 47 77 / 0.6);
}

.prospecting-preview-card__description-link::after {
  content: "→";
  position: absolute;
  display: block;
  top: calc(50% - 0.675em);
  right: -18px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}
