.modal-form__dialog,
.modal-form__dialog.modal-dialog,
.modal.show .modal-form__dialog.modal-add-stripe {
  max-width: 576px;
  top: 40%;
  min-width: 530px;
}

.modal-add-stripe .modal-content {
  border-radius: 0.8rem;
}

.modal-add-stripe__header {
  margin-top: 48px;
  margin-bottom: 16px;
  padding: 1rem 0 0;
}

.modal-add-stripe__header h5 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 18px;
  color: rgb(0 34 61 / 0.87);
}

.modal-add-stripe__header p {
  font-size: 12px;
  color: rgb(0 34 64 / 0.37);
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
}

.modal-add-stripe__header-link {
  padding: 5px 0;
  font-size: 13px;
  color: rgb(0 34 64 / 0.37);
  text-decoration: underline;
}

.modal-add-stripe__header-link:hover {
  color: rgb(0 34 64 / 0.56);
}

.modal-add-stripe__header-logo {
  position: absolute;
  top: -64px;
  left: 38%;
  display: block;
  margin: auto;
}

.modal-add-stripe__body {
  font-family: Quicksand, "Open Sans", "Segoe UI", sans-serif;
  background: #f7fafc;
  border-top: 1px solid #e3e8ee;
  border-bottom: 1px solid #e3e8ee;
  padding: 40px 64px 32px;
}

.modal-add-stripe__body-billed {
  margin-bottom: 24px;
}

.modal-add-stripe__body-brand-list {
  width: 32px;
  margin: 5px 7px 8px 0;
}

.modal-add-stripe__body-card-element-card p {
  margin: 8px 0 12px;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
}

.modal-add-stripe__body-card-element-card img {
  margin-left: 8px;
}

.modal-add-stripe__body-card-element-card .StripeElement {
  padding: 10px 2px 10px 8px;
  background: #fff;
}

.modal-add-stripe__body-card-element-card .StripeElement--focus {
  border: 2px solid #1696ff;
  padding: 8px 0 8px 12px;
  border-radius: 3px;
  transition: 0.2s;
}

.modal-add-stripe__add-card-btn {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0;
  box-shadow: 0 4px 6px rgb(50 50 93 / 0.11), 0 1px 3px rgb(0 0 0 / 0.08);
  width: 100%;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  transition: all 150ms ease;
  margin: 8px 0 0;
  cursor: pointer;
  outline: none !important;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.modal-add-stripe__add-card-btn img {
  margin-bottom: 5px;
}

.modal-add-stripe__add-card-btn span {
  margin-left: 12px;
  text-transform: uppercase;
}

.modal-add-stripe__add-card-btn-pending,
.modal-add-stripe__add-card-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal-add-stripe__add-card-btn:focus {
  background-color: #43458b;
}

.modal-add-stripe__add-card-btn:hover:enabled {
  background-color: #43458b;
}

.modal-add-stripe__add-card-btn-pending {
  color: #6772e5;
}

:hover,
:focus,
.modal-add-stripe__add-card-btn :active {
  outline: 0;
  outline-offset: 0;
}

.modal-add-stripe__add-card-btn-pending::before {
  content: "";
  position: absolute;
  background-color: #fff;
  mask-repeat: no-repeat;
  animation: load 0.8s linear infinite;
  width: 24px;
  height: 24px;
  left: calc(50% - 12px);
  bottom: calc(44% - 10px);
  mask-image: url("../../components/Button/spinner-icon-large.svg");
}

.modal-add-stripe__add-card-error {
  color: rgb(254 22 22 / 0.9);
  margin: 8px 0 4px;
  font-size: 13px;
}

.modal-add-stripe__add-card-error__underline {
  color: rgb(254 22 22 / 0.9);
  text-decoration: underline;
  cursor: pointer;
}

.modal-add-stripe__body-detail-options {
  margin: 20px 0 0;
}

.modal-add-stripe__body-detail-options-bold {
  font-weight: bold;
}

.modal-add-stripe__body-detail-options ul {
  margin: 0;
  padding: 0 24px;
  font-size: 14px;
  line-height: 26px;
  color: rgb(0 34 61 / 0.87);
}

.modal-add-stripe__body-detail-options ul li::before {
  content: url("./check_mark.svg");
  margin: 0 8px 0 -24px;
  height: 20px;
  background-size: 20px 20px;
  position: relative;
  top: 5px;
}

.modal-add-stripe__footer {
  padding: 1.4rem;
}

.modal-add-stripe__footer img {
  margin: 0 auto;
}
