.daily-budget-input {
  display: inline-block;
  width: 196px;
  background: #fff;
  border: 1px solid #d6dadf;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.25px;
  line-height: 21px;
  margin-right: 15px;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.daily-budget-input:focus-within {
  border: 1px solid #1696ff;
  box-shadow: 0 0 0 1px #1696ff;
}

.daily-budget-input_error,
.daily-budget-input_error:focus,
.daily-budget-input_error:focus-within {
  background: #fdefec;
  border: 1px solid #fe1616;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgb(102 113 123 / 0.21);
  border-radius: 2px;
}

.daily-budget-input__input {
  width: 138px;
  background: transparent;
  border: none;
  outline: none;
  display: inline-block;
  border-radius: 0;
  padding: 8px 12px;
}

.daily-budget-input__input::-webkit-outer-spin-button,
.daily-budget-input__input::-webkit-inner-spin-button {
  appearance: none;
  appearance: textfield;
}

.daily-budget-input__input:disabled,
.daily-budget-input__input.disabled {
  background: #f7f9fa;
  box-shadow: none;
  color: rgb(0 34 61 / 0.54);
}

.daily-budget-input__addon {
  font-weight: 600;
  background-image: linear-gradient(-180deg, #fff 0%, #f9fafb 100%);
  border-right: 1px solid #d6dadf;
  border-radius: 0;
  display: inline-block;
  width: 53px;
  height: 100%;
  padding: 8px 0;
  text-align: center;
}

.daily-budget-input__input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
