.insta-stories {
    position: relative;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16));
    border-radius: 3px;
    overflow: hidden;
    width: 182px;
    height: 362px;
    margin: 0 auto;
}

.insta-stories__img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
}

.insta-stories__img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.insta-stories__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.insta-stories__top {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 8px;
}

.insta-stories__lines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    position: relative;
    z-index: 2;
}

.insta-stories__lines div {
    flex-basis: 32.5%;
    background: #C4C4C4;
    opacity: 0.5;
    border-radius: 1px;
    height: 1px;
}

.insta-stories__lines div:first-child {
    background: #FFFFFF;
    opacity: 1;
}

.insta-stories__avatar {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    margin-right: 5px;
}

.insta-stories__title {
    font-weight: 500;
    color: #FFFFFF;
    font-size: 6px;
    line-height: 7px;
    margin-bottom: 3px;
}

.insta-stories__subtitle {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 6px;
    line-height: 7px;
}

.insta-stories__avatar-wrapper {
    display: flex;
    align-items: center;
    margin-top: 3px;
}