:root {
  --user-widget-width: 195px;
  --horisontal-paddings: 32px;
}

.shopify-store-list-wrapper .dropdown-widget__toggle {
  position: relative;
  width: 220px;
}

@media (max-width: 767px) {
  .shopify-store-list-wrapper .dropdown-widget__toggle {
    width: calc(100vw - var(--user-widget-width) - var(--horisontal-paddings));
    max-width: initial;
  }
}

@media (max-width: 767px) {
  .shopify-store-list-wrapper .dropdown-widget__menu {
    margin-right: calc(-100vw + var(--user-widget-width) + var(--horisontal-paddings));
    margin-bottom: 15px;
    width: calc(100vw - var(--horisontal-paddings));
  }
}
