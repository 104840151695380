.facebook-alert-button {
  display: inline-block;
  padding: 2px;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: #0275d8;
  cursor: pointer;
  background: none;
  border: none;
}

.facebook-alert-button:hover {
  color: #014c8c;
}
