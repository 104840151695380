.gl-payment-method__wrapper {
  margin: 0 auto;
  padding: 0 16px 56px;
  max-width: 732px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gl-payment-method__main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gl-payment-method__notification .alert__wrapper_info {
  max-width: 755px;
  position: relative;
  left: -15px;
}

@media (max-width: 991px) {
  .gl-payment-method__notification .alert__wrapper_info {
    max-width: 460px;
    left: 0;
  }
}

.gl-payment-method__wrapper .alert__wrapper {
  margin-bottom: 24px;
}

.gl-payment-method__wrapper_small {
  max-width: 510px;
}

.gl-payment-method__wrapper_small-error {
  padding-top: 20px;
}

.gl-payment-method__title {
  margin-bottom: 40px;
  margin-top: 35px;
  font-size: 32px;
  font-weight: 300;
  padding: 0 105px;
}

.gl-payment-method__item {
  margin-bottom: 20px;
  width: 100%;
  min-height: 269px;
  position: relative;
}

.gl-payment-method__divider {
  width: 1px;
  background-color: #c4cdd5;
  margin: 0 38px;
}

.gl-payment-method__description {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 10px;
}

.gl-payment-method__text-link,
.gl-payment-method__text-link:hover,
.gl-payment-method__text-link:active {
  color: #1596ff;
  font-weight: 500;
  text-decoration: none;
}

.gl-payment-method__notification .alert__wrapper_danger {
  max-width: 700px;
}

.gl-payment-method-cta-button {
  margin-top: 17px;
  max-width: 250px;
}

.gl-payment-method__list {
  padding: 0 12px 15px 22px;
  margin: 0;
  max-width: 460px;
  font-size: 14px;
}

.gl-payment-method__list-item {
  padding: 0;
}

.gl-payment-method__list-item:not(:last-of-type) {
  margin-bottom: 10px;
}

.gl-payment-method__tool-icon {
  display: inline-block;
  text-indent: -9999px;
  width: 18px;
  height: 16px;
  background: url("./assets/tool-icon.svg") no-repeat;
  background-size: 18px 16px;
}

.gl-payment-method__gmail-icon {
  display: inline-block;
  text-indent: -9999px;
  width: 16px;
  height: 14px;
  background: url("./assets/gmail-icon.svg") no-repeat;
  background-size: 16px 16px;
}

@media (max-width: 576px) {
  .gl-payment-method__title {
    font-size: 19px;
    padding: 0;
    max-width: 290px;
    text-align: center;
    margin: 24px auto 0;
  }

  .gl-payment-method__wrapper {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .gl-payment-method__main-section {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .gl-payment-method__description {
    margin-bottom: 24px;
    font-size: 13px;
  }

  .gl-payment-method-cta-button {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
  }
}