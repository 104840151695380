.special_offer-campaign-create__wrapper {
  margin: 0 auto;
  padding: 36px 0;
  width: 100%;
}

.special_offer-campaign-create-page {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0 auto;
  max-width: 1024px;
  max-height: 900px;
}

.special_offer-campaign-create-page__button {
  margin-bottom: 20px;
  padding: 5px 12px;
  position: relative;
  background-color: #fff;
  border: 1px solid #e1e3e6;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.05);
  text-transform: uppercase;
  color: #00223d;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

.special_offer-campaign-create-page__button::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  padding: 0;
  width: 8px;
  height: 13px;
  background: url("nav-button-left.svg") no-repeat;
  background-size: 100%;
}

.special_offer-campaign-create-page__button:hover,
.special_offer-campaign-create-page__button:focus {
  background-color: #f4f6f8;
  box-shadow: inset 0 1px 1px 0 rgb(99 115 129 / 0.15), 0 1px 0 0 rgb(22 29 37 / 0.05);
  color: #00223d;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

.special_offer-campaign.campaign-details__advice-tip-description {
  margin: 0;
  padding: 22px 24px 26px;
  color: #fff;
  width: 320px;
  font-size: 14px;
}

@media (max-width: 991px) {
  .special_offer-campaign-create-page {
    max-height: initial;
  }
}
