.connect-email-fb__img {
  margin-bottom: 15px;
}

.connect-email-fb__title {
  color: #00223d;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 30px;
  margin-bottom: 4px;
}

.fb-login-page .connect-email-fb__text {
  margin-bottom: 18px;
}

.connect-email-fb__text .trigger-intercom-link {
  cursor: pointer;
  text-decoration: underline;
  color: #000;
}

.add-email-button {
  text-transform: initial !important;
}

.connect-email-fb__link,
.connect-email-fb__link:hover,
.connect-email-fb__link:focus {
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
}

.connect-email-fb__link--bluebold {
  color: #1696ff;
  font-weight: 600;
  text-decoration: none;
}
