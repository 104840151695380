.payment-success__caption {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25px;
  color: #00223d;
  font-weight: 100;
}

.payment-success__button {
  font-weight: 600;
  letter-spacing: 1px;
}

.payment-success__subcaption {
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #00223d;
  margin-top: 32px;
  margin-bottom: 24px;
}

.payment-success__description {
  color: rgb(0 34 61 / 0.77);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 21px;
}

.payment-success__wrapper {
  margin: 73px auto 120px;
  max-width: 620px;
}

.payment-success__icon-wrapper {
  margin-top: 24px;
  margin-bottom: 22px;
}

.payment-success__link {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #a1adb7;
  margin-top: 16px;
  font-weight: 300;
}

.payment-success__link a {
  color: #a1adb7;
  text-decoration: underline;
}
