.action-token-failure {
  top: 40%;
  max-width: 550px;
}

.action-token-failure__button {
  margin-top: 8px;
  padding: 10px 20px;
  font-size: 16px;
  height: auto;
  font-weight: 500;
}

.action-token-failure__button:focus {
  box-shadow: 0 0 0 1px #1696ff !important;
}

.modal-try-again-body__underline {
  cursor: pointer;
}