.modal-form__dialog,
.modal-form__dialog.c-modal__inner,
.modal.show .modal-form__dialog.c-modal__inner {
  position: absolute;
  top: 42%;
  right: 50%;
  transform: translate(50%, -40%);
  margin: 0;
  max-height: 90%;
  width: 47%;
  max-width: 500px;
}

.modal-form__dialog .modal-special__close-button {
  position: absolute;
  top: 20px;
  right: 16px;
  display: inline-block;
  width: 14px;
  height: 11px;
  font-size: 0;
  vertical-align: middle;
  background: url("./close-icon.svg") no-repeat;
  background-position: 0 -11px;
  background-size: 80%;
  border: none;
  outline: none;
}

.modal-form__dialog .modal-special__close-button:focus {
  outline: none;
  border: none;
}

.modal-form__dialog .modal-special__close-button:hover {
  cursor: pointer;
  background-position: 0 0;
}

.modal-form__header.c-modal-header {
  padding: 8px 16px;
  margin: 16px 16px 0;
  border: none;
}

.modal-form__header,
.modal-form__header.c-modal-header {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.modal-form__header .modal-special__title {
  font-size: 18px;
  font-weight: 600;
}

.modal-form__body.c-modal-body {
  padding: 8px 16px;
  margin: 8px 16px;
  border: none;
}

.modal-form__body .option-form__radio-item {
  font-size: 13px;
  margin: 18px 25px;
  display: block;
}

.modal-form__body .option-form__radio-item:first-child {
  margin: 0 25px 10px;
}

.modal-form__body .form-group__options input[type="radio"] {
  transform: scale(1.1);
  margin: 2px 0 0 -22px;
  position: absolute;
}

.modal-form__body .form-group__options {
  margin: 0 0 20px;
}

.modal-form__body textarea {
  resize: none;
  font-size: 13px;
  height: 80px;
  padding-top: 10px;
}

.modal-form__footer.c-modal-footer {
  padding: 20px 30px;
}

.modal-form__footer .modal-form-footer-btn {
  min-width: 88px;
}

.modal-form__footer .modal-form-footer-btn__pause {
  margin-left: 12px;
}

.modal-form__footer .modal-form-footer-cancel-btn:focus,
.modal-form__footer .modal-form-footer-cancel-btn:active {
  border: 2px solid #1696ff;
}

.pause-campaign-modal-trial-warning-message__wrapper {
  border: 2px solid #fe9929;
  border-radius: 2px;
  height: 100px;
  display: flex;
  margin: -6px 0 14px;
}

.pause-campaign-modal-trial-warning-message__aside {
  background: #fe9929 url("white-icon.svg") 10px 14px no-repeat;
  flex: 0 0 40px;
}

.pause-campaign-modal-trial-warning-message__main,
.pause-campaign-modal-info-message__main {
  padding: 16px;
}

.pause-campaign-modal-info-message__wrapper {
  font-size: 14px;
}

.pause-campaign-modal-trial-options__headline,
.pause-campaign-modal-options__headline {
  margin-bottom: 12px;
  font-weight: 500;
}

.pause-campaign-modal-info-message__wrapper {
  border: 2px solid #1696ff;
  border-radius: 2px;
  height: 160px;
  display: flex;
  margin: 0 0 20px;
}

.pause-campaign-modal-info-message__aside {
  background: #1696ff url("white-icon.svg") 10px 14px no-repeat;
  flex: 0 0 40px;
}

.pause-campaign-modal-info-message__link {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #1696ff;
  font-weight: 500;
  text-decoration: none;
}

.pause-campaign-modal-info-message__link:hover,
.pause-campaign-modal-info-message__link:focus {
  background-color: transparent;
  border: none;
  color: #147dd4;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

.pause-campaign-modal-info-message__link:active {
  background-color: transparent;
  border: none;
  color: #1897ff;
  text-decoration: none;
}

.pause-message-input-wrapper {
  position: relative;
  width: 100%;
}

.pause-message-input {
  display: block;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
  padding: 10px 48px 6px 12px;
}

.pause-message-input:focus {
  border: 1px solid #1696ff;
  outline: none;
  box-shadow: 0 0 0 1px #1696ff;
}

.pause-message-input_error,
.pause-message-input_error:focus {
  border: 1px solid #fe1616;
  outline: none;
  box-shadow: 0 0 0 1px #fe1616;
}

.pause-message-counter {
  position: absolute;
  bottom: 5px;
  right: 8px;
  font-size: 10px;
  color: rgb(0 34 61 / 0.54);
}

.pause-message-counter_error {
  color: #fe1616;
}

@media (max-width: 767px) {
  .modal-form__header.modal-header {
    padding: 8px;
    margin: 16px 8px 0;
  }

  .modal-form__body.c-modal-body {
    padding: 8px;
    margin: 8px 8px 0;
  }

  .modal-form__footer.c-modal-footer {
    padding: 20px 16px;
  }
}

@media (max-width: 576px) {
  .modal-form__header.modal-header {
    padding: 8px;
    margin: 8px 24px 0 8px;
  }

  .modal-form__body.c-modal-body {
    padding: 8px;
    margin: 8px 8px 0;
  }

  .modal-form__footer.c-modal-footer {
    padding: 20px 16px;
  }
}

@media (max-width: 480px) {
  .modal-form__header.c-modal-header {
    padding: 8px;
    margin: 8px 24px 0 0;
  }

  .modal-form__body.c-modal-body {
    padding: 8px;
    margin: 8px 0 0;
  }

  .modal-form__footer.c-modal-footer {
    padding: 20px 8px;
  }
}
