:root {
  --user-icon-size: 34px;
  --user-widget-size: 195px;
  --horisontal-paddings: 32px;
}

.user-profile-wrapper .dropdown-widget__toggle-icon {
  width: var(--user-icon-size);
  height: var(--user-icon-size);
}

.dropdown-widget__toggle-icon_bordered {
  border: 2px solid #c4cdd5;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .user-profile-wrapper .dropdown-widget__menu {
    margin-left: calc(-100vw + var(--user-widget-size) + var(--horisontal-paddings));
    width: calc(100vw - var(--horisontal-paddings));
  }
}
