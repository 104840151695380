.onboarding-error__wrapper {
  margin: 0 auto;
  padding: 56px 128px;
  color: #00223d;
}

@media (max-width: 991px) {
  .onboarding-error__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .onboarding-error__wrapper {
    padding: 16px;
  }
}

.onboarding-error__title {
  margin-bottom: 40px;
  font-size: 31px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .onboarding-error__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.onboarding-error__description {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .onboarding-error__description {
    font-size: 13px;
  }
}

.onboarding-error__body-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.onboarding-error__buttons {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .onboarding-error__buttons {
    gap: 16px;
  }
}
