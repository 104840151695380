.ad-message-input-wrapper {
  position: relative;
  width: 100%;
}

.ad-message-input {
  background: #fff;
  border: 1px solid #d6dadf;
  padding: 15px;
  height: calc(15px + (1.5em * 3) + 15px);
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.25px;
  line-height: 21px;
  resize: none;
  display: block;
  width: 100%;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.ad-message-input:focus {
  border: 1px solid #1696ff;
  outline: none;
  box-shadow: 0 0 0 1px #1696ff;
}

.ad-message-input_error,
.ad-message-input_error:focus {
  border: 1px solid #fe1616;
  outline: none;
  box-shadow: 0 0 0 1px #fe1616;
}

.ad-message-input:disabled,
.ad-message-input.disabled {
  background: #f7f9fa;
  border: 1px solid #d6dadf;
  box-shadow: none;
  color: rgb(0 34 61 / 0.54);
}

.ad-message-input_with-dropdown.form-control {
  box-sizing: border-box;
  padding-right: 48px;
  margin: 0;
}

.ad-message-tag-dropdown {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ad-message-counter {
  position: absolute;
  bottom: 2px;
  right: 8px;
  font-size: 10px;
  color: rgb(0 34 61 / 0.54);
}

.ad-message-counter_error {
  color: #fe1616;
}

.dropdown-menu_list.ad-message-tag-dropdown__menu {
  background-color: #fff;
  padding: 0;
  margin-bottom: -2px;
  min-width: 0;
  border-radius: 2px;
  border-color: #c4cdd5;
  width: 140px;
  overflow: hidden;
}

.ad-message-tag-dropdown__item {
  font-weight: 500;
  font-size: 13px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.13px;
  line-height: 20px;
  padding: 9px 16px 11px;
  border-radius: 1px;
  height: 36px;
  cursor: pointer;
}

.ad-message-tag-dropdown__item:hover {
  color: #fff;
  font-weight: 500;
  background: #1f2f4d;
}

.ad-message-tag-dropdown__item:focus {
  outline: none;
}

.ad-message-tag-dropdown__toggle {
  padding: 0;
  border: none;
  background: transparent;
}
