.modal-error__dialog,
.modal-error__dialog.c-modal__inner,
.modal.show .modal-error__dialog.c-modal__inner {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -40%);
  margin: 0;
  width: 90%;
  max-width: 468px;
  max-height: 90%;
}

.modal-error__dialog .c-modal__content,
.modal-error__dialog.c-modal__inner .c-modal__content {
  padding: 0 24px;
}

@media (max-width: 480px) {
  .modal-error__dialog .c-modal__content,
  .modal-error__dialog.c-modal__inner .c-modal__content {
    padding: 0 12px;
  }
}

.modal-error__body,
.modal-error__body.modal-body {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
}

.modal-error-body__logo {
  display: block;
  height: 56px;
  width: 56px;
  margin: 24px auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.modal-error-body__logo.default {
  background-image: url("./logo.svg");
}

.modal-error-body__logo.try-again {
  background-image: url("./again.svg");
}

.modal-error-body__content {
  color: #1f2f4d;
  font-size: 13px;
  font-weight: normal;
  font-weight: 400;
  margin: 0;
}

.modal-error-body__content .notification__link {
  font-size: 13px;
  font-weight: 400;
}

.modal-error__footer,
.modal-error__footer.modal-footer {
  margin: 0 auto 24px;
  padding: 0;
  border: none;
  justify-content: center;
}

.modal-error__footer > :not(:last-child),
.modal-error__footer.modal-footer > :not(:last-child) {
  margin-right: 16px;
}

.modal-error__button {
  padding: 10px 20px;
  display: block;
  border: 1px solid;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(22 29 37 / 0.05);
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1em;
}

.modal-error__button:hover,
.modal-error__button:focus {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.modal-error__button:active {
  outline: none;
}

.modal-error__button_primary {
  background-color: #1696ff;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
  text-transform: uppercase;
}

.modal-error__button_primary:hover,
.modal-error__button_primary:focus {
  background-color: #1078cd;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
}

.modal-error__button_primary:active {
  background-color: #0d62a7;
  color: #fff;
}

.modal-error-body__underline,
.modal-error-body__underline:hover,
.modal-error-body__underline:focus {
  color: #1f2f4d;
  text-decoration: underline;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.modal-error-body__underline:hover {
  cursor: pointer;
}
