.gl-dp-mob {
    width: 187px;
    height: 331px;
    position: relative;
    background: url("img/background.svg") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
}

.gl-dp-mob__img-wrapper {
    position: absolute;
    bottom: 48px;
    left: 19px;
    width: 149px;
    height: 49px;
    border-radius: 3px;
    overflow: hidden;
}

.gl-dp-mob__img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}