.create-bm__wrapper {
  margin: 0 auto;
  padding: 56px 128px;
  color: #00223d;
}

.create-bm__wrapper h6 {
  margin-top: 24px;
}

@media (max-width: 991px) {
  .create-bm__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .create-bm__wrapper {
    padding: 16px;
  }
}

.create-bm__title {
  margin-bottom: 40px;
  font-size: 31px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .create-bm__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.create-bm__description {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
  max-width: 450px;
}

.create-bm__description--fluid {
  max-width: 100%;
}

.create-bm__description label {
  margin-top: 12px;
}

@media (max-width: 576px) {
  .create-bm__description {
    font-size: 13px;
  }
}

.create-bm__body-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
}

.create-bm__button {
  margin-right: 32px;
}