.gl-search-mob {
    width: 195px;
    height: 363px;
    margin: 0 auto;
    position: relative;
    background: url("img/google-search-mobile.png") no-repeat center;
    background-size: 100% 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.gl-search-mob::after {
    content: '';
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    height: 225px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 32px;
}

.gl-search-mob__content {
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 6px 6px;
}

.gl-search-mob__url-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.gl-search-mob__url {
    margin-left: 6px;
}

.gl-search-mob__url div {
    font-size: 7px;
    line-height: 8px;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
}

.gl-search-mob__url div:last-child {
    color: rgba(0, 0, 0, 0.6);
    font-size: 6px;
    margin-top: 1px;
}

.gl-search-mob__title {
    font-size: 10px;
    line-height: 14px;
    color: #1A0DAB;
    margin-bottom: 6px;
}

.gl-search-mob__descr {
    color: #4D5156;
    font-size: 7px;
    line-height: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.gl-search-mob__sponsored {
    font-weight: 700;
    font-size: 7px;
    line-height: 8px;
    color: #000000;
    margin-bottom: 6px;
}

.gl-search-mob__search-title {
    position: absolute;
    background: #ffffff;
    height: 14px;
    width: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
    line-height: 14px;
    bottom: 7px;
    left: 89px;
}