.save-campaign-details__cta-button {
  width: 100%;
}

.save-campaign-details-cta-button__tips-wrapper {
  max-width: 215px;
}

.save-campaign-details-cta-button__tips-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.save-campaign-details-cta-button__tips-icon::before {
  opacity: 0;
}

.save-campaign-details__notice {
  padding: 10px 0;
  color: rgb(31 47 77 / 0.4);
  text-align: center;
  font-size: 11px;
  font-weight: 300;
}

.save-campaign-details-notice__link,
.save-campaign-details-notice__link.btn-link {
  padding: 0;
  background: transparent;
  border: none;
  font-size: 11px;
  font-weight: bold;
  color: rgb(37 47 77 / 0.4);
  text-decoration: none;
}

.save-campaign-details-notice__link:hover,
.save-campaign-details-notice__link:focus,
.save-campaign-details-notice__link.btn-link:hover,
.save-campaign-details-notice__link.btn-link:focus {
  color: rgb(37 47 77 / 0.7);
  text-decoration: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}
