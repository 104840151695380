.pricing-widget,
.pricing-widget.tooltip-inner {
  background-color: #00223d;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
  color: rgb(255 255 255 / 0.8);
  font-size: 11px;
}

@media (max-width: 576px) {
  .pricing-widget,
  .pricing-widget.tooltip-inner {
    width: initial;
    max-width: 260px;
  }
}

.pricing-widget_small {
  padding: 0;
  width: initial;
}

.pricing-widget__title {
  margin: 0;
  padding: 6px 12px 12px;
  background-color: #00223d;
  border: none;
  color: #28dcb4;
  font-size: 15px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 480px) {
  .pricing-widget__title {
    font-size: 13px;
    padding: 0 12px 6px;
  }
}

.pricing-widget__content {
  padding: 0;
}

.pricing-widget-content__description {
  margin: 0 0 8px;
  padding: 0 8px;
  text-align: center;
  font-weight: 100;
}

@media (max-width: 480px) {
  .pricing-widget-content__description {
    padding: 0;
  }
}

.pricing-widget-content__link {
  margin-bottom: 16px;
  padding: 0;
  display: block;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  text-decoration: underline;
  text-align: center;
}

@media (max-width: 480px) {
  .pricing-widget-content__link {
    margin-bottom: 8px;
  }
}

.pricing-widget-content__link:hover,
.pricing-widget-content__link:focus {
  outline: none;
  box-shadow: none;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.pricing-widget-content__list-item {
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid rgb(255 255 255 / 0.4);
  border-radius: 8px;
}

@media (max-width: 480px) {
  .pricing-widget-content__list-item {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 0.4);
    border-radius: 0;
  }
}

.pricing-widget-content__list-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .pricing-widget-content__list-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.pricing-widget-content__list-item:hover,
.pricing-widget-content__list-item:focus {
  border-color: #28dcb4;
  cursor: pointer;
}

.pricing-widget-content-list-item__section {
  display: inline-block;
  margin: 0;
  width: initial;
}

.pricing-widget-content-list-item__section:first-child {
  width: 40%;
}

.pricing-widget-content-list-item__section:last-child {
  width: 60%;
}

.pricing-widget-content-list-item-section__title-badge {
  display: block;
  font-size: 36px;
  font-weight: 500;
  line-height: 1em;
  color: rgb(255 255 255 / 0.6);
}

@media (max-width: 480px) {
  .pricing-widget-content-list-item-section__title-badge {
    font-size: 24px;
  }
}

.pricing-widget-content-list-item-section__title-sign {
  position: relative;
  top: -16px;
  vertical-align: baseline;
  font-size: 50%;
  font-weight: 100;
  color: rgb(255 255 255 / 0.6);
}

@media (max-width: 480px) {
  .pricing-widget-content-list-item-section__title-sign {
    top: -8px;
  }
}

.pricing-widget-content-list-item-section__title-description {
  display: block;
  color: rgb(255 255 255 / 0.6);
}

@media (max-width: 576px) {
  .pricing-widget-content-list-item-section__title-description {
    font-size: 10px;
    font-weight: 100;
  }
}

.pricing-widget-content-list-item-section__description {
  display: block;
  color: rgb(255 255 255 / 0.6);
}

.pricing-widget-content-list-item-section__description__caption {
  color: rgb(255 255 255 / 0.8);
  font-weight: bolder;
}

.pricing-widget-content-list-item-section__description-notice {
  display: block;
  color: rgb(255 255 255 / 0.4);
}

.pricing-widget-small__section {
  display: inline-block;
  padding: 0;
  border-right: 1px solid rgb(255 255 255 / 0.2);
}

.pricing-widget-small-section__title {
  margin: 0;
  padding: 10px 16px 4px;
  color: #fff;
  font-size: 13px;
}

.pricing-widget-small-section__description {
  margin: 0;
  padding: 4px 16px 10px;
  color: rgb(255 255 255 / 0.8);
  font-weight: 300;
}
