.tooltip__wrapper {
  display: inline-block;
  position: relative;
  height: 100%;
}

.tooltip-inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  padding: 20px 24px 24px;
  background-color: #fff;
  color: rgb(31 47 77 / 0.8);
  text-align: left;
  font-weight: 400;
  border: 1px solid #e1e3e6;
  border-radius: 8px;
  max-width: 320px;
  min-width: 300px;
}

.tooltip-inner_dark {
  background-color: #00223d;
  color: #fff;
  border: none;
}

.tooltip-inner_dark .tips-description__content {
  color: #fff;
}

.tooltip-inner::before,
.tooltip-inner::after {
  content: "";
  position: absolute;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-left: -6px;
}

.tooltip-inner_dark.tooltip-inner::before,
.tooltip-inner_dark.tooltip-inner::after {
  border-top: 6px solid #00223d;
  margin-top: 0;
}

.tooltip-inner::before {
  border-top: 6px solid #e1e3e6 !important;
  margin-top: 1px;
}

.tooltip-inner::after {
  border-top: 6px solid white;
  margin-top: 0;
  z-index: 1;
}

.tooltip-inner.top {
  bottom: 145%;
}

.tooltip-inner.top::before,
.tooltip-inner.top::after {
  top: 100%;
  left: 50%;
}

.tooltip-inner.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-inner.right::before,
.tooltip-inner.right::after {
  left: -6px;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  transform: rotate(90deg);
}

.tooltip-inner.right::before {
  margin-left: -3px;
}

.tooltip-inner.right::after {
  margin-left: -2px;
}

.tooltip-inner.bottom {
  top: 150%;
}

.tooltip-inner.bottom::before,
.tooltip-inner.bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(0) translateY(-50%);
  transform: rotate(180deg);
}

.tooltip-inner.bottom::before {
  margin-bottom: 1px;
}

.tooltip-inner.bottom::after {
  margin-bottom: 0px;
}

.tooltip-inner.left {
  left: auto;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tooltip-inner.left::before,
.tooltip-inner.left::after {
  left: auto;
  right: -12px;
  top: 50%;
  transform: translateX(0) translateY(-50%);
  transform: rotate(270deg);
}

.tooltip-inner.left::before {
  margin-right: 2px;
}

.tooltip-inner.left::after {
  margin-right: 3px;
}