.gmail-desktop {
    width: 266px;
    height: 136px;
    margin: 0 auto;
    background: url("img/gmail-background.svg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
}

.gmail-desktop__title {
    position: absolute;
    left: 130px;
    top: 48px;
    font-weight: 700;
    font-size: 6px;
    color: #000000;
    max-width: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gmail-desktop__description {
    position: absolute;
    left: 203px;
    top: 48px;
    font-weight: 700;
    font-size: 6px;
    color: #000000;
    max-width: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}