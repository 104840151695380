.facebook-desktop {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    max-width: 268px;
    margin: 0 auto;
}

.facebook-desktop__avatar {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    margin-right: 4px;
}

.facebook-desktop__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px;
}

.facebook-desktop__avatar-wrapper {
    display: flex;
    align-items: center;
}

.facebook-desktop__name {
    font-weight: 700;
    font-size: 8px;
    line-height: 8px;
    display: flex;
    margin-bottom: 2px;
}

.facebook-desktop__name span {
    margin-right: 2px;
}

.facebook-desktop__description {
    display: flex;
    align-items: center;
    font-size: 7px;
    line-height: 1;
    color: #65676B;
    margin-top: 1px;
}

.facebook-desktop__description span {
    margin-right: 1px;
}

.facebook-desktop__text {
    overflow: hidden;
    padding: 2px 8px 8px;
}

.facebook-desktop__text span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 8px;
    line-height: 8px;
}

.facebook-desktop__img-wrapper {
    width: 100%;
    height: 210px;
}

.facebook-desktop__img-wrapper img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.facebook-desktop__cta {
    padding: 8px 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F0F2F5;
}

.facebook-desktop__cta-url {
    color: #65676B;
    font-size: 8px;
    line-height: 1;
    margin-bottom: 2px;
    text-transform: lowercase;
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.facebook-desktop__cta-title {
    font-size: 8px;
    line-height: 10px;
    color: #050505;
    font-weight: 700;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.facebook-desktop__cards {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-left: 10px;
}

.facebook-desktop-card {
    width: 210px;
    border-radius: 3px;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid #D8DADD;

}

.facebook-desktop-card + .facebook-desktop-card {
    margin-left: 10px;
}

.facebook__social-approve {
    position: relative;
}

.facebook__social-approve div {
    position: absolute;
    top: 6px;
    left: 7px;
    font-size: 7px;
    line-height: 8px;
    color: #000000;
}

.facebook__social-approve div span {
    font-weight: 600;
}