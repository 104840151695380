.payment-error__caption {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.25px;
  color: #00223d;
  font-weight: 100;
}

.payment-error__description {
  color: rgb(0 34 61 / 0.77);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 21px;
}

.payment-error__wrapper {
  margin: 73px auto 120px;
  max-width: 620px;
}

.payment-error__icon-wrapper {
  margin-top: 24px;
  margin-bottom: 22px;
}

.payment-error__link {
  display: block;
  margin-bottom: 46px;
  color: #758896;
  text-decoration: underline;
  font-weight: 100;
  font-size: 12px;
}

.payment-error__text {
  display: block;
  margin: 10px 0 46px;
  color: #758896;
  font-weight: 100;
  font-size: 12px;
}

.payment-error__text-link {
  color: #758896;
  text-decoration: underline;
}
