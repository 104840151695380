.google_button_wrapper {
    background: #fff url("./google_logo.svg") no-repeat 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    max-width: 226px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    padding-left: 34px;
    text-decoration: none;
}

.google_button_wrapper:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.54);
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.google_button_wrapper:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #fff url("./google_logo.svg") no-repeat 10px;
}

.google_button_wrapper:focus {
    box-shadow: 0 0 0 2px #4285F4, 0 0 3px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.18);
}

.google_button_wrapper:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #fff url("./google_logo.svg") no-repeat 10px;
}
