.spend-progress-bar {
    font-size: 14px;
    line-height: 18px;
    color: #00223D;
    letter-spacing: 0.25px;
}

.spend-progress-bar__title {
    font-size: 10px;
    line-height: 15px;
    color: #758896;
}

.spend-progress-bar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.spend-progress-bar__spends {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.spend-progress-bar__percents {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: rgba(0, 34, 61, 0.38);
    margin-left: 4px;
    font-weight: 400;
}

.spend-progress-bar__content .tips__wrapper {
    display: flex;
}

.spends-progress-bar__tooltip {
    white-space: break-spaces;
}

.spends-progress-bar__tooltip a {
    font-weight: 500;
}

.spends-progress-bar__tooltip a span {
    margin-right: 8px;
}

.spend-progress-bar__line {
    width: 180px;
    height: 2px;
    background: #D6DADF;
    border-radius: 4px;
    position: relative;
}

.spend-progress-bar__progress-line {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: all .3s;
}

@media (max-width: 575px) {
    .spend-progress-bar {
        flex-direction: column;
        position: absolute;
        left: 19px;
        top: -22px;
    }

    .spend-progress-bar__content {
        display: none;
    }

    .spend-progress-bar__line {
        width: 120px;
    }
}