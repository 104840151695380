/* Additional styles for LoginView in dropdown menu (list of stores) */

.choose-shop {
  will-change: height;
}

.choose-shop .dropdown-widget__toggle {
  width: 267px;
  max-width: none;
  margin: 0 auto;
  background-image: linear-gradient(180deg, #fff 0%, #f3f5f7 100%);
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .choose-shop .dropdown-widget__toggle {
    width: auto;
  }
}

.choose-shop .dropdown-widget__toggle:active,
.choose-shop .dropdown-widget__toggle:focus {
  box-shadow: 0 1px 0 #f2f4f5, inset 0 1px 4px rgb(31 47 77 / 0.2), inset 0 1px 1px rgb(31 47 77 / 0.6);
}

.choose-shop .dropdown-widget__menu {
  width: 320px;
  right: 0;
  margin: 0 auto;
  left: -53px;
  max-height: 237px;
  overflow-y: scroll;
}

@media screen and (max-width: 480px) {
  .choose-shop .dropdown-widget__menu {
    width: auto;
  }
}

.choose-shop-login__button {
  border-radius: 4px;
  border: 1px solid transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 16px auto 0;
  max-width: 320px;
  width: 100%;
  line-height: 41px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  outline: none;
}

.choose-shop-login__button:hover {
  border-color: #1270bc;
  box-shadow: 0 1px 0 #e7eef3;
  text-decoration: none;
}

.choose-shop-login__button:active,
.choose-shop-login__button:focus {
  outline: none;
}

.choose-shop .login-view__dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c4cdd5;
}

.choose-shop .login-view__dropdown-store-icon {
  width: 52px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c4cdd5;
  background-image: linear-gradient(180deg, #fff 0%, #f3f5f7 100%);
}

.choose-shop .login-view___dropdown-store-icon img {
  width: 20px;
  margin: 0 auto;
  object-fit: cover;
}

.choose-shop .dropdown-widget__menu-item {
  padding: 11px 16px 11px 110px;
}

.choose-shop-type::after {
  content: "";
  position: absolute;
  top: -1px;
  width: 36px;
  height: 100%;
  background-size: 36px;
  left: 55px;
}

.type-shopify::after {
  background: url("../../components/ShopifyStoreListWidget/shopify-logo-icon.svg") center no-repeat;
}

.type-bigcommerce::after {
  background: url("../../components/ShopifyStoreListWidget/bigcommerce-logo-icon.svg") center no-repeat;
}

.type-woocommerce::after {
  background: url("../../components/ShopifyStoreListWidget/woocommerce-logo-icon.svg") center no-repeat;
}

.type-standalone::after {
  background: url("../../components/ShopifyStoreListWidget/standalone-logo-icon.svg") center no-repeat;
}

.choose-shop .dropdown-widget__toggle-icon {
  z-index: -1;
  position: absolute;
}

.fb-login-logout-button {
  background: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}

.logged-as-text {
  margin-top: 40px;
  color: #fff !important;
}

.logged-as-text span {
  font-weight: 600;
}
