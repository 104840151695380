.todolist-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0 -24px;
}

.todo-list {
    display: flex;
    align-items: center;
}

.todo-list_progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    background: #FFFFFF;
    border: 1px solid #C4CDD5;
    box-shadow: 0px 1px 0px #F2F4F5;
    border-radius: 8px;
    font-weight: 500;
    margin-right: 16px;
}

.todo-list-dropdown {
    max-width: 100%;
    overflow: visible;
}

.todo-list__toggler {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.todo-list .caret-icon::before {
    width: 9px;
    height: 6px;
}

.todo-list__caret {
    padding: 0 16px
}

.todo-list__toggler-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}


.todo-list__progress-bar-wrapper {
    width: 100%;
    height: 2px;
    background: #D6DADF;
    border-radius: 4px;
    margin-top: 4px;
    position: relative;
}

.todo-list__progress-bar {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
}

.todo-list_progress--red {
    color: #FF0000;
}

.todo-list_progress--neon {
    color: #FE019A;
}

.todo-list__progress-bar--red {
    background: #FF0000;
}

.todo-list__progress-bar--neon {
    background: #FE019A;

}

.todo-list__progress-bar--orange {
    background: #FE9929;
}

.todo-list__progress-bar--green {
    background: #07AE3A;
}

.todo-list_progress--orange {
    color: #FE9929;
}

.todo-list_progress--green {
    color: #07AE3A;
}

.todo-list__dropdown-menu {
    background: #FFFFFF;
    width: 375px;
    margin-top: 14px;
    border: 1px solid #D6DADF;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    overflow: visible;
}

.todo-list__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    font-size: 12px;
    color: #A1ADB7;
    line-height: 1.5;
    transition: all .3s;
    cursor: pointer;

    .tooltip__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;

        .tooltip-inner {
            max-width: 280px;
            transition: all .3s;
        }
    }
}

.todo-list__item--subtext {
    font-size: 10px;
    padding: 0 10px;
    cursor: default;
}

.todo-list__item--subtext span {
    color: #FE9929;
}

.todo-list__item-title {
    flex-grow: 1;
}

.todo-list__dot {
    display: flex;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #E4E8EB;
    transition: all .3s;
}

.todo-list__item + .todo-list__item {
    border-top: 2px solid #D6DADF;
}

.todo-list__item:not(.todo-list__item--subtext):hover {
    color: #00223D;
    background: #F2F4F5;
}

.todo-list__item.todo-list__item--not-started {
    color: #00223D;
    background: #F2F4F5;
}

.todo-list__item.todo-list__item--not-started .todo-list__dot {
    background: #758896;
}

.todo-list__item:hover .todo-list__dot {
    background: #758896;
}

.todo-list__item.todo-list__item--not-started  .todo-list__item-status--start-btn {
    opacity: 1;
}

.todo-list__item:hover .todo-list__item-status--start-btn {
    opacity: 1;
}

.todo-list__item-icon {
    width: 26px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.todo-list__item-status {
    font-size: 10px;
    line-height: 1.5;
    text-align: right;
}

.todo-list__item-status--fail {
    color: #00223D;
    position: relative;
    cursor: pointer;
}

.todo-list__item-status--fail::after {
    position: absolute;
    content: '';
    left: 0;
    width: 100%;
    height: 1px;
    background: #00223D;
    bottom: 0;
}

.todo-list__item-status--complete {
    color: #5BC920;
}

.todo-list__item-status--verification {
    color: #FC9829;
}

.todo-list__item-status--start-btn {
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 8px;
    line-height: 12px;
    color: #00223D;
    border: 1px solid #00223D;
    border-radius: 1px;
    padding: 2px 4px;
    cursor: pointer;
    opacity: 0;
    transition: all .3s;
}

.todo-list-modal {
    max-width: 458px;
}

.todo-list-modal__body {
    padding: 40px 35px;
}

.todo-list-modal p,
.todo-list-modal ol,
.todo-list-modal label {
    color: rgba(0, 34, 61, 0.87);

}

.todo-list-modal__close-btn {
    z-index: 10;
}

.todo-list-modal__btns {
    margin-top: 32px;
}

.todo-list-modal__btns button + button {
    margin-left: 16px;
}

.todo-list-modal label {
    display: flex;
    align-items: center;
}

.todo-list-modal ol {
    padding-left: 0;
    margin: 16px 0;
    list-style: inside decimal;
    line-height: 21px;
}

.todo-list-modal ol li + li {
    margin-top: 6px;
}

.todo-list-modal ol li span {
    display: block;
    color: #00223D5E;
    font-size: 12px;
    line-height: 18px;
}

.todo-list-modal__title {
    color: #00223D;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
}