.card-info-card__wrapper {
  padding: 0 24px 24px;
}

.card-info-card__block {
  padding: 8px 10px 8px 16px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-info-card-logo__block {
  position: relative;
  margin-left: 42px;
}

.card-info-card-logo__block p,
.card-info-card-expires__block p {
  margin: 0;
}

.card-info-card-logo__block::before {
  content: " ";
  position: absolute;
  left: -42px;
  top: -10px;
  height: 36px;
  width: 36px;
  background-size: 270px;
}

.card-info__visa::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") 0 10px no-repeat;
}

.card-info__mastercard::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -46px 10px no-repeat;
}

.card-info__diners-club::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -92px 10px no-repeat;
}

.card-info__discover::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -138px 10px no-repeat;
}

.card-info__jcb::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -184px 10px no-repeat;
}

.card-info__american-express::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -231px 10px no-repeat;
}

.card-info__union-pay::before {
  background: url("../../../components/ModalAddStripe/card_icons.svg") -276px 10px no-repeat;
}

.card-info-replace-card__button,
.card-info-replace-card__button:focus,
.card-info-replace-card__button:active,
.card-info-replace-card__button:hover {
  background-image: none;
  background-color: #f6f7fb;
  border: none;
  box-shadow: none;
}

.card-info-delete-card__button,
.card-info-delete-card__button:focus,
.card-info-delete-card__button:active,
.card-info-delete-card__button:hover {
  background-image: none;
  background-color: #f6f7fb;
  border: none;
  box-shadow: none;
  margin-left: 16px;
}

@media screen and (max-width: 680px) {
  .card-info-card__block {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-info-card-expires__block {
    margin: 10px 0;
  }
}
