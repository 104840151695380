.shopify-reinstall__title {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: normal;
}

.shopify-reinstall__text {
  margin-bottom: 34px;
  font-size: 15px;
}

.shopify-reinstall__button {
  display: inline-block;
  padding: 16px 24px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  background: #1595fe linear-gradient(-180deg, #59b3ff 0, #1595fe 100%);
  border: 1px solid #1696ff;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.1), inset 0 1px 0 1px rgb(255 255 255 / 0.06);
  box-sizing: border-box;
  text-decoration: none;
}

.shopify-reinstall__button:hover,
.shopify-reinstall__button:focus {
  background-image: linear-gradient(-180deg, #47acff 0%, #008cff 100%);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.shopify-reinstall__button:active {
  background-image: linear-gradient(0deg, #47acff 0%, #008cff 100%);
  color: #fff;
  text-decoration: none;
}
