.domain_verification_banner {
  width: 100%;
  padding: 24px 39px 24px 28px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #fff;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.04);
  margin-bottom: 40px;
  position: relative;
  background: #fff url("./assets/domain-verification-banner.png") no-repeat 0 0;
  background-size: cover;
}

@media (max-width: 780px) {
  .domain_verification_banner {
    background-position: 40%;
  }
}

.domain_verification_banner-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 0;
}

.domain_verification_banner-body {
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 24px;
  flex-basis: auto;
  font-weight: 400;
  max-width: 500px;
}

.domain_verification_banner-body a {
  color: #fff;
  text-decoration: underline;
}

.domain_verification_banner-title {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 780px) and (min-width: 750px) {
  .domain_verification_banner-title {
    font-size: 33px;
  }
}

@media (max-width: 749px) and (min-width: 700px) {
  .domain_verification_banner-title {
    font-size: 28px;
  }
}

.domain_verification_banner-cta {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.domain_verification_banner-cta a,
.domain_verification_banner-cta a:hover,
.domain_verification_banner-cta a:focus,
.domain_verification_banner-cta a:active {
  margin-right: 24px;
  background: #ffd71d;
  border-color: transparent;
  box-shadow: none;
}

.domain_verification_banner-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  line-height: 11px;
  text-align: center;
  cursor: pointer;
  height: 11px;
  width: 11px;
  background-image: url("../assets/close-sign.svg");
  z-index: 25;
}
