.create-merchant-center__wrapper {
  margin: 0 auto;
  padding: 56px 90px;
  color: #00223d;
}

@media (max-width: 991px) {
  .create-merchant-center__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .create-merchant-center__wrapper {
    padding: 16px;
  }
}

.create-merchant-center__body-wrapper {
  margin-bottom: 32px;
}

.create-merchant-center__description {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .create-merchant-center__description {
    font-size: 13px;
  }
}

.notification__link_with-right-margin-5 {
  margin-right: 5px;
}

.create-merchant-center__bottom_text_field {
  font-size: 14px;
  margin-top: 30px;
  font-weight: 300;
}