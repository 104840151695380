.facebook-login__wrapper {
  margin: 50px 130px 130px;
  color: #1f2f4d;
}

.facebook-login__title {
  margin-bottom: 40px;
  padding-top: 6px;
  font-size: 31px;
  font-weight: 300;
  line-height: 1;
}

.facebook-login__link-text {
  line-height: 1.9;
}

.facebook-login__description {
  margin-bottom: 20px;
  color: rgb(0 34 61 / 0.77);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 21px;
}

.facebook-login__save-info {
  margin-bottom: 22px;
  color: rgb(0 34 61 / 0.77);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 21px;
}

.facebook-login__description_icon {
  font-size: 29px;
}

.facebook-login__description_notice {
  font-size: 11px;
}

.facebook-login__description_link {
  color: #1f2f4d;
  text-decoration: underline;
}

.facebook-login .facebook-login__cta-button {
  margin-top: 26px;
}

.facebook-login__checkbox {
  width: 10%;
  max-width: 14px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.facebook-login__checkbox-label {
  text-align: left;
  vertical-align: top;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.facebook-login__checkbox-label,
.facebook-login__checkbox-label a {
  color: rgb(0 34 61 / 0.77);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.facebook-login__checkbox-label a {
  text-decoration: underline;
}

.facebook-login__checkbox-label_accent,
.facebook-login__checkbox-label_accent a {
  color: #fe1616;
}

.facebook-login__with-photo-button img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
}

.facebook-login__cta-button[disabled] {
  opacity: 0.5;
  background-color: #4267b2;
  border-color: inherit;
}

@media screen and (max-width: 576px) {
  .facebook-login__wrapper {
    margin: 0 20px;
  }

  .facebook-login__cta-button {
    margin: 0 auto;
    border: 0;
  }
}

/* Styled checkboxes for PLATFORM-17 task */

.custom-checkbox-container {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* stylelint-enable property-no-vendor-prefix */
  user-select: none;
  padding: 0 23px 10px 0;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  box-shadow: inset 0 0 2px rgb(0 34 61 / 0.25);
  border-radius: 2px;
  border: 1px solid #c4cdd5;
  background-color: #fff;
  position: absolute;
  top: 4px;
  left: 0;
  height: 14px;
  width: 14px;
}

.checkmark:focus {
  position: absolute;
  top: 4px;
  left: 0;
  height: 14px;
  width: 14px;
  box-shadow: 0 1px 0 rgb(22 29 37 / 0.05);
  border-radius: 2px;
  border: 2px solid #1696ff;
  background: linear-gradient(180deg, #f7f9fa 0%, #f1f4f6 100%);
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  background-image: linear-gradient(180deg, #fff 0%, #f9fafb 100%);
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  box-shadow: 0 1px 0 #e7eef3;
  border: 2px solid #1696ff;
  background: #1696ff url("./assets/checkmark.svg") center no-repeat;
  background-size: 8px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.facebook-login__reload {
  margin-top: 16px;
}

.reset-button-margin {
  margin-left: -292px;
}

.login-view__button__fb {
  margin-bottom: 20px;
}