.verification-dropdown__wrapper {
    border: 1px solid #C4CDD5;
    border-radius: 2px;
    padding: 24px;
    cursor: pointer;
    font-size: 18px;
}

.default {
    background: url("./img/arrow-icon.svg") 95% center no-repeat;
}

.selected {
    background: url("./img/arrow-down-icon.svg") 95% center no-repeat, linear-gradient(270deg, #EFFFEB 0%, rgba(255, 255, 255, 0) 100%);
}

.verification-dropdown__content {
    border: 1px solid #C4CDD5;
    border-radius: 2px;
    background: #fff;
    padding: 24px;
    border-top: none;
    position: relative;
    font-size: 14px;
}