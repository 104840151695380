.ad-preview-remarketing {
  padding: 24px 24px 39px;
  border-left: 1px solid #e1e3e6;
}

@media (max-width: 500px) {
  .ad-preview-remarketing {
    padding: 24px 10px 39px;
  }
}

.ad-preview-remarketing span {
  font-weight: 500;
  color: #00223d;
  line-height: 21px;
  font-size: 14px;
}

.ad-preview-remarketing p {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 8px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(0 34 61 / 0.38);
}

.ad-preview-remarketing__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ad-preview-remarketing__card {
  max-width: 460px;
  min-width: 230px;
  height: 116px;
  border: 1px solid #dadcdf;
  display: flex;
}

.ad-preview-remarketing__image-wrapper {
  max-width: 230px;
  min-width: 110px;
  height: 114px;
  display: flex;
}

.ad-preview-remarketing__image {
  width: 100%;
  object-fit: cover;
}

.ad-preview-remarketing__desc {
  font-family: Arial, serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
  padding: 8px;
  max-width: 230px;
  min-width: 110px;
  position: relative;
}

.ad-preview-remarketing__headline {
  color: #00223d;
  margin-bottom: 10px;
  max-height: 32px;
  overflow: hidden;
}

.ad-preview-remarketing__ad-badge {
  width: 20px;
  height: 14px;
  background-color: #298e2b;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
}

.ad-preview-remarketing__button {
  width: 72px;
  height: 22px;
  background-color: #5194dc;
  border-radius: 2px;
  position: absolute;
  bottom: 12px;
}

.ad-preview-remarketing__button-link {
  color: #fff;
  font-size: 11px;
  line-height: 22px;
  display: flex;
  justify-content: center;
}

.ad-preview-remarketing__button-link:hover {
  text-decoration: none;
  color: #fff;
}
