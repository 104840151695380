.gl-onboarding-settings__modal {
    width: 458px;
    margin: 25px auto;
}

.gl-onboarding-settings__modal-body {
    padding: 40px;
}

.gl-onboarding-settings__modal-body-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.gl-onboarding-settings__modal__title {
    color: #00223D;
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
}

.gl-onboarding-settings__modal__content {
    color: rgba(0, 34, 61, 0.87);
    text-align: center;
    margin-bottom: 12px;
}

.gl-onboarding-countries-dropdown-input__error {
    color: #fe1616;
    font-size: 12px;
    margin-left: 12px;
    font-weight: 600;
}

.gl-onboarding-countries-dropdown-wrong-phone {
    color: #fe1616;
    font-size: 12px;
    margin-left: 12px;
    font-weight: 600;
    position: relative;
    left: -100px;
}

.gl-onboarding-settings__modal__close-icon {
    background: url("./img/close-icon.svg");
    height: 10px;
    position: absolute;
    width: 10px;
    top: 25px;
    right: 25px;
    cursor: pointer;
}