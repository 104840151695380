.dashboard-page {
  padding: 40px 0;
  width: 100%;
}

.dashboard-page__section:not(:last-child) {
  margin: 10px auto 16px;
}

.dashboard-page-trial__block {
  height: 40px;
  background-color: #04365b;
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .dashboard-page-trial__block {
    flex-direction: column;
    height: auto;
    text-align: center;
    padding: 5px 15px;
  }
}

.dashboard-page-trial__block p {
  line-height: 21px;
  margin: 0;
}

.dashboard-page-trial__block p::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #fff;
  mask-image: url("../../components/Icons/info-icon-white.svg");
  mask-repeat: no-repeat;
  top: 2px;
  right: 6px;
}

.dashboard-page-trial__block a {
  color: #fff;
}

.dashboard-modal-wrapper {
  width: 480px;
}

.dashboard-modal {
  padding: 6px 18px;
}

.dashboard-modal-checkbox {
  margin-top: 22px;
  margin-bottom: 22px;
}

.dashboard-modal-buttons-wrapper {
  display: flex;
  justify-content: space-around;
  width: 375px;
  margin: 0 auto;
  margin-top: 24px;
}