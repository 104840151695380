.countries-dropdown-menu {
    background: #fff;
    width: 340px;
}

.countries-dropdown-item {
    color: #00223D;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 52px;
    cursor: pointer;
    transition: all .3s;
}

.countries-dropdown-item:hover {
    background: #F7F9FA;
}

.countries-dropdown-flag {
    width: fit-content;
    max-width: 100%;
    justify-content: flex-start;
    display: flex;
    position: relative;
    padding-right: 30px;
    color: #758896;
}

.countries-dropdown-flag--error {
    border-color: #FE1616;
}

.countries-dropdown-flag--error span {
    color: #FE1616;
}

.countries-dropdown-flag span {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.countries-dropdown-flag > i {
    position: absolute;
    top: 10px;
    right: 12px;
}

.countries-dropdown-item + .countries-dropdown-item {
    border-top: 1px solid #D6DADF
}

.countries-dropdown-item--worldwide {
    border-bottom: 1px solid #D6DADF
}

.countries-dropdown-item--selected {
    position: relative;
    background: #F7F9FA;
}

.countries-dropdown-item--selected::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 16px;
    width: 24px;
    height: 24px;
    background: url('./components/CountriesDropdown/icons/check.svg') no-repeat center;
    background-size: 100% 100%;
}

.countries-dropdown-search {
    border: 0;
    padding-left: 56px;
    border-bottom: 2px solid #d6dadf;
    width: 100%;
    background: #fff url('./components/CountriesDropdown/icons/search-icon.svg') 16px center no-repeat;
    font-size: 14px;
    position: sticky;
    top: 0;
    height: 40px;
    z-index: 1;
}

.countries-dropdown-search::placeholder {
    color: rgba(0, 34, 61, 1);
    font-size: 14px;
}

.ad-audience-form-group {
    display: flex;
    align-items: center;
}

.ad-audience-form-group__label {
    color: rgba(0, 34, 61, 0.87);
    font-size: 14px;
    line-height: 1.5;
    min-width: 32px;
    margin-right: 16px;
}

.ad-audience-form-group__label--margin {
    min-width: auto;
    margin-left: 16px;
}

.countries-dropdown-info {
    padding: 8px 20px 8px 18px;
    border-bottom: 1px solid #D6DADF;
    font-size: 10px;
    line-height: 1.5;
}

.ad-audience-block + .ad-audience-block {
    margin-top: 20px;
}

.age-wrapper {
    display: flex;
    align-items: center;
}

.age-dropdown-menu {
    background: #FFFFFF;
    min-width: 90px;
}

