.nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0;
}

.tabs-navigation {
  margin-top: -1px;
  margin-left: 1px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-wrap: wrap;
}

.tabs-navigation .nav-item:first-child .tabs-navigation__link {
  border-left: none;
}

.tabs-navigation__link-content-static {
  position: relative;
  min-height: 47px;
  text-align: center;
  color: rgb(0 34 61 / 0.54);
  line-height: 42px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  cursor: pointer;
  transition: 0.3s;
  border-top: 2px solid transparent;
}

.tabs-navigation__link-content-static:hover {
  color: #00223d;
}

.tabs-navigation__link_active .tabs-navigation__link-content-static {
  border-top-color: #1696ff;
  color: #00223d;
  background-color: #fff;
}

.tabs-navigation__link-content-static span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  font-size: 14px;
  line-height: 1.1;
  padding: 6px 0;
}

.tabs-navigation .nav-item:first-child .tabs-navigation__link_active {
  border-left: none;
}

.nav-item:first-child .tabs-navigation__link_active .tabs-navigation__link-content-static::after,
.tabs-navigation__link_active .tabs-navigation__link-content-static::before,
.tabs-navigation__link_active .tabs-navigation__link-content-static::after,
.tabs-navigation__link-content-static::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #dee2e6;
}

.nav-item:last-child .disable-tab {
  pointer-events: none;
}

.tabs-content > .tabs-pane {
  display: none;
}

.tabs-content > .active {
  display: block;
}
