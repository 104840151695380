.cancel-plan-modal {
    max-width: 500px;
    padding: 40px;
}

.cancel-plan-modal__header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.25px;
    color: #00223D;
}

.cancel-plan-modal__body p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 34, 61, 0.87);
}

.cancel-plan-modal__body .billing-plan__link {
    font-size: 14px;
}

.cancel-plan-modal__textarea {
    width: 100%;
    resize: none;
    padding: 8px;
    border-radius: 0.3em;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(0, 34, 61, 0.87);
}

.cancel-plan-modal__input-tip {
    font-size: 10px;
    display: block;
    font-weight: bold;
}

.cancel-plan-modal__buttons {
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: space-between;

}

.cancel-plan-modal__buttons button {
    flex-basis: calc(50% - 8px);
}

.cancel-plan-modal__buttons button + button {
    margin-left: 16px;
}