.modal-page-error__dialog,
.modal-page-error__dialog.c-modal__inner,
.c-modal .modal-page-error__dialog.c-modal__inner {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -40%);
  margin: 0;
  width: 90%;
  max-width: 470px;
  max-height: 90%;
}

.modal-page-error__dialog {
  position: relative;
}

.modal-page-error__dialog .c-modal__content,
.modal-page-error__dialog.c-modal__inner .c-modal__content {
  padding: 0 40px 32px;
  border-radius: 12px;
}

@media (max-width: 480px) {
  .modal-page-error__dialog .c-modal__content,
  .modal-page-error__dialog.c-modal__inner .c-modal__content {
    padding: 0 12px 24px;
  }
}

.modal-page-error__body,
.modal-page-error__body.modal-body {
  margin: 0 0 8px;
  padding: 0;
}

.modal-page-error__body.error {
  text-align: center;
}

.modal-page-error-body__logo {
  display: block;
  height: 56px;
  width: 56px;
  margin: 24px auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.modal-page-error-body__logo.try-again {
  background-image: url("./again.svg");
}

.modal-page-error-body__logo.error {
  background-image: url("./error.svg");
}

.modal-page-error-body__content {
  color: #1f2f4d;
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  font-weight: 400;
  margin: 0;
}

.modal-page-error-body__content .notification__link {
  font-size: 14px;
  font-weight: 400;
}

.modal-page-error__list {
  margin: 0;
  padding: 18px;
}

.modal-page-error__list-item:not(:last-child) {
  margin-bottom: 8px;
}

.modal-page-error__footer,
.modal-page-error__footer.modal-footer {
  padding: 0;
  border: none;
  justify-content: start;
}

.modal-page-error__footer.error {
  justify-content: center;
}

.modal-page-error__footer > :not(:last-child),
.modal-page-error__footer.modal-footer > :not(:last-child) {
  margin-right: 16px;
}

.modal-page-error-body__underline,
.modal-page-error-body__underline:hover,
.modal-page-error-body__underline:focus {
  color: #758896;
  text-decoration: underline;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.modal-page-error-body__underline:hover {
  cursor: pointer;
}

.modal-page-error__close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  display: inline-block;
  width: 12px;
  height: 12px;
  font-size: 0;
  vertical-align: middle;
  background: url("./close.svg") no-repeat;
  background-size: cover;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
}
