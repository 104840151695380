.standard-ads-edit__wrapper {
  margin: 0 auto;
  padding: 36px 0;
  width: 100%;
}

.standard-ads-edit__ad-preview {
  border: 1px solid #d6dadf;
  border-radius: 2px;
  margin-bottom: 16px;
  background-color: #fff;
  padding: 16px 24px 24px;
}

.standard-ads-edit__ad-preview p {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 8px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(0 34 61 / 0.38);
}
