.pen-icon {
  vertical-align: middle;
}

.pen-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: rgb(0 34 61 / 0.54);
  mask-image: url("pen.svg");
  mask-repeat: no-repeat;
}

.button .pen-icon::before {
  margin: 0 6px 0 0;
}

.button:hover .pen-icon::before {
  background-color: #1696ff;
}

.arrow-left-icon {
  vertical-align: middle;
}

.arrow-left-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 13px;
  background-color: rgb(0 34 61 / 1);
  mask-image: url("arrow-left.svg");
  mask-repeat: no-repeat;
}

.button .arrow-left-icon::before {
  margin-right: 6px;
}

.button_square .arrow-left-icon::before {
  margin-right: 0;
}

.arrow-right-icon {
  vertical-align: middle;
}

.arrow-right-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 8px;
  height: 13px;
  background-color: rgb(0 34 61 / 1);
  mask-image: url("arrow-left.svg");
  transform: rotateY(180deg);
  mask-repeat: no-repeat;
}

.plus-icon {
  vertical-align: middle;
}

.plus-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: rgb(0 34 61 / 1);
  mask-image: url("plus.svg");
  mask-repeat: no-repeat;
}

.caret-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 7px;
  height: 5px;
  background-color: rgb(0 34 61 / 1);
  mask-image: url("caret.svg");
  mask-repeat: no-repeat;
  vertical-align: middle;
  margin-left: 5px;
}
.caret-icon_white::before {
  background-color: #fff;
}

.close-icon {
  line-height: 14px;
  height: 14px;
  display: inline-block;
}

.close-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  background-color: rgb(0 34 61 / 1);
  mask-image: url("close-icon.svg");
  mask-repeat: no-repeat;
  vertical-align: middle;
}

.close-icon_white::before {
  background-color: #fff;
}

.info-icon {
  line-height: 16px;
  height: 16px;
  display: inline-block;
}

.info-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #a1aeb8;
  mask-image: url("info-icon.svg");
  mask-repeat: no-repeat;
}

.blue-info-icon {
  line-height: 16px;
  height: 16px;
  display: inline-block;
}

.blue-info-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 17px;
  line-height: 16px;
  background-color: #1696FF;
  mask-image: url("info-icon-blue.svg");
  mask-repeat: no-repeat;
}

.warning-icon {
  line-height: 16px;
  height: 16px;
  display: inline-block;
  left: 4px;
}

.warning-icon::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-color: #FE9929;
  mask-image: url("warning-icon.svg");
  mask-repeat: no-repeat;
}
