.bfcm-decor {
    height: 113px;
}

.bfcm-decor--banner {
    background: url("./img/easter_2023.png") no-repeat right -3px top -1px;
    background-size: 275px 114px;
}

.bfcm-decor__badge {
    position: absolute;
    top: 53px;
    left: calc(50% + 170px);
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 17px;
    padding: 2px 8px;
    background: #FE1616;
    border-radius: 24px;
    color: #FFFFFF;
    letter-spacing: -0.02em;
    font-weight: 500;

}

.bfcm-decor__badge::before {
    position: absolute;
    content: '';
    background: url('./img/arrow-decor.svg') no-repeat center;
    background-size: 100% 100%;
    width: 60px;
    height: 26px;
    top: -25px;
    left: -8px;
}

.choose-plan__wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 1184px;
    color: rgba(0, 34, 61, 0.87);
    position: relative;
  }

.choose-plan__bfcm-decor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
  
.choose-plan__main-section {
    display: flex;
    justify-content: center;
}

.choose-plan__title-wrapper {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.chose-plan__top-middle-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.choose-plan__title {
    font-size: 32px;
    font-weight: 400;
    color: #00223D;
}
  
.choose-plan__subtitle {
    font-size: 24px;
    color: #758896;
    margin-bottom: 36px;
    font-weight: 300;
}
  
.choose-plan__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 252px;
    border: 1px solid #D6DADF;
    border-radius: 8px;
    padding: 24px;
    min-height: 450px;
}

.choose-plan__item--accent {
    border-color: #07AE3A;
}
  
.choose-plan__item h6 {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 0;
}

.choose-plan__item:not(:last-of-type) {
    margin-right: 32px;
}

.choose-plan__item.choose-plan__item--last {
    margin-right: 0;
    margin-left: 32px;
    order: 4;
}

.choose-plan__price {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    position: relative;
    margin-bottom: 4px;
    margin-top: 4px;
}

.choose-plan__price--accent {
    color: #07AE3A;
}

.choose-plan__price span {
    font-size: 20px;
    color: #758896;
    font-weight: 500;
}

.choose-plan__price span.choose-plan__price--old {
    color: #FEA038;
    /*text-decoration: line-through;*/
    position: absolute;
    top: -20px;
}

.choose-plan__price span.choose-plan__price--old::after {
    content: '';
    position: absolute;
    background: #FEA038;
    height: 2px;
    width: 41px;
    top: 23px;
    left: -3px;
}

.choose-plan__description {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #758896;
    margin-bottom: 24px;
}

.choose-plan__description .choose-plan__highlight {
    color: #FE9929;
    font-weight: 400;
}

.choose-plan__description p {
    color: #C4CDD5;
    font-weight: 600;
    margin-bottom: 0;
}

.choose-plan__description span {
    font-weight: 600;
}

.choose-plan__description span b {
    color: #07AE3A;
}

.choose-plan__description--limit {
    color: #00223D;
    height: auto;
}

.choose-plan__list {
    list-style: none;
    padding: 0;
}

.choose-plan__list .highlighted {
    background: #EFFFEB;
    border-radius: 4px;
    padding: 2px 8px;
    margin-left: -8px;
}

.choose-plan__tooltip-icon {
    margin-left: 8px;
}

.choose-plan__description b {
    display: flex;
    align-items: center;
}

.choose-plan__list li {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 21px;
}

.choose-plan__list li span {
    color: #07AE3A;
}

.choose-plan__text {
    color: #758896;
    margin: 32px 0 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
}
  
.choose-plan__text-link,
.choose-plan__text-link:hover,
.choose-plan__text-link:active {
    color: #1596ff;
    font-weight: 300;
    text-decoration: underline;
}
  
.choose-plan__cta-button {
    margin-top: 8px;
    font-weight: 600;
}

.choose-plan__name-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.choose-plan__description b {
    color: #00223D;
    flex-shrink: 0;
    white-space: nowrap;
}

.choose-plan__badge {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    border: 1px solid #07AE3A;
    margin-left: 8px;
    border-radius: 16px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.25px;
    color: #07AE3A;
    white-space: nowrap;
}

.choose-plan__badge.choose-plan__badge--red {
    background: #FE1616;
    color: #FFFFFF;
    border: none;
}
  
@media (max-width: 576px) {
    .choose-plan__title {
        font-size: 24px;
        padding: 0;
        max-width: 290px;
        text-align: center;
        margin: 24px auto 0;
    }

    .choose-plan__subtitle {
        font-size: 16px;
        padding: 0;
        max-width: 290px;
        text-align: center;
        margin: 24px auto 0;
    }
  
    .choose-plan__wrapper {
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }
  
    .choose-plan__main-section {
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }

    .choose-plan__item:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 12px;
    }

    .choose-plan__description {
        margin-bottom: 24px;
        font-size: 13px;
    }
  
    .choose-plan__cta-button {
        position: relative;
        bottom: auto;
        left: auto;
        transform: none;
    }

    .choose-plan__title-wrapper {
        flex-direction: column;
        display: flex;
        position: relative;
        z-index: 2;
    }

    .choose-plan__buttons-wrapper {
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }

    .bfcm-decor__badge {
        transform: rotate(90deg);
        left: auto;
        right: -90px;
        top: 270px;
    }

    .bfcm-decor__badge::before {
        transform: rotate(315deg);
        top: 21px;
        left: -54px;

    }

}
    