.facebook_button_wrapper {
    background: #1877F2 url("./facebook_logo.svg") no-repeat 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 2px 3px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    color: #fff !important;
    font-size: 16px;
    max-width: 226px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    padding-left: 34px;
    white-space: nowrap;
    text-decoration: none !important;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: none;
}

.facebook_button_wrapper:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #1877F2 url("./facebook_logo.svg") no-repeat 10px;
}

.facebook_button_wrapper:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #1877F2 url("./facebook_logo.svg") no-repeat 10px;
}
