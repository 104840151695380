.campaign-details__admessage-wrapper {
  margin-bottom: 14px;
}

.campaign-details__link,
.campaign-details__link.btn-link {
  padding: 0;
  background: transparent;
  border: none;
  color: #1696ff;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
}

.campaign-details__link:hover,
.campaign-details__link:focus,
.campaign-details__link.btn-link:hover,
.campaign-details__link.btn-link:focus {
  color: #1378cc;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
}

.campaign-details__link-tips.tooltip-inner {
  padding: 0;
  max-width: 420px;
  width: 420px;
  border-radius: 2px;
}

.campaign-details__advice-tip {
  padding: 0;
  background-color: #00223d;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
  color: rgb(255 255 255 / 0.8);
  font-size: 11px;
}

@media (max-width: 480px) {
  .campaign-details-footer-advice__content {
    display: flex;
    flex-flow: column nowrap;
  }
}

.campaign-details__advice-tip-section {
  display: inline-block;
  padding: 0;
  border-right: 1px solid rgb(255 255 255 / 0.2);
  cursor: pointer;
}

@media (max-width: 480px) {
  .campaign-details__advice-tip-section {
    display: block;
    border-bottom: 1px solid rgb(255 255 255 / 0.4);
  }
}

.campaign-details__advice-tip-section:last-child {
  border: none;
}

.campaign-details__advice-tip-title {
  margin: 0;
  padding: 10px 16px 4px;
  color: #fff;
  font-size: 13px;
}

.campaign-details__advice-tip-description {
  margin: 0;
  padding: 4px 16px 10px;
  color: rgb(255 255 255 / 0.8);
  font-weight: 300;
}

.campaign-details__advice-min-db {
  font-size: 16px;
  font-weight: 500;
  display: block;
}