.shop-protected__wrapper {
  margin: 0 auto;
  padding: 56px 16px;
  max-width: 656px;
}

.shop-protected__error {
  color: rgba(254, 22, 22, 1);
  font-size: 14px;
  margin-bottom: 18px;
  margin-top: -12px;
}
  
.shop-protected__main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shop-protected__title {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 300;
}
  
.shop-protected__description {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  white-space: nowrap;
}

.shop-protected__description_bottom {
  margin-top: 18px;
}

.shop-protected__checkbox {
  margin: 0;
}

.shop-protected__buttons {
  display: flex;
}

.shop-protected__button {
  margin-right: 32px;
}
  
.shop-protected__text-link,
.shop-protected__text-link:hover,
.shop-protected__text-link:active {
  color: #1596ff;
  font-weight: 400;
  margin-top: 8px;
  display: block;
}
  
@media (max-width: 576px) {
  .shop-protected__title {
    font-size: 19px;
    padding: 0;
    max-width: 290px;
    text-align: center;
    margin: 24px auto 0;
  }
  
  .shop-protected__wrapper {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  
  .shop-protected__main-section {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  
  .shop-protected__description {
    margin-bottom: 24px;
    font-size: 13px;
  }
}
    