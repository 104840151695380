.bulk-notification__wrapper {
  position: fixed;
  margin: 0 auto;
  right: -50px;
  bottom: 84px;
  left: -50px;
  z-index: 9000;
  width: 100%;
  max-width: 470px;
  text-align: center;
}

.bulk-notification {
  padding: 15px 16px 18px 44px;
  color: #fff;
  background: #1f2f4d;
  border-radius: 4px;
}

.bulk-notification::before {
  content: "";
  position: absolute;
  display: block;
  top: 16px;
  left: 16px;
  width: 19px;
  height: 15px;
  background: 50% no-repeat;
}

.bulk-notification_success::before {
  content: "";
  background-image: url("./check-icon.svg");
}

.bulk-notification_error::before {
  content: "";
  background-image: url("./alert-icon.svg");
}

.bulk-notification__text {
  padding: 0;
  margin: 0;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 100;
}
