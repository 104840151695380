.error-screen__wrapper {
    width: 912px;
    background: #FFF;
    text-align: center;
    border: 1px solid #D6DADF;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
    margin: 40px 0;
    height: 520px;
}

.error-screen__img {
    width: 100px;
    height: 100px;
    margin-top: 56px;
    margin-bottom: 32px;
}

.error-screen__caption {
    color: #00223D;
    font-size: 32px;
    margin-bottom: 32px;
}

.error-screen__text {
    color: #00223D;
    margin: 0 auto;
    margin-bottom: 32px;
}

.error-screen__tips {
    background: #F7F9FA;
    border-radius: 4px;
    margin: 32px;
    font-size: 14px;
    margin-top: 60px;
}

.tips__loader__wrapper {
    display: flex;
}

.error-screen__tips__loader__wrapper {
    display: flex;
    border-bottom: 1px solid #D6DADF;
}

.error-screen__tips__container {
    padding: 16px;
}

.error-screen__tips__loader {
    height: 4px;
    background: #1696FF;
}

.tips__loader {
    height: 4px;
    background: #D6DADF;
    border-radius: 0 0 0 8px;
}

.error-screen__icon-wrapper {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 32px;
    margin-top: 80px;
}

.error-screen__loader-image--spinner {
    animation: loader-spinner infinite 2s linear;
    position: absolute;
    height: 90px;
}

.error-screen__icon__inner {
    background: #EBF6FF;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    padding: 13px;
}
