.campaign-page-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 800px) {
  .campaign-page-layout {
    flex-direction: row;
  }
}

.campaign-page-layout__column {
  display: block;
  width: 100%;
}

@media (min-width: 800px) {
  .campaign-page-layout__column {
    width: calc(50% - 8px);
  }
}

.campaign-page-layout .card-block {
  margin-bottom: 16px;
}
