.settings-page {
  width: 100%;
}

.settings-page__wrapper {
  margin: 36px auto 16px;
}

.settings-page__container {
  margin: 20px auto;
  padding-top: 16px;
}

.settings-page__button-container {
  margin-bottom: 16px;
}

.settings-page__block {
  padding: 0 24px 16px;
}

.settings-page__block-content_flex {
  display: flex;
}

.settings-page__block-content_flex_column {
  display: flex;
  flex-direction: column;
}

.settings-page__block-content-wrapper {
  width: 100%;
}

.settings-page__block-footer {
  padding-top: 12px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  border-top: 1px solid #d2d5db;
}

.settings-page__block-footer button:last-child {
  margin-left: 12px;
}

.settings-page__block_padding-top-no {
  padding-top: 0;
}

.settings-page__block-content-sidebar {
  width: 32%;
}

.settings-page__block-content-sidebar_flex-right {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 0 1%;
  margin: 16px 0;
}

.billing-info-page__link {
  padding: 5px 0;
  font-size: 14px;
  text-decoration: underline;
  color: rgb(0 34 61 / 0.87);
}

.billing-plan_section {
  margin-bottom: -1px;
}

.billing-plan__wrap {
  display: flex;
  justify-content: space-between;
}

.billing-plan__link {
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
}

.billing-plan__link:focus {
  outline: none;
  border: none;
}

.billing-plan__link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.settings-page__link {
  padding: 5px 0;
  font-size: 13px;
  color: #7e8799;
  text-decoration: underline;
}

.settings-page__link_highlighted {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #1696ff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.settings-page__link_highlighted:hover,
.settings-page__link_highlighted:focus {
  background-color: transparent;
  border: none;
  color: #147dd4;
  text-decoration: none;
  outline: none;
}

.settings-page__link_highlighted:active {
  background-color: transparent;
  border: none;
  color: #1897ff;
  text-decoration: none;
}

.settings-page__link_with-arrow {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  margin-top: 10px;
  color: #7e8799;
  font-size: 13px;
}

.settings-page__link_with-arrow:hover {
  text-decoration: none;
  color: #7e8799;
}

.settings-page__form-control {
  background:
    url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+")
    no-repeat 98% 50%;
  appearance: none;
}

.settings-page__form-control:focus {
  box-shadow: none;
  outline: none;
}

.settings-page__form-control:disabled,
.settings-page__form-control.disabled,
.settings-page__form-control:disabled:hover,
.settings-page__form-control.disabled:hover,
.settings-page__form-control:disabled:focus,
.settings-page__form-control.disabled:focus,
.settings-page__form-control:disabled:active,
.settings-page__form-control.disabled:active {
  background: #e9ecef;
  cursor: default;
  user-select: none;
}

.settings-page-info {
  box-sizing: border-box;
  width: 100%;
  max-width: 276px;
  padding: 0 20px 16px;
  color: #00223d;
  background: rgb(31 47 77 / 0.04);
  border: 1px solid rgb(31 47 77 / 0.16);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.04);
  border-radius: 2px;
}

.settings-page-info__title {
  font-weight: 500;
  font-size: 14px;
  margin: 12px 0 8px;
}

.settings-page-info__content {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
}

.settings-page-info__content_capitalised {
  text-transform: capitalize;
}

.settings-page-form {
  max-width: 340px;
  width: 95%;
}

/* Loader Start */
.loading {
  position: relative;
  color: transparent;
  cursor: default;
  pointer-events: none;
}

.loading:hover,
.loading:focus,
.loading:active {
  color: transparent;
}

.loading::before {
  content: "";
  position: absolute;
  left: calc(50% - 8px);
  bottom: calc(50% - 7px);
  width: 16px;
  height: 16px;
  background-color: #00223d;
  mask-image: url("spinner-icon.svg");
  mask-repeat: no-repeat;
  animation: load 0.8s linear infinite;
}

.loading_light::before {
  background-color: #fff;
}

.billing-plan-statistics_block-progress {
  height: 0.5rem;
  margin: 24px 0 16px;
}

.billing-plan-statistics_block-progress-bar {
  background-color: #5bc920;
  border-radius: 0.25rem;
}

.billing-plan-statistics_block-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.billing-plan-statistics_block-details div {
  width: 100%;
  height: 24px;
}

.billing-plan-statistics_block-details div p {
  margin: 0;
}

.billing-plan-statistics_block-details_small {
  font-size: 12px;
  color: #a5b1ba;
}

.billing-history__description-text {
  margin-top: 0;
}

.billing-history__description-wrapper {
  margin-top: 8px;
  margin-bottom: 32px;
  align-items: flex-end;
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .billing-plan-statistics_block-details div,
  .billing-plan-statistics_block-details div:nth-child(2n) {
    width: 100%;
    height: 26px;
    text-align: left;
  }
}

/* Loader End */
