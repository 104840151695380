.trial-badge {
  background: #ebf6ff;
  border: 1px solid #aed4f2;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.04);
  font-weight: 600;
  font-size: 14px;
  color: #00223d;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding: 12px 16px;
  border-radius: 28px;
}

.trial-badge-bonus {
  padding: 10px 8px 14px;
}

.trial-badge_absolute {
  position: absolute;
  top: -10px;
  right: 20px;
}

.trial-badge__title {
  margin: 0 16px;
  vertical-align: middle;
}

.trial-badge .tips__wrapper {
  margin-left: 4px;
  display: inline-flex;
  vertical-align: middle;
}

.trial-badge-extended {
  color: #fff;
  background: rgb(51 163 255 / 1);
  background: linear-gradient(to bottom, #33a3ff 0%, #008cff 100%);
  border: 1px solid #1270bc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 9px 1px 22px;
  width: 268px;
  line-height: 19px;
  cursor: pointer;
}

.trial-badge-extended:hover {
  background: rgb(49 157 245 / 1);
  background: linear-gradient(to bottom, #319df5 0%, #0087f5 100%);
  border: 1px solid #1270bc;
  outline: none;
}

.trial-badge-extended:focus,
.trial-badge-extended:active {
  background: rgb(49 157 245 / 1);
  background: linear-gradient(to bottom, #319df5 0%, #0087f5 100%);
  border: 1px solid #11568f;
  outline: none;
}

.trial-badge-extended .tips__wrapper {
  height: 23px;
}

.trial-badge-extended_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: #fff;
  font-weight: normal;
}

.trial-badge-extended_block p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.trial-badge-extended_block span {
  margin: 0;
  font-size: 10px;
}

.tips-widget-icon {
  width: 20px;
}
