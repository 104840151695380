.gl-onboarding-settings__modal__sms__input {
    width: 34px;
    height: 46px;
    background: #fff;
    border: 1px solid #C4CDD5;
    border-radius: 2px;
    margin: 8px;
    color: #203E56;
    text-align: center;
    font-size: 20px;
}

.gl-onboarding-settings__modal__sms__input_error {
    border: 1px solid rgba(254, 22, 22, 1)
}