.login-view__white-substrate.choose-shop {
  max-width: 740px;
  min-width: 440px;
  border-radius: 12px;
  padding: 40px;
  font-family: Roboto, sans-serif;
}

.choose-shop .shops-install__on {
  margin: 0 auto 32px;
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  color: #00223D;
  opacity: 1;
}

.shops-install__list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.shops-install__item {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c4cdd5;
  background-color: #ffffff;
  background-image: none;
  width: 322px;
  height: 68px;
  margin-bottom: 16px;
  transition: border 0.1s linear;
}

.shops-install__item:active,
.shops-install__item:hover,
.shops-install__item:focus {
  outline: none;
  border: 1px solid #07AE3A;
  background-color: #ffffff;
  background-image: none;
  box-shadow: none;
  transition: all 0.1s ease-out;
}

.shops-install__item-active:hover,
.shops-install__item-active {
  outline: none;
  border: 2px solid #07AE3A;
  box-shadow: 0px 2px 12px rgba(216, 216, 216, 0.5);
}

.shops-install__item img {
  height: 32px;
  width: 100%;
  margin: 0 auto;
}

.shops-install__item--bc img {
  height: 34px;
}

.shops-install__item--other img {
  height: 21px;
}

.shops-install-url__wrapper {
  width: 100%;
  text-align: left;
  margin-top: 8px;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  will-change: opacity;
}

.shops-install-url__wrapper.shops-install-url__wrapper-visible {
  opacity: 1;
  max-height: 400px;
  transition: max-height 0.2s ease-out;
}

.shops-install-url__header {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #00223d;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}

.shops-install-url__header span {
  margin-left: 8px;
}

.shops-install-url__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
}

.shops-install-url__form div {
  width: 100%;
  text-align: center;
  height: 48px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 8px;
}

.shops-install-url__input {
  padding: 0 215px 0 20px;
  outline: none;
  width: 100%;
  height: 48px;
  background: #fff;
  border: 1px solid #07AE3A;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #00223d;
}

.shops-install-url__domain {
  position: absolute;
  margin-left: 134px;
  padding-right: 8px;
  user-select: none;
  color: #758896;
  font-size: 16px;
  line-height: 24px;
}

.shops-install-url__input::placeholder {
  color: #758896;
  font-size: 16px;
  line-height: 24px;
}

.shops-install-url__input:focus,
.shops-install-url__input:active {
  outline: none;
}

.shops-install-url__error {
  box-sizing: border-box;
  width: 100%;
  line-height: 21px;
  margin: 6px 0 4px;
  padding-left: 8px;
  height: 21px;
  font-size: 14px;
  color: #f34242;
  text-align: left;
}

.shops-install-url-form__btn {
  color: #fff;
  border-radius: 50px;
  background-color: #07ae3a;
  border: none;
  box-shadow: none;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 0 24px;
  position: absolute;
  cursor: pointer;
  right: 8px;
}

.shops-install-url-form__btn:focus,
.shops-install-url-form__btn:active {
  outline: none;
}

.shops-install-url-form__btn:hover {
  cursor: pointer;
}

.shops-install-url-form__btn:disabled {
  color: #ffffff;
  opacity: .5;
  pointer-events: none;
}

.shops-install-url-form__btn:disabled:hover {
  cursor: auto;
}

.shops-install__partners-list {
  display: flex;
  width: 400px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
