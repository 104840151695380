.ad-preview-loader {
  height: 32px;
}

.ad-preview-loader_spinner {
  animation: loader-spinner infinite 0.7s linear;
}

@keyframes loader-spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
