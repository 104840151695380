.connect-bm__wrapper {
    margin: 0 auto;
    padding: 56px 128px;
    color: #00223d;
  }

.connect-bm__wrapper h6 {
  margin-top: 24px;
}
  
  @media (max-width: 991px) {
    .connect-bm__wrapper {
      padding: 35px 68px;
    }
  }
  
  @media (max-width: 576px) {
    .connect-bm__wrapper {
      padding: 16px;
    }
  }
  
  .connect-bm__title {
    margin-bottom: 40px;
    font-size: 31px;
    font-weight: 300;
  }
  
  @media (max-width: 576px) {
    .connect-bm__title {
      margin: 8px 0 24px;
      font-size: 19px;
    }
  }
  
  .connect-bm__description {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 300;
    max-width: 450px;
  }

  .connect-bm__description--sm {
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 21px;
    color: #758896;
  }
  
  @media (max-width: 576px) {
    .connect-bm__description {
      font-size: 13px;
    }
  }

  .connect-bm__body-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  
  .connect-bm__dropdown {
    max-width: 340px;
  }
  
  .connect-bm__dropdown-menu {
    margin: -1px 0 0;
    padding: 0;
    width: 100%;
    border-color: #c4cdd5;
    overflow: hidden;
    max-height: 224px; 
    overflow-y: scroll;
  }

  .connect-bm__dropdown .dropdown-item-main {
    height: 56px;
  }

  .connect-bm__dropdown .flag-toggler {
    min-height: 56px;
  }
