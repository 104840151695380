.standard-toggler .caret-icon,
.transparent-toggler .caret-icon {
  margin-left: 20px;
}

.transparent-toggler {
  margin: 0;
  padding: 9px 12px;
  border-radius: 2px;
  border: 1px solid rgb(255 255 255 / 0.8);
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: default;
}

.transparent-toggler:hover,
.transparent-toggler:focus {
  border-color: #fff;
  color: #fff;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.transparent-toggler:active,
.transparent-toggler.active {
  border: 1px solid rgb(255 255 255 / 0.9);
  background-color: transparent;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.transparent-toggler[disabled] {
  cursor: not-allowed;
}

.flag-toggler {
  position: relative;
  display: block;
  margin: 0 auto 8px;
  padding: 8px 40px 8px 73px;
  width: 100%;
  height: 56px;
  background-image: linear-gradient(-180deg, #fff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 #f2f4f5;
  border-radius: 4px;
  color: rgb(0 34 61 / 0.87);
  font-size: 15px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media (max-width: 576px) {
  .flag-toggler {
    padding: 8px 40px 8px 23px;
  }
}

.flag-toggler:hover,
.flag-toggler:focus {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

.flag-toggler:active {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
  outline: none;
}

.flag-toggler::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 52px;
  display: block;
  width: 20px;
  height: 100%;
  background: url("flag-icon.svg") 14px no-repeat;
  background-size: 204x;
  font-size: 30px;
  font-weight: bold;
  border-right: 1px solid #c4cdd5;
}

.flag-toggler.find-icon::before {
  background: url("find-icon.svg") 14px no-repeat;
}

@media (max-width: 576px) {
  .flag-toggler::before {
    display: none;
  }
}

.flag-toggler.case-icon::before {
  background: url("case-icon.svg") 14px no-repeat;
}

.flag-toggler.custom-icon::before {
  display: none;
}

@media (max-width: 576px) {
  .flag-toggler::before {
    display: none;
  }
}

.flag-toggler .caret-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.flag-toggler[disabled] {
  opacity: 0.5;
  user-select: none;
  cursor: not-allowed;
}

.flag-toggler[disabled]:hover,
.flag-toggler[disabled]:focus {
  background-image: linear-gradient(-180deg, #fff 0%, #f9fafb 100%);
}

.flag-toggler__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00223D;
}

.flag-toggler__subtitle {
  font-weight: 400;
  font-size: 10px;
  color: rgb(0 34 61 / 0.54);
  line-height: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
