.dropdown-item-icon {
  position: relative;
  padding: 3px 0 3px 40px;
  color: #00223d;
  display: block;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 21px;
}

.dropdown-item-icon:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.dropdown-item-icon:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dropdown-item-icon:hover,
.dropdown-item-icon:focus {
  background-color: #f7f9fa;
  color: #00223d;
  cursor: pointer;
  outline: none;
}

.dropdown-item-icon:active,
.dropdown-item-icon.active {
  background-color: #f2f4f5;
  color: #00223d;
  outline: none;
}

.dropdown-item-icon:active::after,
.dropdown-item-icon.active::after {
  content: "";
  position: absolute;
  top: -1px;
  left: 11px;
  width: 18px;
  height: 100%;
  background: url("check.svg") 0 no-repeat;
  background-size: 18px;
}

.dropdown-item-icon__icon {
  margin-right: 12px;
  max-width: 50px;
}

@media screen and (max-width: 480px) {
  .dropdown-item-icon__icon {
    display: none;
  }
}

.dropdown-item-main {
  position: relative;
  padding: 10px 0 10px 51px;
  color: rgb(0 34 61 / 0.87);
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  height: 56px;
  outline: none;
  border-top: 1px solid #c4cdd5;
  background-color: #fff;
}

.dropdown-item-main:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-top: none;
}

.dropdown-item-main:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dropdown-item-main:hover,
.dropdown-item-main:focus {
  background-color: #f7f9fa;
  color: #00223d;
  cursor: pointer;
  outline: none;
}

.dropdown-item-main:active {
  outline: none;
}

.dropdown-item-main.active {
  background-color: #f7f9fa;
}

.dropdown-item-main--disabled {
  color: #758896;
}

.dropdown-item-main--disabled:hover {
  color: #758896;
}

.dropdown-item-main.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 13px;
  width: 18px;
  height: 100%;
  background: url("check.svg") 0 no-repeat;
  background-size: 18px;
}

.dropdown-item-main-new::after {
  content: "";
  position: absolute;
  top: 0;
  right: 12px;
  width: 40px;
  height: 100%;
  background: url("newIcon.svg") 0 no-repeat;
}

.dropdown-item-main__title {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 21px;
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.dropdown-item-main__subtitle {
  font-weight: 400;
  font-size: 10px;
  color: rgb(0 34 61 / 0.54);
  letter-spacing: 0.75px;
  line-height: 15px;
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
