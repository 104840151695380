.stripe-billing__wrapper {
  margin: 0 auto;
  padding: 8vh 0;
  min-height: 590px;
  max-width: 690px;
}

@media (max-width: 991px) {
  .stripe-billing__wrapper {
    padding: 16px;
  }
}

.stripe-billing__section {
  margin: 1vh auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.stripe-billing__title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
  color: #00223d;
  letter-spacing: 0.25px;
}

.stripe-billing__subtitle {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #00223d;
  letter-spacing: 0.25px;
  margin: 24px 0;
}

.stripe-billing__button {
  width: 110px;
}

.stripe-billing__text {
  font-size: 14px;
  line-height: 21px;
  color: #00223d;
  max-width: 570px;
}

.stripe-billing__text--info {
  font-size: 12px;
  line-height: 18px;
  color: #758896;
  margin-bottom: 46px;
}

.stripe-billing__link {
  color: #758896;
  text-decoration: underline;
}

.stripe-billing__link:hover {
  color: #1696ff;
}
