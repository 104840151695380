.sub-duration-btns {
    border: 1px solid #D6DADF;
    border-radius: 8px;
    padding: 2px;
}

.sub-duration-btns__btn {
    border-radius: 8px;
}

.sub-duration-btns__btn.active {
    color: #FFFFFF;
}

.sub-duration-btns__btn--monthly.active {
    background: #A1ADB7;
}

.sub-duration-btns__btn--yearly.active {
    background: #1696FF;
}

@media (max-width: 576px) {

}