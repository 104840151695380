@media (max-width: 579px) {
  .tooltip.tooltip-top,
  .tooltip.bs-tether-element-attached-bottom {
    margin-left: 42px;
  }

  .tooltip .tooltip-inner.tips__description::before {
    display: none;
  }
}

.statistics__wrapper {
  padding: 24px;
  background-color: #00223d;
  border-radius: 2px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 0.1);
}

.statistics__section {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}

.statistics__section_primary {
  margin-bottom: 24px;
  padding-bottom: 42px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255 255 255 / 0.2);
}

@media (max-width: 579px) {
  .statistics__section_primary {
    padding-bottom: 0;
  }
}

@media (max-width: 870px) {
  .statistics__section_primary {
    flex-flow: row wrap;
  }
}

@media (max-width: 579px) {
  .statistics__section_secondary {
    padding-bottom: 0;
  }
}

@media (max-width: 870px) {
  .statistics__section_secondary {
    flex-flow: row wrap;
  }
}

.statistics__section_header {
  margin: -12px 0 24px;
  padding-bottom: 12px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255 255 255 / 0.2);
}

.statistics-section__item {
  width: 232px;
}

@media (max-width: 579px) {
  .statistics-section__item {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media (min-width: 580px) and (max-width: 719px) {
  .statistics-section__item {
    width: 50%;
  }
}

@media (min-width: 580px) and (max-width: 719px) {
  .statistics-section__item:nth-child(1),
  .statistics-section__item:nth-child(2) {
    margin-bottom: 32px;
  }
}

.statistics-section__item .transparent-toggler {
  width: 145px;
}

@media (max-width: 870px) {
  .statistics-section__item_roas {
    order: 2;
  }
}

@media (max-width: 870px) {
  .statistics-section__item_spend {
    order: 3;
  }
}

@media (max-width: 870px) {
  .statistics-section__item_reach {
    order: 4;
  }
}

@media (max-width: 579px) {
  .statistics-section__item_purchases {
    margin-bottom: 0;
  }
}

@media (max-width: 870px) {
  .statistics-section__item_purchases {
    order: 5;
  }
}

.statistics-section__item_selector .transparent-toggler {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 870px) {
  .statistics-section__item_selector {
    margin-bottom: 24px;
    width: 100%;
    order: 1;
  }

  .statistics__section_header .statistics-section__item_selector {
    margin-bottom: 0;
    width: auto;
  }
}

@media (min-width: 500px) and (max-width: 579px) {
  .statistics-section-secondary__item:last-child {
    margin-bottom: 0;
  }
}

.statistics-section-item__title {
  margin: 0;
  padding: 0 0 6px;
  color: rgb(255 255 255 / 0.6);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.statistics-section-item__title span {
  display: inline-block;
  vertical-align: text-top;
  margin-right: 4px;
}

.statistics-section-item__title .tips__wrapper {
  vertical-align: text-top;
}

.statistics-section-item__value {
  margin: 0;
  padding: 0;
  color: #fff;
  font-weight: 500;
}

.statistics-section-item__value sup {
  font-size: 10px;
  color: rgb(255 255 255 / 0.79);
  font-weight: 500;
  top: 0;
  vertical-align: super;
  margin-left: 3px;
}

.statistics-section-item__value.statistics-section-primary-item__value sup {
  font-size: 12px;
}

.statistics-section-primary-item__value {
  font-size: 28px;
}

.statistics-section-primary-item-tips__wrapper {
  min-width: 201px;
}

.statistics-section-primary-item-tips__wrapper .tips-description__link {
  display: inline-block;
}

.statistics-section-primary-item-tips__content {
  margin: 0;
  padding: 0 0 30px;
  list-style: none;
}

.statistics-section-primary-item-tips__content-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.statistics-section-primary-item-tips__content-item:not(:last-child) {
  padding-bottom: 14px;
}

.statistics-section-primary-item-tips__content-item_separated {
  margin-top: -5px;
  padding-top: 8px;
  border-top: 1px solid #f0f1f5;
}

.statistics-section-primary-item-tips-content-item__value {
  color: #00223d;
  font-weight: 500;
}

.statistics-section-secondary-item__value {
  font-size: 18px;
  font-weight: 500;
}

.statistics-section-item__details {
  margin: 0;
  position: relative;
  color: rgb(255 255 255 / 0.6);
  font-size: 13px;
  font-weight: 300;
}

.statistics-section-item__details::before {
  content: "";
  position: relative;
  display: inline-block;
  margin-right: 5px;
  width: 9px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.statistics-section-item__details_increase::before {
  border-bottom: 9px solid #5bca20;
}

.statistics-section-item__details_decrease::before {
  border-top: 9px solid #fe1616;
}

.statistics-section__dropdowns-wrapper {
  display: flex;
  align-items: center;
  width: 550px;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 685px) {
  .statistics-section__dropdowns-wrapper {
    width: 100%;
    flex-wrap: wrap;
  }
}

.statistics-section__dropdown-wrapper {
  display: flex;
  align-items: center;
  width: 209px;
}

@media (max-width: 685px) {
  .statistics-section__dropdown-wrapper {
    width: 100%;
  }
}

@media (max-width: 385px) {
  .statistics-section__dropdown-wrapper {
    flex-flow: column wrap;
    align-items: baseline;
  }

  .statistics-section__dropdown-wrapper > span {
    margin-bottom: 15px;
  }
}

.statistics-section__dropdown-wrapper > span {
  color: rgb(255 255 255 / 0.6);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin-right: 15px;
}

.statistics__section_list {
  width: 650px;
  justify-content: space-between;
}
