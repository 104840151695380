.styled-table {
  border-top: 0;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.styled-table th, .styled-table td {
  padding: 0.75rem
}

.styled-table thead {
  color: rgb(0 34 61 / 87);
  border-top: none;
  border-bottom: 1px solid rgb(222 226 230 / 1);
  font-weight: 500;
  font-size: 13px;
}

.styled-table .align-right {
  text-align: right;
}

.styled-table .align-center {
  text-align: center;
}

.styled-table.styled-table--billing-history thead th {
  font-weight: 500;
  font-size: 14px;
}

.styled-table_top-bottom-border {
  border-top: 1px solid rgb(222 226 230 / 1);
  border-bottom: 1px solid rgb(222 226 230 / 1);
}

.styled-table tr td,
.styled-table tr th {
  border: none;
}

.styled-table tbody tr:hover,
.styled-table tbody tr:focus {
  background-color: #f6f7fb;
  color: #1f2f4d;
}

.styled-table tr {
  border-top: none;
  border-bottom: 1px solid rgb(0 34 61 / 0.1);
}

.styled-table tr:only-child {
  border-bottom: none;
}

.styled-table td.loading {
  height: 55px;
}

.styled-table td p {
  margin: 0;
  padding: 0;
}

.shopify-billing-info__table .billing-period p,
.shopify-billing-info__table .billing-period {
  width: 40%;
}

.shopify-billing-info__table .billing-plan p,
.shopify-billing-info__table .billing-plan,
.shopify-billing-info__table .fee-paid p,
.shopify-billing-info__table .fee-paid {
  width: 28%;
}

.shopify-billing-info__table .fee-paid {
  text-align: right;
}

.styled-table_last-two-columns-align-to-right td:last-child,
.styled-table_last-two-columns-align-to-right td:last-child p,
.styled-table_last-two-columns-align-to-right th:last-child,
.styled-table_last-two-columns-align-to-right td:nth-last-child(2),
.styled-table_last-two-columns-align-to-right td:nth-last-child(2) p,
.styled-table_last-two-columns-align-to-right th:nth-last-child(2) {
  text-align: right;
}

/* Bigcommerce/WooCommerce billing info table */
.bigcommerce-or-woocommerce-billing-info__table .billing-period {
  width: 38%;
  text-align: left;
}

.bigcommerce-or-woocommerce-billing-info__table .billing-plan {
  text-align: center;
  width: 15%;
}

.bigcommerce-or-woocommerce-billing-info__table .fee-paid {
  width: 32%;
  text-align: right;
}

.bigcommerce-or-woocommerce-billing-info__table .download-receipt {
  width: 15%;
  text-align: right;
}

.bigcommerce-or-woocommerce-billing-info__table .download-receipt a {
  color: #00223d;
  text-decoration: underline;
}

/* Loader Start */
.loading {
  position: relative;
  color: transparent;
  cursor: default;
  pointer-events: none;
}

.loading:hover,
.loading:focus,
.loading:active {
  color: transparent;
}

.loading::before {
  content: "";
  position: absolute;
  left: calc(50% - 8px);
  bottom: calc(50% - 7px);
  width: 16px;
  height: 16px;
  background-color: #00223d;
  mask-image: url("spinner-icon.svg");
  mask-repeat: no-repeat;
  animation: load 0.8s linear infinite;
}

.loading_light::before {
  background-color: #fff;
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Loader End */
