.no-shops-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f6f7fb;
}

.no-shops-section {
  text-align: center;
}

.no-shops-section__container {
  margin: 104px auto auto;
}

.no-shops-section__title {
  margin-bottom: 32px;
  color: rgb(31 47 77 / 0.9);
  font-size: 40px;
  font-weight: normal;
}

.no-shops-section-error-message__block,
.no-shops-section__description {
  margin: 0 auto 32px;
  max-width: 340px;
  color: rgb(31 47 77 / 0.8);
  font-size: 15px;
  font-weight: 300;
}

.no-shops-section__buttton {
  display: inline-block;
  padding: 16px 24px;
  background: #1595fe linear-gradient(-180deg, #59b3ff 0, #1595fe 100%);
  border: 1px solid #1696ff;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.1), inset 0 1px 0 1px rgb(255 255 255 / 0.06);
  box-sizing: border-box;
  color: #fff;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
}

.no-shops-section__buttton:hover,
.no-shops-section__buttton:focus {
  background-image: linear-gradient(-180deg, #47acff 0%, #008cff 100%);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.no-shops-section__buttton:active {
  background-image: linear-gradient(0deg, #47acff 0%, #008cff 100%);
  color: #fff;
  text-decoration: none;
}

.no-shops-section-error-message__block {
  margin-top: 16px;
}

.no-shops-section-bigcommerce__button {
  display: block;
  margin: 16px auto;
}
