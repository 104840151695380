.connect-gl-ad-account__wrapper {
  margin: 0 auto;
  padding: 56px 90px;
  color: #00223d;
}

@media (max-width: 991px) {
  .connect-gl-ad-account__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .connect-gl-ad-account__wrapper {
    padding: 16px;
  }
}

.connect-gl-ad-account__title {
  margin-bottom: 40px;
  font-size: 31px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .connect-gl-ad-account__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.connect-gl-ad-account__body-wrapper {
  margin-bottom: 32px;
}

.connect-gl-ad-account__dropdown {
  max-width: 340px;
}

.connect-gl-ad-account__dropdown .dropdown-item-main {
  height: 56px;
}

.connect-gl-ad-account__dropdown-menu {
  margin: -1px 0 0;
  padding: 0;
  width: 100%;
  border-color: #c4cdd5;
  overflow: hidden;
  max-height: 224px;
  overflow-y: scroll;
}