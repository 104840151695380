.select-dropdown {
  font-size: 13px;
  color: rgb(0 34 61 / 87);
}

.select-dropdown .dropdown-menu {
  padding: 0;
  margin: -1px 0 0;
  overflow: hidden;
  border-color: #c4cdd5;
}

.select-dropdown .dropdown-item {
  padding: 6px 12px;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: rgb(0 34 61 / 87);
  cursor: pointer;
}

.select-dropdown .dropdown-item:hover {
  background-color: rgb(22 29 37 / 0.05);
}

.select-dropdown .dropdown-item:focus,
.select-dropdown .dropdown-item:active {
  background: none;
  background-color: rgb(22 29 37 / 0.07);
  outline: none;
  color: rgb(0 34 61 / 87);
}

.dropdown-menu_list--billing-history {
  min-width: 10rem;
  max-height: initial;
}
