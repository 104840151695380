.c-collapse {
    display: none;
}

.c-collapse.show {
    display: block;
}

.c-collapse.collapsing {
    overflow: hidden;
    transition: all .3s;
}