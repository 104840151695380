.preview-slide-wrapper img {
    display: flex;
    max-width: 100%;
}

.preview-slide-description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.preview-slide-icon {
    margin-right: 8px;
    max-width: 12px;
    display: flex;
}

.preview-slide-icon img {
    max-width: 100%;
}

.preview-slide-text {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: rgba(0, 34, 61, 0.87);
}

.preview-slider-container {
    padding: 0 42px;
    position: relative;
}

.preview-slider-back-btn, .preview-slider-next-btn {
    position: absolute;
    cursor: pointer;
    top: 148px
}

.preview-slider-back-btn {
    left: -8px;
}

.preview-slider-next-btn {
    right: -8px;
}
