.start-trial__caption {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.25px;
    color: #00223d;
    font-weight: 100;
}

.start-trial__button {
    font-weight: 600;
    letter-spacing: 1px;
}

.start-trial__subcaption {
    font-size: 18px;
    letter-spacing: 0.25px;
    color: #00223d;
    margin-top: 32px;
    margin-bottom: 24px;
}

.start-trial__description {
    color: rgb(0 34 61 / 0.77);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 21px;
}

.start-trial__wrapper {
    margin: 73px auto 120px;
    max-width: 620px;
}

.start-trial__icon-wrapper {
    margin-top: 24px;
    margin-bottom: 22px;
}

.start-trial__link {
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #a1adb7;
    margin-top: 16px;
    font-weight: 300;
}

.start-trial__link a {
    color: #a1adb7;
    text-decoration: underline;
}
