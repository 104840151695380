.gl-onboarding-settings__daily-budget__title {
    color: #00223D;
    font-size: 14px;
}

.gl-onboarding-settings__daily-budget__wrapper {
    background: #F7F9FA;
    display: flex;
    padding: 8px 24px;
    width: fit-content;
}

.gl-onboarding-settings__daily-budget__wrapper__title {
    margin-right: 79px;
    margin-top: 8px;
}