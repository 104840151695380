.tips__wrapper {
  display: inline-block;
  height: 100%;
}

.tips__wrapper_aligned {
  vertical-align: middle;
}

.tips__icon {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
}

.tips__icon:hover,
.tips__icon:focus,
.tips__icon:active {
  cursor: pointer;
}

.tips__icon.warning-icon:hover::before,
.tips__icon.warning-icon:focus::before,
.tips__icon.warning-icon:active::before {
  background-color: #FE9929;
}

.tips__icon.blue-info-icon:hover::before,
.tips__icon.blue-info-icon:focus::before,
.tips__icon.blue-info-icon:active::before {
  background-color: #1696ff;
}

.tips__icon.tips__icon_dark:hover::before,
.tips__icon.tips__icon_dark:focus::before,
.tips__icon.tips__icon_dark:active::before {
  background-color: #758897;
}

.tips__icon:hover::before,
.tips__icon:focus::before,
.tips__icon:active::before {
  background-color: #c2cbd1;
  cursor: pointer;
}

.tips-description__inner-title {
  margin: 0 0 7px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(0 34 61 / 1);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 1;
}

.tips-description__title {
  margin: 0 0 7px;
  font-weight: 500;
  font-size: 20px;
  color: rgb(0 34 61 / 1);
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 1;
}

.tips-description__title.light {
  color: #fff;
}

.tips-description__content {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.1px;
  line-height: 21px;
}

.tips-description__content:not(:last-child) {
  margin-bottom: 8px;
}

.tips-description__link,
.tips-description__link:hover {
  color: #1696FF;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
