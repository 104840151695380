.book-a-call {
  padding: 60px 120px;
}

.book-a-call__title {
  font-weight: 300;
  font-size: 32px;
  line-height: 150%;
  letter-spacing: 0.25px;
  color: #00223D;
  margin-bottom: 40px;
}

.book-a-call__title .phone-icon {
  font-size: 32px;
  line-height: 150%;
  display: block;
  margin-bottom: 24px;
}

.book-a-call__text {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.25px;
  margin-bottom: 32px;
}

.book-a-call__btn-transparent {
  color: #1696FF;
  border: none;
  padding: 0;
}

.book-a-call__btn-transparent:hover {
  background: none;
}

.book-a-call__btn-transparent svg {
  margin-right: 4px;
}

.book-a-call__btn-transparent span {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .book-a-call {
    padding: 16px;
  }
}