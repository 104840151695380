.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(255 255 255 / 0.85);
  z-index: 9999;
}

.loader-image {
  height: 32px;
}

.loader-image--spinner {
  animation: loader-spinner infinite 0.7s linear;
}

@keyframes loader-spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader-long-wait {
  text-align: center;
}

.loader-long-wait__loader-wrapper {
  margin-bottom: 18px;
}

.loader-long-wait__loader-title {
  margin: 5px;
  font-size: 15px;
  font-weight: normal;
  color: #1f2f4d;
}

.loader-long-wait__loader-text {
  margin-top: 5px;
  font-size: 13px;
  font-weight: lighter;
  color: #1f2f4d;
}
