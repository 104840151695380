.facebook-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.facebook-page-layout__container {
  display: flex;
  flex: 1 1 80%;
  text-align: center;
  color: #00223d;
}

.facebook-page-layout__content-wrapper {
  flex: 1 1 100%;
  margin: auto;
}

.facebook-page-layout__logo-container {
  margin: auto;
}

@media screen and (max-width: 575px) {
  .facebook-page-layout__container {
    flex: 1 1 95%;
  }

  .facebook-page-layout__logo-container {
    flex: 1 1 5%;
  }

  .facebook-page-layout__logo {
    width: 120px;
    height: 23px;
  }
}

/* New window for facebook login */

.fb-login-page .login-view__title {
  color: #fff;
  font-weight: 300;
}

.fb-login-page .login-view__white-substrate {
  box-shadow: 0 2px 24px rgb(0 0 0 / 0.1), 0 2px 12px rgb(0 34 61 / 0.37);
  border-radius: 8px;
  border: 1px solid #d6dadf;
  background-color: #fff;
  max-width: 504px;
  min-width: 440px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fb-login-page .login-view__description {
  color: rgb(0 34 61 / 0.87);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 21px;
  padding: 0 75px;
  margin-bottom: 32px;
}

.facebook-page-layout__wrapper .login-view__description {
  padding: 0 60px;
}
