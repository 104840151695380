.product-feed {
    padding: 56px 71px 100px;
}

.product-feed__title {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 300;
    margin-left: 24px;
    margin-bottom: 32px;
}

.product-feed__item + .product-feed__item {
    margin-top: 32px;
}

.product-feed__item.product-feed__item--end {
    display: flex;
    justify-content: flex-end;
}

.product-feed__create-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-feed-dropdown {
    margin-top: 16px;
}