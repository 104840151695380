.share-pixel__wrapper {
  margin: 0 auto;
  padding: 7vh 0;
  max-width: 690px;
}

@media (max-width: 991px) {
  .share-pixel__wrapper {
    padding: 16px;
  }
}

.share-pixel__section {
  margin: 1vh auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.share-pixel__title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
  color: #00223d;
  letter-spacing: 0.25px;
}

.share-pixel__button {
  width: 109px;
}

.share-pixel__list {
  padding: 15px;
  margin: 0;
  max-width: 420px;
}

.share-pixel__list-item {
  padding: 0;
}

.share-pixel__list-item:not(:last-of-type) {
  margin-bottom: 10px;
}

.share-pixel__text {
  font-size: 14px;
  line-height: 21px;
  color: #00223d;
  margin: 24px 0 10px;
}

.share-pixel__validation {
  margin: 20px 0 24px;
  max-width: 420px;
}

.share-pixel__clipboard-icon {
  display: inline-block;
  text-indent: -9999px;
  width: 16px;
  height: 16px;
  background: url("./clipboard-icon.svg");
  background-size: 15px 16.5px;
  background-repeat: no-repeat;
}

.share-pixel__copy-to-clipboard {
  color: #07ae3a;
  cursor: pointer;
}

.share-pixel__tips-wrapper .tooltip-inner {
  padding: 9px 20px;
  font-size: 14px;
  line-height: 21px;
}

.share-pixel__instruction-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.share-pixel-help {
  margin-top: 20px;
  width: 183px;
  height: 139px;
  border: 2px solid #1696ff;
  padding: 24px;
  border-radius: 8px;
  font-size: 13px;
  line-height: 18px;
}

.share-pixel-help__title {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 20px;
}

.share-pixel__yt-icon {
  display: inline-block;
  text-indent: -9999px;
  width: 20px;
  height: 15px;
  background: url("./yt-icon.svg");
  background-size: 20px 16px;
  background-repeat: no-repeat;
}

.share-pixel__text-link--red,
.share-pixel__text-link--red:hover {
  color: #f00;
}
