.gl-onboarding-settings__wrapper {
  margin: 0 auto;
  padding: 0 16px 80px;
  color: #1f2f4d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 770px;
}

.gl-onboarding-settings__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.gl-onboarding-settings__main-section {
  display: flex;
  justify-content: center;
}

.gl-onboarding-settings__notification .alert__wrapper_info {
  max-width: 755px;
  position: relative;
  left: -15px;
}

.gl-onboarding-settings__wrapper .alert__wrapper {
  margin-bottom: 24px;
}

.gl-onboarding-settings__wrapper_small {
  max-width: 510px;
}

.gl-onboarding-settings__wrapper_small-error {
  padding-top: 20px;
}

.gl-onboarding-settings__title {
  margin-bottom: 40px;
  margin-top: 35px;
  font-size: 32px;
  font-weight: 300;
  padding: 0 105px;
}

.gl-onboarding-settings__item {
  margin-bottom: 20px;
  width: 100%;
  min-height: 269px;
  position: relative;
}

.gl-onboarding-settings__divider {
  width: 1px;
  background-color: #c4cdd5;
  margin: 0 38px;
}

.gl-onboarding-settings__description {
  margin: 0 auto 12px;
  max-width: 470px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

.gl-onboarding-settings__description-title {
  font-weight: 400;
  font-size: 18px;
  color: rgb(31 47 77 / 0.9);
  margin-bottom: 16px;
}

.gl-onboarding-settings__description-title::before {
  content: "";
  display: block;
  background-size: cover;
}

.gl-onboarding-settings__list {
  padding: 0 20px;
  margin: 0;
}

.gl-onboarding-settings__list-item {
  margin-bottom: 10px;
}

.gl-onboarding-settings__list-item:last-of-type {
  margin-bottom: 25px;
}

.gl-onboarding-settings__text-link,
.gl-onboarding-settings__text-link:hover,
.gl-onboarding-settings__text-link:active {
  color: #1596ff;
  font-weight: 500;
  text-decoration: none;
}

.gl-onboarding-settings__checkbox-label {
  padding-left: 4px;
}

.gl-onboarding-settings__notification .alert__wrapper_danger {
  max-width: 700px;
}

.gl-onboarding-settings-cta-button {
  margin-top: 40px;
  font-weight: 400;
  font-size: 13px;
  max-width: fit-content;
  padding: 7px 22px;

  /* reset pending button styles */
  transform: none !important;
}

.gl-onboarding-settings-cta-button.button_pending,
.gl-onboarding-settings-cta-button.button_pending:focus,
.gl-onboarding-settings-cta-button.button_pending:disabled {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.gl-onboarding-settings-cta-button_accent {
  border: 2px solid #1696ff;
}

@media (max-width: 991px) {
  .gl-onboarding-settings__notification .alert__wrapper_info {
    max-width: 460px;
    left: 0;
  }
}

@media (max-width: 768px) {
  .gl-onboarding-settings__divider {
    margin: 0 40px;
  }

  .gl-onboarding-settings__item {
    margin-bottom: 40px;
    min-height: 300px;
  }
}

@media (max-width: 576px) {
  .gl-onboarding-settings__title {
    font-size: 19px;
    padding: 0;
    max-width: 290px;
    text-align: center;
    margin: 24px auto 0;
  }

  .gl-onboarding-settings__wrapper {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .gl-onboarding-settings__main-section {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .gl-onboarding-settings__change-page {
    margin: 16px;
    max-width: 290px;
  }

  .gl-onboarding-settings__description {
    margin-bottom: 24px;
    font-size: 13px;
  }

  .gl-onboarding-settings__divider {
    display: none;
  }

  .gl-onboarding-settings-cta-button {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
  }

  .gl-onboarding-settings__item {
    min-height: 0;
  }

  .gl-onboarding-settings__description-title::before {
    margin-bottom: 20px;
  }
}
  