:root {
  --ra-logo-width: 60px;
  --trial-badge-size: 374px;
  --shops-user-widgets-size: 430px;
  --menu-button-size: 134px;
}

.header-wrapper {
  background: #fff;
  padding: 0 32px;
  box-shadow: 0 2px 24px rgb(0 0 0 / 0.1);
}

.header-navbar,
.header-navbar.navbar {
  padding: 0;
  max-width: 1024px;
  align-items: center;
  flex-wrap: wrap;
}

.header-navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.header-navbar_small,
.header-navbar_small.header-navbar {
  max-width: 912px;
}

.header-navbar-toggler,
.header-navbar-toggler.navbar-toggler {
  padding: 8px 14px;
  margin: 0;
  height: 32px;
  border: 1px solid #2f3a4d;
  border-radius: 2px;
  font-size: 11px;
  color: #2f3a4d;
  text-transform: uppercase;
  text-align: center;
  line-height: 1em;
  vertical-align: middle;
  box-sizing: border-box;
  background: transparent;
}

.header-navbar-toggler:focus,
.header-navbar button.header-navbar-toggler:focus {
  outline: none;
}

.header-navbar-brand,
.header-navbar-brand.navbar-brand {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.header-navbar-collapse,
.header-navbar-collapse.navbar-collapse {
  flex-grow: 0;
}

.header-navbar .dropdown-widget__menu {
  max-height: 296px;
  overflow-y: scroll;
}

.header__app-logo {
  padding: 9px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header__app-logo__icon {
  height: 32px;
}

.header__app-logo__text {
  margin-left: 8px;
  display: inline-block;
  width: 83px;
  height: 16px;
  background-image: url("logo-txt.svg");
  background-size: cover;
}

.header__page-title {
  display: inline-block;
  margin-left: 9px;
  padding: 9px 0;
  height: initial;
}

.header__page-title__content {
  margin: 0 9px 0 0;
  padding: 0 0 0 8px;
  display: inline-block;
  max-width: calc(100vw - var(--ra-logo-width) - var(--shops-user-widgets-size));
  height: initial;
  border-left: 1px solid #d6dadf;
  color: #1f2f4d;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.header__page-title__content_small {
  max-width: calc(100vw - var(--ra-logo-width) - var(--trial-badge-size) - var(--shops-user-widgets-size));
}

.header-progress-bar {
  margin-left: 16px;
  padding-left: 16px;
  position: relative;
  display: flex;
  align-items: center;
}

.header-progress-bar::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 28px;
  background: #D6DADF;
}

.header-progress-bar__btn {
  margin-left: 16px;
}

@media (min-width: 768px) {
  .header-navbar-toggler {
    display: none;
  }

  .header-navbar-collapse,
  .header-navbar-collapse.navbar-collapse {
    display: flex;
    flex-basis: auto;
  }

  .header-navbar-nav {
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  .header-navbar,
  .header-navbar.navbar {
    padding: 0 15px;
  }
}

@media (max-width: 991px) {
  .header-wrapper .trial-badge,
  .header-wrapper .trial-badge.badge {
    display: none;
  }

  .header__page-title__content_small {
    max-width: calc(100vw - var(--ra-logo-width) - 150px - var(--shops-user-widgets-size));
  }
}

@media (max-width: 767px) {
  .header-wrapper {
    padding: 0;
  }

  .header-navbar-brand,
  .header-navbar-brand.navbar-brand {
    max-width: 75%;
  }

  .header-navbar-nav,
  .header-navbar-nav.navbar-nav {
    margin: 16px 0 0;
    flex-direction: row;
  }

  .header__app-logo__text {
    display: none;
  }

  .header__page-title__content {
    padding: 4px 0 4px 8px;
    max-width: calc(100vw - var(--ra-logo-width) - var(--menu-button-size));
    font-size: 17px;
    line-height: 1.25em;
    white-space: normal;
    vertical-align: middle;
  }
}

@media (max-width: 575px) {
  .header-navbar.header-navbar--with-progress-bar {
    padding-top: 16px;
  }
  .header__page-title__content {
    max-width: calc(100vw - var(--ra-logo-width) - var(--menu-button-size));
  }

  .header-progress-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-progress-bar__btn {
    margin-left: 0;
    padding: 5px 16px;
  }
}

@media (max-width: 480px) {
  .header-wrapper {
    padding: 0;
  }
}
