.custom-toggle__option {
    color: #C4CDD5;
    transition: all .3s;
}

.custom-toggle__input {
    visibility: hidden;
    font-size: 0;
    position: absolute;
    z-index: -9999;
}

.custom-toggle__input + .custom-toggle__label .custom-toggle__option--1 {
    color: #0087F5;
}

.custom-toggle__input + .custom-toggle__label .custom-toggle__option--2 {
    color: #C4CDD5;
}

.custom-toggle__input:checked + .custom-toggle__label .custom-toggle__option--2 {
    color: #0087F5;
}

.custom-toggle__input:checked + .custom-toggle__label .custom-toggle__option--1 {
    color: #C4CDD5;
}

.custom-toggle__label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.custom-toggle__box {
    text-indent: -9999px;
    width: 36px;
    height: 20px;
    background: #A1ADB7;
    display: block;
    border-radius: 56px;
    position: relative;
    margin: 0 11px;
}

.custom-toggle__box::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.custom-toggle__input:checked + .custom-toggle__label .custom-toggle__box::after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}