.gl-onboarding-settings__sms-counter__resend-link {
    color: rgba(22, 150, 255, 1);
    cursor: pointer;
    margin-bottom: 32px;
    text-align: center;
}

.gl-onboarding-settings__sms-counter__countdown__counter {
    color: rgba(22, 150, 255, 1);
}

.gl-onboarding-settings__sms-counter__countdown__counter__wrapper {
    width: 385px;
    margin-bottom: 32px !important;
}
