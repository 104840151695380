.action-success__wrapper {
  width: 100%;
  background: #07ae3a;
  background-image: linear-gradient(-125deg, rgb(0 0 0 / 0) 20%, rgb(0 20 0 / 0.21) 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-success__logo {
  display: inline-block;
  width: 207px;
  height: 40px;
  background: url("../../../img/retargetApp-logo-white.svg") no-repeat;
  background-size: 100% 100%;
}

.action-success__block {
  color: #fff;
}

.action-success__main-message {
  font-size: 32px;
  font-weight: 500;
  margin: 32px 0 20px;
}

.action-success__second-message {
  line-height: 26px;
}

.action-success__button {
  margin-top: 16px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  border: 2px solid #fff;
  padding: 7px 16px;
}

.action-success__button:hover {
  color: #fff;
  border: 2px solid #fff;
  background: transparent;
}

.action-success__button:focus {
  color: #fff;
  border: 2px solid #fff;
  background: transparent;

  /* necessary style, forced measure */
  box-shadow: 0 0 0 3px rgb(242 244 245 / 0.4) !important;
}
