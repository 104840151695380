.checkbox {
  max-height: 14px;
  max-width: 14px;
  position: relative;
  margin: 0;
  outline: none;
  margin-right: 10px;
}

.checkbox::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: 0.5px solid #c4cdd5;
  box-shadow: inset 0 1px 2px rgb(0 34 61 / 0.25);
  background: #fff;
}

.checkbox:checked::after {
  border: 1px solid #1696ff;
  box-shadow: 0 1px 0 0 #e7eef3;
  background: url("./checkmark.svg") 0.5px 1px / 10px 11px no-repeat #1696ff;
}

.checkbox:focus-visible::after {
  border: none;
  box-shadow:
    inset 0 1px 2px rgb(0 34 61 / 0.25),
    0 0 0 2px #1696ff;
  border-radius: 1px;
}

.checkbox:disabled::after {
  background: linear-gradient(180deg, #f7f9fa 0%, #f1f4f6 100%);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 rgb(22 29 37 / 0.05);
}

.checkbox:hover:enabled::after {
  background: linear-gradient(180deg, #fff, 0%, #f9fafb 100%);
}

.checkbox:checked:focus-visible::after {
  border: none;
  box-shadow:
    inset 0 1px 2px rgb(0 34 61 / 0.25),
    0 0 0 2px #1696ff,
    0 1px 0 2px #e7eef3;
}

.checkbox:checked:disabled::after {
  background: url("./disabled-checkmark.svg") 2px 1px / 10px 11px no-repeat, linear-gradient(180deg, #f7f9fa 0%, #f1f4f6 100%);
}

.checkbox:checked:enabled:hover::after {
  background: url("./checkmark.svg") 0.5px 1px / 10px 11px no-repeat, linear-gradient(180deg, #1696ff 0%, #1593fb 100%);
}
