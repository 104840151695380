.notification__wrapper {
  position: relative;
  display: block;
  margin: 0 auto 16px;
  padding: 20px 32px 24px 82px;
  width: 100%;
  min-height: 82px;
  border-radius: 2px;
  border: 1px solid;
  color: rgb(31 47 77 / 0.8);
  font-size: 13px;
}

.notification__wrapper::before {
  content: "";
  position: absolute;
  display: block;
  left: 16px;
  top: 16px;
  bottom: 0;
  width: 50px;
  height: 50px;
  float: left;
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 100%;
  border: 2px solid;
}

.notification__wrapper::after {
  content: "";
  position: absolute;
  display: block;
  top: -1px;
  left: -1px;
  width: calc(1px + 100% + 1px);
  height: 3px;
  border-radius: 2px 2px 0 0;
}

.notification__wrapper.notification__wrapper_info {
  border-color: rgb(22 150 255 / 0.1);
  background-color: rgb(22 150 255 / 0.1);
}

.notification__wrapper.notification__wrapper_info::before {
  background-image: url("info-icon.svg");
  background-size: 32px;
  border-color: #1696ff;
}

.notification__wrapper.notification__wrapper_info::after {
  background-color: #1696ff;
}

.notification__wrapper.notification__wrapper_success {
  border-color: rgb(91 201 31 / 0.1);
  background-color: rgb(91 201 31 / 0.1);
}

.notification__wrapper.notification__wrapper_success::before {
  background-image: url("success-icon.svg");
  background-size: 32px;
  border-color: #5BC920;
}

.notification__wrapper.notification__wrapper_success::after {
  background-color: #5bca20;
}

.notification__wrapper.notification__wrapper_warning {
  border-color: rgb(254 153 41 / 0.1);
  background-color: rgb(254 153 41 / 0.1);
}

.notification__wrapper.notification__wrapper_warning::before {
  background-image: url("warning-icon.svg");
  background-size: 32px;
  border-color: #fe9929;
}

.notification__wrapper.notification__wrapper_warning::after {
  background-color: #fe9929;
}

.notification__wrapper.notification__wrapper_danger {
  border-color: rgb(237 99 71 / 0.1);
  background-color: rgb(237 99 71 / 0.1);
}

.notification__wrapper.notification__wrapper_danger::before {
  background-image: url("alert-icon.svg");
  background-size: 32px;
  border-color: #ed6347;
}

.notification__wrapper.notification__wrapper_danger::after {
  background-color: #ed6347;
}

.notification__title {
  margin: 0 0 8px;
  padding: 0;
  color: #00223d;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.notification__description {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.notification__description-bottom-margin-8 {
  margin-bottom: 8px;
}

.notification__description-bottom-margin-12 {
  margin-bottom: 12px;
}

.notification__description:last-of-type {
  margin-bottom: 20px;
}

.notification__description--underline-text {
  position: relative;
}

.notification__description--underline-text::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #00223D;
}

.notification__description b {
  white-space: nowrap;
}

.notification__caption {
  font-weight: bold;
}

.notification__link,
a.notification__link:not([href]):not([tabindex]) {
  padding: 0;
  background: none;
  border: none;
  color: rgb(31 47 77 / 0.8);
  font-size: 13px;
  font-weight: 300;
  text-decoration: underline;
  box-shadow: none;
}

.notification__link:hover,
.notification__link:focus,
a.notification__link:not([href]):not([tabindex]):hover,
a.notification__link:not([href]):not([tabindex]):focus {
  color: rgb(31 47 77 / 0.6);
  cursor: pointer;
  outline: none;
}

.notification__link:active,
a.notification__link:not([href]):not([tabindex]):active {
  color: rgb(31 47 77 / 0.7);
}

.notification__link-black {
  color: #000;
}

.notification__link-black:hover,
.notification__link-black:focus,
.notification__link-black:active {
  color: #000;
}

.notification__link_with-arrow {
  position: relative;
  display: inline-block;
  margin-right: 16px;
}

.notification__link_with-arrow::after {
  content: "→";
  position: absolute;
  display: block;
  top: calc(50% - 0.65em);
  right: -18px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}

.notification__link_with-left-margin-5 {
  margin-left: 5px;
}

.notification__bold_span {
  font-weight: bold;
}

.notification__link_with-left-margin {
  margin-left: 16px;
}

.notification__link_with-left-margin-24 {
  margin-left: 24px;
}

.notification__link-raise-underline-text {
  position: relative;
  top: -1px;
}

.notification__button {
  margin-top: 16px;
  padding: 8px 12px;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #00223d;
  border-radius: 2px;
  color: rgb(31 47 77 / 0.8);
  font-size: 11px;
  font-weight: 500;
  line-height: 1em;
  text-decoration: none;
}

.notification__button:hover,
.notification__button:focus {
  border-color: rgb(31 47 77 / 0.75);
  background-color: transparent;
  color: rgb(31 47 77 / 0.75);
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.notification__button:active {
  border-color: rgb(31 47 77 / 0.9);
  background-color: transparent;
  color: rgb(31 47 77 / 0.9);
}

.notification__button_bigger {
  font-size: 14px;
  font-weight: normal;
  padding: 8px 44px;
  margin: 0;
}

.banner__emoji {
  font-size: 15px;
}

.banner-link {
  font-size: 14px;
  text-decoration: underline;
  color: rgb(0 34 61 / 0.87);
  font-weight: 400;
}
