.an-mobile {
    background: url('img/audience-network-bg.jpg') no-repeat center;
    background-size: 100% 100%;
    width: 182px;
    height: 332px;
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    font-family: 'Roboto', sans-serif;
    padding: 80px 9px 68px;
}

.an-mobile::after {
    content: '';
    background: rgba(156, 156, 156, 0.5);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}

.an-mobile__adv {
    background: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    z-index: 3;
}

.an-mobile__adv-img-wrapper {
    width: 100%;
    height: 85px;
}

.an-mobile__adv-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.an-mobile__adv-top {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.an-mobile__avatar {
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 7px;
}

.an-mobile__title {
    color: #262626;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 2px;
}

.an-mobile__subtitle {
    color: #65676B;
    font-size: 6px;
    line-height: 8px;
}

.an-mobile__description {
    color: #050505;
    font-size: 6px;
    line-height: 130%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.an-mobile__adv-main {
    padding: 6px 6px 10px;
}

.an-mobile__btn {
    background: #07AE3A;
    border-radius: 12px;
    font-weight: 600;
    font-size: 8px;
    line-height: 130%;
    width: 68px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #FFFFFF;
    margin-top: 10px;
}