.connect-page__wrapper {
  margin: 0 auto;
  padding: 56px 128px;
  color: #00223d;
}

@media (max-width: 991px) {
  .connect-page__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .connect-page__wrapper {
    padding: 16px;
  }
}

.connect-page__title {
  margin-bottom: 40px;
  font-size: 31px;
  font-weight: 300;
  max-width: 440px;
}

@media (max-width: 576px) {
  .connect-page__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.connect-page__description {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 300;
  max-width: 440px;
}

@media (max-width: 576px) {
  .connect-page__description {
    font-size: 13px;
  }
}

.connect-page__body-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
}