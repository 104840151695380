.fb-page__wrapper {
  margin: 0 auto;
  padding: 49px 88px 211px 130px;
  color: #00223d;
}

@media (max-width: 991px) {
  .fb-page__wrapper {
    padding: 35px 68px;
  }
}

@media (max-width: 576px) {
  .fb-page__wrapper {
    padding: 16px;
  }
}

.fb-page__title {
  margin-bottom: 40px;
  font-size: 31px;
  font-weight: 300;
}

@media (max-width: 576px) {
  .fb-page__title {
    margin: 8px 0 24px;
    font-size: 19px;
  }
}

.fb-page__description {
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 300;
  max-width: 460px;
}

@media (max-width: 576px) {
  .fb-page__description {
    margin-bottom: 24px;
    font-size: 13px;
  }
}

.fb-page__notifications-wrapper {
  position: relative;
  left: -60px;
  width: 118%;
}

@media (max-width: 991px) {
  .fb-page__notifications-wrapper {
    position: static;
    width: 100%;
  }
}

.fb-page__notifications-wrapper .alert__wrapper_warning {
  max-width: 100%;
}

.fb-page__cta-button {
  position: relative;
  display: block;
  margin: 0 auto 16px;
  padding: 18px 80px;
  width: 100%;
  background-image: linear-gradient(to bottom, #fff 35%, #f0f1f5 100%);
  border: 1px solid rgb(31 47 77 / 0.1);
  border-radius: 4px;
  color: #00223d;
  font-size: 14px;
  line-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media (max-width: 576px) {
  .fb-page__cta-button {
    margin-bottom: 16px;
    padding: 16px;
    width: 100%;
    text-align: left;
  }
}

.fb-page__cta-button:hover,
.fb-page__cta-button:focus {
  background-image: linear-gradient(#fff, #f0f1f5 65%);
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

.fb-page__cta-button:active {
  background-image: linear-gradient(to top, #fff, #f0f1f5 65%);
  outline: none;
}

.fb-page__cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 22px;
  padding: 0 20px;
  width: 20px;
  height: 100%;
  background: url("./assests/flag-icon.svg") 0 no-repeat;
  background-size: 20px;
  font-size: 30px;
  font-weight: bold;
  border-right: 1px solid rgb(96 103 117 / 0.2);
}

@media (max-width: 576px) {
  .fb-page__cta-button::before {
    display: none;
  }
}

.fb-page__cta-button::after {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  right: 24px;
  width: 0;
  height: 0;
  background-color: transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #00223d;
}

@media (max-width: 576px) {
  .fb-page__cta-button::after {
    right: 16px;
  }
}

.fb-page__cta-button[disabled] {
  opacity: 0.5;
  user-select: none;
  cursor: default;
}

.fb-page__cta-button[disabled]:hover,
.fb-page__cta-button[disabled]:focus {
  background: linear-gradient(to bottom, #fff, rgb(96 103 117 / 0.1));
}

.fb-page__cta-button[disabled]::after {
  opacity: 0.5;
}

.fb-page__cta-button_with-selected-item {
  padding: 12px 100px 12px 87px; /* 87 = 22+21+20+24 */
  background-color: rgb(250 250 250 / 0.4);
  border: 1px solid rgb(31 47 77 / 0.16);
  border-radius: 2px;
  text-align: left;
  max-width: 340px;
  margin: 8px 0;
}

@media (max-width: 576px) {
  .fb-page__cta-button_with-selected-item {
    padding: 14px;
  }
}

.fb-page__cta-button_with-selected-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 22px;
  width: 41px; /* 41 = 21+20 */
  height: 100%;
  background: url("./assests/flag-icon.svg") 0 no-repeat;
  background-size: 21px;
  border-right: 1px solid rgb(31 47 77 / 0.16);
}

@media (max-width: 576px) {
  .fb-page__cta-button_with-selected-item::before {
    display: none;
  }
}

.fb-page-cta-button-with-selected-item__title {
  padding: 0;
  margin: 0 0 8px;
  display: block;
  color: rgb(31 47 77 / 1);
  font-size: 15px;
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fb-page-cta-button-with-selected-item__subtitle {
  padding: 0;
  margin: 0;
  display: block;
  color: rgb(31 47 77 / 0.8);
  font-size: 11px;
  line-height: 1em;
  font-weight: 300;
}

.fb-page__tips-wrapper {
  max-width: 320px;
}

.fb-page-modal__tips-icon {
  vertical-align: text-top;
}

.fb-page__modal_fb-pages-list.modal-widget__dialog,
.fb-page__modal_fb-pages-list.modal-widget__dialog.modal-dialog,
.modal.show .fb-page__modal_fb-pages-list.modal-widget__dialog.modal-dialog {
  max-width: 440px;
}

.fb-page__modal_fb-pages-list .modal-widget__body,
.fb-page__modal_fb-pages-list .modal-widget__body.modal-body {
  padding: 0;
}

.fb-page-one-page__wrapper {
  max-width: 340px;
}

.fb-page-one-page__notice {
  margin: 0 0 12px;
  display: flex;
  justify-content: space-between;
}

.fb-page-one-page-notice__title {
  text-align: left;
  color: rgb(31 47 77 / 0.8);
  font-size: 12px;
  font-weight: 300;
}

.fb-page-one-page-notice__title span {
  display: inline-block;
  vertical-align: middle;
  color: rgb(0 34 61 / 0.87);
}

.fb-page-one-page__info-block {
  position: relative;
  margin: 0 0 16px;
  padding: 12px 24px 12px 87px; /* 87 = 22+21+20+24 */
  background-color: rgb(250 250 250 / 0.4);
  border: 1px solid rgb(31 47 77 / 0.16);
  border-radius: 2px;
  text-align: left;
}

@media (max-width: 576px) {
  .fb-page-one-page__info-block {
    padding: 14px;
  }
}

.fb-page-one-page__info-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 22px;
  width: 41px; /* 41 = 21+20 */
  height: 100%;
  background: url("./assests/flag-icon.svg") 0 no-repeat;
  background-size: 21px;
  border-right: 1px solid rgb(31 47 77 / 0.16);
}

@media (max-width: 576px) {
  .fb-page-one-page__info-block::before {
    display: none;
  }
}

.fb-page-one-page-info-block__title {
  padding: 0;
  margin: 0 0 8px;
  display: block;
  color: rgb(31 47 77 / 1);
  font-size: 15px;
  line-height: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fb-page-one-page-info-block__subtitle {
  padding: 0;
  margin: 0;
  display: block;
  color: rgb(31 47 77 / 0.8);
  font-size: 11px;
  line-height: 1em;
  font-weight: 300;
}

.fb-page__cta-button-secondary {
  margin: 0 auto 16px;
  width: fit-content;
  padding: 13px 24px;
}

.fb-page__connect-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fb-page-help {
  width: 186px;
  height: 171px;
  border: 2px solid #1696ff;
  padding: 24px;
  border-radius: 8px;
}

.fb-page-help__title {
  font-size: 16px;
  line-height: 27px;
}

.fb-page-help__caption {
  font-size: 14px;
  line-height: 21px;
  font-weight: 300;
}

.fb-page-help__link {
  font-size: 13px;
  line-height: 18px;
}
