.book-call {
    width: 186px;
    height: 171px;
    border: 2px solid #1696ff;
    padding: 24px;
    border-radius: 8px;
}

.book-call__title {
    font-size: 16px;
    line-height: 27px;
}

.book-call__caption {
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
}
