.yt-dp {
    width: 265px;
    height: 150px;
    background: url("img/background.svg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.yt-dp__adv {
    width: 221px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
    border-radius: 3px;
    overflow: hidden;
    padding: 3px;
    z-index: 2;
}

.yt-dp__adv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.yt-dp__avatar-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.yt-dp__avatar {
    width: 10px;
    height: 10px;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    backdrop-filter: blur(2px);
    border-radius: 50%;
    margin-right: 2px;
}

.yt-dp__title {
    font-size: 5px;
    line-height: 1;
    color: #FFFFFF;
}

.yt-dp__subtitle {
    white-space: nowrap;
    max-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #FFFFFF;
    font-size: 4px;
    line-height: 1;
    margin-top: 1px;
}

.yt-dp__duration {
    font-size: 3px;
    margin-top: 1px;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
}