.dashboard-campaign-promo {
  background: rgb(0 34 61 / 0.92);
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 60px;
  padding-left: 51px;
  padding-right: 12px;
  z-index: 9;
  position: relative;
}

.dashboard-campaign-promo--xmas::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 39px;
  height: 60px;
  background: url("./xmas-small.png");
  background-size: cover;
}

@media (min-width: 1024px) {
  .dashboard-campaign-promo--xmas::before {
    width: 72px;
    height: 66px;
    background: url("./xmas.png");
    background-size: cover;
  }
}

.dashboard-campaign-promo__content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0;
}

@media (min-width: 720px) {
  .dashboard-campaign-promo__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
  }
}

.dashboard-campaign-promo__badge {
  background: #ffe62b;
  border: 2px solid #fff;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2px 12px;
  font-size: 9px;
  color: #00223d;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 12px;
  display: inline-block;
  border-radius: 10px;
  position: absolute;
  left: 22px;
  top: -9px;
}

@media (min-width: 1024px) {
  .dashboard-campaign-promo {
    position: absolute;
    padding-left: 60px;
    padding-right: 24px;
    height: 74px;
    right: 0;
    top: 0;
    border-top-left-radius: 24px;
  }

  .dashboard-campaign-promo__badge {
    left: 30px;
  }
}

.dashboard-campaign-promo__text {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 36px;
  margin: 0 40px 0 0;
  display: inline-block;
}

.dashboard-campaign-promo__button {
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.05);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 21px;
  position: relative;
  border-radius: 2px;
  outline: none;
  border: none;
}

.dashboard-campaign-promo__button--xmas {
  background: #07ae3a;
  color: #fff;
  padding: 8px 16px 8px 34px;
  box-shadow: 0 2px 0 0 #3a703a;
}

.dashboard-campaign-promo__button--bfcm {
  background: #ffe62b;
  color: #00233e;
  padding: 4px 12px 4px 27px;
}

.dashboard-campaign-promo__button::before {
  content: "";
  width: 7px;
  height: 11px;
  mask-image: url("thunder.svg");
  mask-repeat: no-repeat;
  position: absolute;
  top: 14px;
}

.dashboard-campaign-promo__button--xmas::before {
  background-color: #fff;
  left: 16px;
}

.dashboard-campaign-promo__button--bfcm::before {
  background-color: #00223d;
  left: 11px;
}

@media (max-width: 719px) {
  .dashboard-campaign-promo__button--bfcm::before {
    top: 9px;
  }
}

.dashboard-campaign-promo__button:hover,
.dashboard-campaign-promo__button:focus {
  cursor: pointer;
  outline: none;
  border: none;
}

.dashboard-campaign-promo__button--xmas:hover,
.dashboard-campaign-promo__button--xmas:focus {
  background: #07a336;
  box-shadow: 0 2px 0 0 #007000;
}

.dashboard-campaign-promo__button--bfcm:hover,
.dashboard-campaign-promo__button--bfcm:focus {
  background: #ffda33;
}

.dashboard-campaign-promo__button:active {
  outline: none;
  border: none;
}

.dashboard-campaign-promo__button--xmas:active {
  background: #07a336;
  box-shadow: 0 1px 0 0 #007000, inset 0 3px 3px 0 rgb(3 82 27 / 0.7);
}

.dashboard-campaign-promo__button--bfcm:active {
  background: #ffda33;
  box-shadow:
    0 1px 1px 0 rgb(31 47 77 / 0.05),
    inset 0 1px 1px 0 rgb(31 47 77 / 0.6),
    inset 0 1px 4px 0 rgb(31 47 77 / 0.2);
}

.dashboard-campaign-promo__button_loading--xmas,
.dashboard-campaign-promo__button_loading--xmas:hover,
.dashboard-campaign-promo__button_loading--xmas:focus,
.dashboard-campaign-promo__button_loading--xmas:active {
  color: transparent;
  background: #55a36c;
  box-shadow: 0 2px 0 0 #3a703a;
}

.dashboard-campaign-promo__button_loading--bfcm,
.dashboard-campaign-promo__button_loading--bfcm:hover,
.dashboard-campaign-promo__button_loading--bfcm:focus,
.dashboard-campaign-promo__button_loading--bfcm:active {
  color: transparent;
  background: #e9c936;
  box-shadow: none;
}

.dashboard-campaign-promo__button_loading--xmas::before,
.dashboard-campaign-promo__button_loading--bfcm::before {
  content: "";
  position: absolute;
  left: calc(50% - 8px);
  top: 10px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  mask-image: url("spinner.svg");
  mask-repeat: no-repeat;
  animation: load 0.8s linear infinite;
}

@media (max-width: 719px) {
  .dashboard-campaign-promo__button_loading--bfcm::before {
    top: 6px;
  }
}
