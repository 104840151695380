.c-modal-open {
    overflow: hidden;
    padding-right: 0 !important;
}

.c-modal-backdrop {
    background: #00223d !important;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 2s;
}

.c-modal-backdrop.show {
    opacity: 0.5;
}

.c-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;
}

.c-modal__inner {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    margin: 1.75rem auto;
    min-height: calc(100% - (2 * 1.75rem));
}

.c-modal-enter .c-modal-backdrop {
    opacity: 0;
}

.c-modal-enter-active .c-modal-backdrop {
    opacity: 0.5;
    transition: opacity 0.3s;
}
.c-modal-enter-exit .c-modal-backdrop {
    opacity: 0.5;
}

.c-modal-exit-active .c-modal-backdrop {
    opacity: 0;
    transition: opacity 0.3s;
}

.c-modal-enter .c-modal__content {
    opacity: 0;
    transform: translateY(-100px);
}

.c-modal-enter-active .c-modal__content {
    opacity: 1;
    transition: all 0.3s;
    transform: translateY(0);
}
.c-modal-enter-exit .c-modal__content {
    opacity: 1;
    transform: translateY(0);
}

.c-modal-exit-active .c-modal__content {
    opacity: 0;
    transition: all 0.3s;
}


.c-modal__inner.centered {
    align-items: center;
}

.c-modal__content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
}

.c-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.c-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    font-size: 14px;
}

.c-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.c-modal-footer>:not(:first-child) {
    margin-left: 0.25rem;
}

.c-modal-footer>:not(:last-child) {
    margin-right: 0.25rem;
}