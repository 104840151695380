.dashboard-campaign {
  position: relative;
}

.dashboard-campaign__header {
  margin: 0;
  padding: 12px 24px;
  border-bottom: 1px solid #e1e3e6;
}

.dashboard-campaign__header-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.dashboard-campaign__title_small {
  font-size: 13px;
}

.dashboard-campaign__title-status {
  margin: 0;
  padding: 0;
  position: relative;
  color: #00223d;
  font-size: 12px;
}

.dashboard-campaign__title-status::before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  vertical-align: baseline;
}

.dashboard-campaign__title-status_creating::before {
  background-image: url("./half-filled-circle-icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.dashboard-campaign__title-status_creating::after {
  content: "Creating";
}

.dashboard-campaign__title-status_active::before {
  background-color: #5bca20;
}

.dashboard-campaign__title-status_active::after {
  content: "Active";
}

.dashboard-campaign__title-status_paused::before,
.dashboard-campaign__title-status_in_learning_phase::before,
.dashboard-campaign__title-status_pending::before,
.dashboard-campaign__title-status_removed::before {
  background-color: #d8d8d8;
}

.dashboard-campaign__title-status_paused::after {
  content: "Paused";
}

.dashboard-campaign__title-status_pending::after {
  content: "In Review";
}

.dashboard-campaign__title-status_declined::before,
.dashboard-campaign__title-status_rejected::before {
  background-color: #fe1616;
}

.dashboard-campaign__title-status_declined::after {
  content: "Declined";
}

.dashboard-campaign__title-status_rejected::after {
  content: "Rejected";
}

.dashboard-campaign__title-status_removed::after {
  content: "Removed";
}

.dashboard-campaign__title-status_in_learning_phase::after {
  content: "In learning phase";
}

.dashboard-campaign__type {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgb(0 34 61 / 0.38);
}

.dashboard-campaign__wrapper {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 480px) {
  .dashboard-campaign__wrapper {
    flex-wrap: wrap;
  }
}

.dashboard-campaign__main {
  width: 100%;
  color: rgb(0 34 61 / 0.54);
  font-size: 11px;
  font-weight: 300;
  margin-left: -1px;
  border-left: 1px solid #e1e3e6;
  min-height: 210px;

}

.dashboard-campaign__main_without-tabs {
  padding: 16px 24px;
}

.dashboard-campaign__aside {
  width: 210px;
  min-width: 210px;
  min-height: 200px;
  padding: 16px 24px;
}

@media (max-width: 480px) {
  .dashboard-campaign__aside {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .dashboard-campaign__aside {
    border-right: none;
  }
}

.dashboard-campaign__main-title {
  font-size: 14px;
  color: rgb(0 34 61 / 0.54);
  font-weight: 500;
}

.dashboard-campaign__main-title span {
  display: inline-block;
  vertical-align: middle;
}

.dashboard-campaign__main-title .tips__wrapper {
  margin-left: 4px;
}

.dashboard-campaign-info {
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
}

.dashboard-campaign-info__item {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
}

.dashboard-campaign-info__item .tips-wrapper {
  line-height: 1;
}

.dashboard-campaign-info__item-value {
  font-size: 14px;
  font-weight: 600;
  color: rgb(0 34 61 / 0.87);
  margin-bottom: 0;
}

.dashboard-campaign-info__item_small {
  margin-top: 8px;
}

.dashboard-campaign-info__item-value_with-cta {
  border: none;
  padding: 0;
  border-radius: 2px;
  background: none;
  font-weight: 500;
  color: #1696ff;
  text-decoration: underline;
}

.dashboard-campaign-info__item-value_with-cta:hover,
.dashboard-campaign-info__item-value_with-cta:focus {
  cursor: pointer;
  background-color: #edeff1;
  color: #1f2f4d;
  outline: none;
}

.dashboard-campaign-info__item-value_with-cta:active {
  cursor: pointer;
  background-color: #edeff1;
  color: #1f2f4d;
  outline: none;
}

.dashboard-campaign-info__item-value_with-cta:disabled,
.dashboard-campaign-info__item-value_with-cta:disabled:hover,
.dashboard-campaign-info__item-value_with-cta:disabled:focus,
.dashboard-campaign-info__item-value_with-cta:disabled:active {
  color: #798294;
  text-decoration: none;
  cursor: default;
  background-color: transparent;
}

.dashboard-campaign-suggested-budget-tips__wrapper {
  max-width: 320px;
}

.dashboard-campaign-suggested-budget-tips__content {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.dashboard-campaign-admessage {
  margin: 16px 24px;
}

.dashboard-campaign-admessage-preview__content {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0 34 61 / 0.87);
}

.dashboard-campaign-admessage-preview__content:first-of-type {
  margin-top: 12px;
}

.dashboard-campaign-admessage-preview__content:last-of-type {
  margin-bottom: 12px;
}

.dashboard-campaign-admessage-editor.show {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.dashboard-campaign-admessage-editor__input-wrapper {
  margin: 16px 0 24px;
  position: relative;
  width: 100%;
}

.dashboard-campaign-admessage-editor__button-group {
  width: 100%;
  text-align: right;
}

.dashboard-campaign-admessage-editor__button-group .button:first-child {
  margin-right: 10px;
}

.dashboard-campaign__aside-nav .button:first-child {
  margin-right: 10px;
}

.dashboard-campaign-section-nav-button__tips-wrapper {
  max-width: 190px;
}

.dashboard-campaign-section-nav-button__tips-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  color: transparent;
}

.dashboard-campaign-section-nav-button__tips-icon::before {
  box-shadow: none;
  color: transparent;
}

.dashboard-campaign-section-nav-button__tips-icon_disabled:hover,
.dashboard-campaign-section-nav-button__tips-icon_disabled:focus,
.dashboard-campaign-section-nav-button__tips-icon_disabled:active {
  cursor: not-allowed;
}

.dashboard-campaign-beta__badge {
  background: #00223d;
  color: #efffeb;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2px 12px;
  width: 64px;
  font-size: 8px;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 12px;
  display: inline-block;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  border: 2px solid #fff;
}
