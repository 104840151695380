.shopify-page-container {
  background: #f6f7fb;
}

.shopify-page-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.shopify-page-layout__container {
  display: flex;
  flex: 1 1 80%;
  text-align: center;
  color: #00223d;
}

.shopify-page-layout__content-wrapper {
  flex: 1 1 100%;
  margin: auto;
}

.shopify-page-layout__logo-container {
  flex: 1 1 20%;
  margin: auto;
}

.shopify-page-layout__logo {
  display: inline-block;
  width: 207px;
  height: 40px;
  background: url("retargetapp-logo.svg") no-repeat;
  background-size: 100% 100%;
}

@media screen and (max-width: 575px) {
  .shopify-page-layout__container {
    flex: 1 1 95%;
  }

  .shopify-page-layout__logo-container {
    flex: 1 1 5%;
  }

  .shopify-page-layout__logo {
    width: 120px;
    height: 23px;
  }
}
