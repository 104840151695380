.customer-interview-invite {
  width: 100%;
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.04);
  margin-bottom: 40px;
  position: relative;
  background: url('./assets/customer-interview-invite.svg') no-repeat 100% 0;
  background-size: cover;
}

.customer-interview-invite--home {
  margin-top: 40px;
}

@media (max-width: 780px) {
    .customer-interview-invite {
      background-position: 40%;
    }
}

.customer-interview-invite-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 0;
}

.customer-interview-invite__body {
  font-size: 13px;
  line-height: 20px;
  margin: 4px 0 8px;
  flex-basis: auto;
  font-weight: 400;
  max-width: 670px;
}

@media (max-width: 780px) {
  .customer-interview-invite__body {
    max-width: 500px;
  }
}

.customer-interview-invite-title {
  font-size: 20px;
  font-weight: 600;
}

.customer-interview-invite-title span {
  font-size: 20px;
  font-weight: 600;
  background-color: #fff;
  color: #07AE3A;
  border-radius: 5px;
  padding: 4px 5px;
}

@media (max-width: 780px) and (min-width: 700px) {
  .customer-interview-invite-title {
    font-size: 28px;
  }
}

.customer-interview-invite-cta {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.customer-interview-invite-cta a {
  margin-right: 24px;
  border-radius: 4px;
}

.customer-interview-invite-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  text-decoration: none;
  line-height: 11px;
  text-align: center;
  cursor: pointer;
  height: 11px;
  width: 11px;
  background-image: url("../assets/close-sign.svg");
  z-index: 25;
}

.customer-interview-invite-link {
  background: none;
  color: #fff;
  font-weight: 300;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}
