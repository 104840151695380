.connect-instagram {
    padding: 56px 120px;
    color: #00223D;
    display: flex;
}

.connect-instagram__side {
    flex-basis: 186px;
}

.connect-instagram__main {
    flex-basis: calc(100% - 32px - 186px);
    margin-right: 32px;
}

.connect-instagram__title {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.25px;
    font-weight: 300;
    margin: 0 0 32px;
}

.connect-instagram__description {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;
}

.connect-instagram__description a {
    text-decoration: underline;
}

.connect-instagram__description + connect-instagram__description {
    margin-top: 21px;
}

.connect-instagram__dropdown-title {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.25px;
    margin: 0 0 12px;
    font-weight: 500;
}

.connect-instagram__btn-wrapper {
    margin-top: 24px;
}

.connect-instagram__checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
}