.bigcommerce-contact-us__wizard-container {
  box-shadow: none;
}

.bigcommerce-contact-us__wizard-container p {
  font-size: 15px;
  margin-bottom: 8px;
}

p.bigcommerce-contact-us__text {
  margin-bottom: 40px;
}

.bigcommerce-contact-us__text:first-child {
  margin-bottom: 32px;
}

.bigcommerce-contact-us__button {
  width: 264px;
}
