.retargeting-invite {
  width: 100%;
  border-left: 4px #0c3 solid;
  padding: 24px 39px 24px 28px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #1f2f4d;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.04);
  margin-top: 16px;
}

@media (min-width: 700px) {
  .retargeting-invite {
    padding-right: 388px;
    background: #fff url("./assets/retargeting-invite-background.png") no-repeat right 0 center;
    background-size: auto 100%;
  }
}

@media (max-width: 760px) and (min-width: 650px) {
  .retargeting-invite {
    background-size: 60% auto;
  }
}

.retargeting-invite-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 0;
}

.retargeting-invite__body {
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 24px;
  flex-basis: auto;
  font-weight: 400;
  max-width: 650px;
}

.retargeting-invite__body p:last-child {
  margin: 0;
}

.retargeting-invite-title {
  display: flex;
  flex-flow: column;
  font-weight: 400;
  color: rgb(31 47 77 / 0.8);
}

.retargeting-invite-title__content {
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  color: rgb(31 47 77 / 1);
}

@media (max-width: 780px) and (min-width: 750px) {
  .retargeting-invite-title__content {
    font-size: 33px;
  }
}

@media (max-width: 749px) and (min-width: 700px) {
  .retargeting-invite-title__content {
    font-size: 28px;
  }
}

.retargeting-invite-cta {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: baseline;
  color: #fff;
}

.retargeting-invite-cta button:last-child {
  margin-left: 24px;
}
