.insta-mob {
    width: 220px;
    margin: 0 auto;
}

.insta-mob__post-info {
    font-size: 6px;
    line-height: 1.1;
    padding: 1px 3.5%;
    margin-top: -1px;
    background: #ffffff;
    position: relative;
    z-index: 2;
}

.insta-mob__author {
    font-weight: 600;
    margin-right: 4px;
}

.insta-mob__post-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.insta-mob__more {
    position: absolute;
    bottom: 1px;
    color: #363636;
    right: 3.5%;
    background: #ffffff;
}

.insta-mob__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 6px 8px 6px 5px;
}

.insta-mob__avatar-wrapper {
    display: flex;
    align-items: center;
}

.insta-mob__avatar {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 6px;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
}

.inta-mob__title {
    font-size: 7px;
    line-height: 8px;
    font-weight: 500;
    color: #262626;
    margin-bottom: 2px;
}

.insta-mob__sponsored {
    font-size: 5px;
    line-height: 6px;
    color: #262626;
}