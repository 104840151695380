.recommendations {
    margin-bottom: 16px;
    max-width: 400px;

}

.recommendations__title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #00223D;
}

.recommendations__card {
    background: #00223D;
    border-radius: 8px;
    padding: 20px 24px 24px;
    color: #FFFFFF;
    position: relative;
    display: grid;
    grid-template-columns: 115px 1fr;
    grid-gap: 16px 12px;
}

.recommendations__badge {
    position: absolute;
    left: 16px;
    top: -7px;
    background: #5F94C0;
    border-radius: 16px;
    font-size: 10px;
    line-height: 16px;
    padding: 0 8px;
}

.recommendations__card-item {
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: 400;
    font-family: 'SF Pro Display', sans-serif;
}

.recommendations__card-item-name {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.54);
}

.recommendations__card-item-value {
    font-weight: 600;
}