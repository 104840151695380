.not-found__container {
  padding: 30px 60px;
}

.not-found__logo-container {
  display: flex;
}

.not-found__logo {
  flex: 1 1 0;
  margin: auto auto 30px;
  height: 80px;
}

.not-found__title {
  color: #353535;
  margin-bottom: 16px;
}
