.verification-dropdown__wrapper {
    border: 1px solid #C4CDD5;
    border-radius: 2px;
    padding: 24px;
}

.error {
    background: url("./img/warning-icon.svg") 99% center no-repeat, linear-gradient(270deg, #FDEFEC 0%, rgba(255, 255, 255, 0) 100%);
}

.successful {
    background: url("./img/done-icon.svg") 99% center no-repeat, linear-gradient(270deg, #EFFFEB 0%, rgba(255, 255, 255, 0) 100%);
}

.verification-dropdown__content {
    border: 1px solid #C4CDD5;
    border-radius: 2px;
    background: #fff;
    padding: 24px;
    border-top: none;
    position: relative;
}