.gmail-mobile {
    width: 181px;
    height: 360px;
    margin: 0 auto;
    background: url("img/backgound.svg") no-repeat center;
    background-size: 100% 100%;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    font-family: 'Roboto', sans-serif;
    color: #232323;
}

.gmail-mobile::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 220px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1px);
    bottom: 29px;
    left: 0;
}

.gmail-mobile__title {
    position: absolute;
    top: 68px;
    left: 50px;
    font-size: 8px;
    line-height: 9px;
    font-weight: 700;
    max-width: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gmail-mobile__description {
    max-width: 120px;
    font-size: 7px;
    line-height: 10px;
    color: #3F3F3F;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 37px;
}