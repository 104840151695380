.not-qualified__wrapper {
  margin: 0 auto;
  padding: 7vh 131px 6vh;
}

.not-qualified__section {
  margin: 4vh auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.not-qualified-not-ready__article {
  margin-bottom: 20px;
}

.not-qualified-reasons__block {
  width: 430px;
  border: 2px solid #1696ff;
  border-radius: 10px;
  display: flex;
  height: 100px;
}

.not-qualified-required__block {
  width: 146px;
  height: 100%;
  display: flex;
  background: #1696ff;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.not-qualified-required__block img {
  width: 58px;
}

.not-qualified-reasons__block ul {
  font-size: 12px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-qualified_contact_us_button {
  width: max-content;
  padding-left: 46px;
  padding-right: 46px;
  margin-top: 25px;
}

.not-qualified_contact_us_button_text {
  color: #fff;
}

.not-qualified-reasons__block ul li {
  text-align: left;
  margin: 1px 0;
}

.not-qualified-contact-us__article {
  margin-top: 16px;
  color: #a1adb7;
  font-size: 12px;
}

.not-qualified-contact-us__article a,
.not-qualified-contact-us__article .settings-page__link {
  color: #a1adb7;
}

#sign-title {
  font-family: inherit;
}
