.messenger-mobile {
    width: 182px;
    height: 361px;
    position: relative;
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
}

.messenger-mobile__background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.messenger-mobile__blur {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    position: absolute;
    left: 0;
    width: 100%;
}

.messenger-mobile__blur--1 {
    top: 0;
    height: 162px;
}

.messenger-mobile__blur--2 {
    top: 200px;
    height: 135px;
}

.messenger-mobile__adv {
    padding: 4px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    background: #ffffff;
    height: 40px;
    width: 100%;
    top: 164px;
    left: 0;
}

.messenger-mobile__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    flex-shrink: 0;
    margin-right: 4px;
}

.messenger-mobile__title {
    font-size: 8px;
    line-height: 10px;
    font-weight: bold;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.messenger-mobile__description {
    font-size: 7px;
    line-height: 8px;
    color: #65676B;
    max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 1px 0;
}

.messenger-mobile__link {
    color: #057CFD;
    font-weight: 600;
    font-size: 7px;
    line-height: 8px;
}

.messenger-mobile__img-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    overflow: hidden;
}

.messenger-mobile__img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}