.tabs-navigation__link--insights {
  width: 200px;
  height: 60px;
  display: block;
}

.tabs-navigation--insights {
  margin-top: -1px;
  margin-left: 1px;
  display: flex;
}

.tabs-navigation__link-content-static--insights {
  position: relative;
  height: 60px;
  text-align: center;
  color: #00223d8a;
  line-height: 60px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  border-top: 2px solid transparent;
  border: 1px solid transparent;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom: none;
}

.tabs-navigation__link-content-static--insights:hover {
  color: #00223d;
}

.nav-item:nth-child(1) .tabs-navigation__link-content-static--insights {
  padding-left: 25px;
}

.nav-item:nth-child(2) .tabs-navigation__link-content-static--insights {
  padding-left: 8px;
}

.nav-item:nth-child(3) .tabs-navigation__link-content-static--insights {
  padding-left: 8px;
}

.tabs-navigation__link_active--insights .tabs-navigation__link-content-static--insights {
  border-color: #e3e3e3;
  color: #00223d;
  background-color: #fff;
}

.tabs-navigation__link-content-static--insights span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}

.nav-item:first-child .tabs-navigation__link-content-static--insights span::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 21px;
  width: 18px;
  height: 18px;
  background-image: url("./fb-tab-icon.svg");
}

.nav-item:nth-child(2) .tabs-navigation__link-content-static--insights span::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 21px;
  width: 20px;
  height: 18px;
}

.google-tab span::before {
  background-image: url("./google-tab-icon.svg");
}

.nav-item .disable-tab--insights {
  pointer-events: none;
}

.available-ad-types-tab {
    background: url('./adwisely-icon.svg') 1px 23px no-repeat;
}

.available-ad-types-tab a {
  color: #00223d8a !important;
}

.available-ad-types-tab a:hover {
  color: #00223d !important;
}