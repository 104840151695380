.modal-open {
  padding-right: 0 !important;
}

.c-modal__inner.video-player__modal .c-modal__content {
  background-color: transparent;
  border: none;
}

.c-modal__inner.video-player__modal {
  max-width: 568px;
}

.video-player__modal__body {
  display: flex;
  justify-content: center;
}

.video-player__close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  background-image: url("./assets/close-sign.svg");
  background-size: contain;
  z-index: 25;
}

.video-help-section {
  max-width: 186px;
  max-height: 228px;
  border: 2px solid #1696ff;
  padding: 24px;
  border-radius: 8px;
  font-size: 14px;
  color: #00223D;
}

.video-help-section__play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 78px;
  border-radius: 4px;
  cursor: pointer;
  background: url("./assets/gl-billing-video-cover.png") center no-repeat;
  background-size: contain;
  margin-bottom: 12px;
  border: 1px solid #1696ff;
}

.video-help-section__play-button img {
  width: 24px;
  height: 17px;
  cursor: pointer;
}

.person-icon {
  display: inline-block;
  text-indent: -9999px;
  width: 12px;
  height: 12px;
  background: url("./assets/person-icon.svg") no-repeat;
  background-size: 12px 12px; 
}

.video-help-section__chat-button {
  cursor: pointer;
  color: #1696FF;
  margin: 0;
}