.modal-backdrop {
  background-color: #00223d !important;
}

.modal.fade .modal-charge__dialog {
  transform: translate(50%, -300%);
}

.modal-charge__dialog {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -40%);
  margin: 0;
  width: 56%;
  max-width: 510px;
  max-height: 90%;
}

.modal-charge__dialog .c-modal__content {
  padding: 85px 69px 36px;
  background: #fff url("shopify-logo.svg") no-repeat center 36px;
  border: 1px solid #d6dadf;
  border-radius: 12px;
}

.modal-charge__dialog--fb .c-modal__content {
  padding: 85px 60px 36px;
  background: #fff url("fb-logo.svg") no-repeat center 36px;
  border: 1px solid #d6dadf;
  border-radius: 12px;
  position: relative;
}

.modal-charge__dialog--fb__hero {
  position: absolute;
  bottom: 36px;
  left: 15px;
  height: 100px;
}

@media (max-width: 845px) {
  .modal-charge__dialog--fb__hero {
    display: none;
  }
}

.modal-permissions__dialog,
.modal-permissions__dialog.modal-dialog,
.modal.show .modal-permissions__dialog.modal-dialog {
  width: 56%;
  max-width: 560px;
}

.modal-permissions__dialog .modal-content,
.modal-permissions__dialog.modal-dialog .modal-content {
  padding: 84px 60px 40px;
  background: #fff url("logo.svg") no-repeat center 30px;
}

@media (max-width: 767px) {
  .modal-charge__dialog .modal-content,
  .modal-charge__dialog.modal-dialog .modal-content {
    padding: 100px 64px 24px; /* 76px=logo size + logo top padding + logo bottom padding */
  }
}

@media (max-width: 576px) {
  .modal-charge__dialog .modal-content,
  .modal-charge__dialog.modal-dialog .modal-content {
    padding: 100px 48px 24px; /* 76px=logo size + logo top padding + logo bottom padding */
  }
}

@media (max-width: 480px) {
  .modal-charge__dialog .modal-content,
  .modal-charge__dialog.modal-dialog .modal-content {
    padding: 100px 24px 24px; /* 76px=logo size + logo top padding + logo bottom padding */
  }
}

.modal-charge__header,
.modal-charge__header.modal-header {
  margin: 0 auto 12px;
  padding: 0;
  border: none;
}

.modal-permissions__header,
.modal-permissions__header.modal-header {
  margin: 15px auto 12px;
}

.modal-charge__header .modal-title,
.modal-charge__header.modal-header .modal-title {
  width: 100%;
  text-align: center;
}

.modal-charge__header .modal-charge__title {
  margin: 0;
  color: rgb(31 47 77 / 0.9);
  line-height: 27px;
  font-size: 18px;
  font-weight: 500;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  max-width: 378px;
}

.modal-charge__header .modal-charge__title span {
  color: #07ae3a;
}

.modal-charge__header .modal-charge__title--fb span {
  color: #3664a2;
}

.modal-charge__body,
.modal-charge__body.modal-body {
  margin: 0 0 28px;
  padding: 0;
  text-align: center;
}

.modal-permissions__body,
.modal-permissions__body.modal-body {
  margin: 0 0 24px;
}

.modal-charge-body__content {
  margin: 0 auto 8px;
  color: #1f2f4d;
  font-size: 14px;
  font-weight: normal;
  max-width: 370px;
}

.modal-charge-body__caption {
  font-weight: bold;
}

.modal-charge-body__footnote {
  margin: 14px 0 0;
}

.modal-permissions-body__footnote {
  margin: 12px 0 0;
}

.modal-charge__footer,
.modal-charge__footer.modal-footer {
  margin: 0 auto;
  padding: 0;
  border: none;
  justify-content: center;
}

.modal-charge__footer > :not(:last-child),
.modal-charge__footer.modal-footer > :not(:last-child) {
  margin-right: 12px;
}

.modal-charge__button {
  padding: 10px 20px;
  display: block;
  border: 1px solid;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(22 29 37 / 0.05);
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1em;
}

.modal-charge__button:hover,
.modal-charge__button:focus {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.modal-charge__button:active {
  outline: none;
}

.modal-charge__button_primary {
  background-color: #1696ff;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
}

.modal-charge__button_primary:hover,
.modal-charge__button_primary:focus {
  background-color: #1078cd;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
}

.modal-charge__button_primary:active {
  background-color: #0d62a7;
  color: #fff;
}

.modal-charge__button_secondary {
  padding: 10px;
  background-color: #fff;
  border-color: #e1e3e6;
  color: #1f2f4d;
}

.modal-charge__button_secondary:hover,
.modal-charge__button_secondary:focus {
  background-color: #f4f6f8;
  box-shadow: inset 0 1px 1px 0 rgb(99 115 129 / 0.15), 0 1px 0 0 rgb(22 29 37 / 0.05);
  color: #1f2f4d;
}

.modal-charge__button_secondary:active {
  box-shadow: inset 0 1px 1px 0 rgb(99 115 129 / 0.25), 0 1px 0 0 rgb(22 29 37 / 0.15);
  color: #1f2f4d;
}

.modal-permissions__link {
  color: #000;
}

.modal-permissions__link span {
  border-bottom: 1px solid #000;
}

.modal-permissions__link:hover,
.modal-permissions__link:focus,
.modal-permissions__link:active {
  text-decoration: none;
  color: #000;
}

.modal-charge__link {
  padding: 0;
  background: transparent;
  border: none;
  color: #1696ff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.modal-charge__link:hover,
.modal-charge__link:focus {
  color: #1378cc;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
}

.modal-charge__link:active {
  color: #2f70cc;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
}

.modal-charge__link_with-arrow::after {
  content: "→";
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}
