.connected-assets {
  width: 186px;
  border: 2px solid #D6DADF;
  padding: 24px 22px 24px 24px;
  border-radius: 8px;
  margin-bottom: 32px;
}

.connected-assets p {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.connected-assets__title {
  font-size: 16px;
  line-height: 27px;
  margin: 0;
}

.connected-assets__caption {
  margin-bottom: 4px;
  margin-top: 12px;
}

.connected-assets__caption span {
  font-weight: 500;
  margin-right: 4px;
  font-size: 14px;
}

.connected-assets__info {
  color: #758896;
  font-weight: 300;
  cursor: default;
}