.login-view__title {
  margin-bottom: 1.1em;
  color: #000;
  font-size: 30px;
  font-weight: 100;
  opacity: 0.9;
}

.login-view__description {
  display: block;
  margin-top: 0.7em;
  margin-bottom: 4em;
  text-align: center;
  color: #00223d;
  font-size: 15px;
  opacity: 0.8;
}

.login-view-error__wrapper {
  max-width: 420px;
  text-align: left;
}

.login-view-error__wrapper_wide {
  max-width: 550px;
}

@media screen and (max-width: 575px) {
  .login-view-error__wrapper {
    margin-bottom: 0.5em;
  }

  .login-view__title {
    max-width: 320px;
    margin: 0 auto 1em;
  }

  .login-view__description {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

.facebook-login__cta-button,
.facebook-login__cta-button.btn,
.facebook-login__cta-button.btn-secondary,
.facebook-login__cta-button.btn.btn-secondary {
  position: relative;
  padding: 10px 10px 10px 50px;
  height: 40px;
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.25px;
  border-radius: 4px;
  width: max-content;
  background-color: #4a66ad;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.facebook-login__cta-button[disabled] {
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 767px) {
  .facebook-login__cta-button,
  .facebook-login__cta-button.btn,
  .facebook-login__cta-button.btn-secondary,
  .facebook-login__cta-button.btn.btn-secondary {
    width: 320px;
  }
}

@media screen and (max-width: 360px) {
  .facebook-login__cta-button,
  .facebook-login__cta-button.btn,
  .facebook-login__cta-button.btn-secondary,
  .facebook-login__cta-button.btn.btn-secondary {
    width: 300px;
  }
}

.facebook-login__cta-button:hover,
.facebook-login__cta-button.btn:hover,
.facebook-login__cta-button.btn-secondary:hover,
.facebook-login__cta-button.btn.btn-secondary:hover {
  background-color: #415a98;
  text-decoration: none;
  color: #fff;
}

.facebook-login__cta-button:active,
.facebook-login__cta-button.btn:active,
.facebook-login__cta-button.btn-secondary:active,
.facebook-login__cta-button.btn.btn-secondary:active {
  background-color: #415a98;
  box-shadow: 0 1px 0 #e7eaf3, inset 0 1px 8px rgb(31 44 77 / 0.2), inset 0 1px 4px rgb(31 44 77 / 0.6) !important;
}

.facebook-login__cta-button.btn::after,
.facebook-login__cta-button.btn-secondary::after,
.facebook-login__cta-button::after {
  background-color: #29487d;
}

.facebook-login__cta-button::before,
.facebook-login__cta-button.btn::before,
.facebook-login__cta-button.btn-secondary::before,
.facebook-login__cta-button.btn.btn-secondary::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("./fb-icon.svg") center no-repeat;
  background-size: 24px;
  left: 8px;
  top: 8px;
}

.facebook-login__cta-button[disabled],
.facebook-login__cta-button.btn[disabled],
.facebook-login__cta-button.btn-secondary[disabled],
.facebook-login__cta-button.btn.btn-secondary[disabled] {
  background-color: #99a9d1;
  border-color: inherit;
}

.login-view__button .facebook-login__cta-button {
  width: 320px;
  height: 40px;
  line-height: 19px;
}

.login-view__button {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-view__button__fb {
  margin-bottom: 20px;
}
