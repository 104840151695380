.fb-pages-list {
  margin: 0;
  padding: 16px 0;
  list-style: none;
  max-height: 304px;
}

.fb-pages-list__item {
  position: relative;
  padding: 14px 72px 14px 60px;
}

.fb-pages-list__item::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 24px;
  width: 18px;
  height: 100%;
  background: url("../assests/flag-icon.svg") 0 no-repeat;
  background-size: 18px;
  opacity: 0.4;
}

.fb-pages-list__item:not(:last-child) {
  margin-bottom: 6px;
}

.fb-pages-list__item:hover {
  cursor: pointer;
}

.fb-pages-list__item:hover::before {
  opacity: 0.6;
}

.fb-pages-list__item:hover .fb-pages-list__item__title {
  color: rgb(31 47 77 / 1);
}

.fb-pages-list__item.active {
  background-color: #f6f7fb;
  pointer-events: none;
}

.fb-pages-list__item.active::before {
  opacity: 1;
}

.fb-pages-list__item.active::after {
  content: "";
  position: absolute;
  top: -1px;
  right: 24px;
  width: 24px;
  height: 100%;
  background: url("../assests/check.svg") 0 no-repeat;
  background-size: 24px;
}

.fb-pages-list__item.active .fb-pages-list__item__title {
  color: rgb(31 47 77 / 1);
}

.fb-pages-list__item__title {
  padding: 0;
  margin: 0;
  color: rgb(31 47 77 / 0.4);
  font-size: 15px;
}

.fb-pages-list__item__notice {
  padding: 0;
  margin: 0;
  color: rgb(31 47 77 / 0.32);
  font-size: 11px;
}
