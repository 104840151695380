.alert__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 2px;
  background-color: #fff;
  overflow: hidden;
  max-width: 460px;
  margin: 0 auto 32px;
}

.alert__wrapper::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  background-origin: content-box;
}

.alert__wrapper_light {
  padding: 0 0 0 24px;
  border: none;
}

.alert__wrapper_light::before {
  padding: 0 8px 0 0;
  width: 24px;
  background: transparent url("exclamation-circle.svg") no-repeat left 0;
  background-size: 16px;
}

.alert__wrapper_warning {
  padding: 16px 16px 16px 54px;
  border: 1px solid #fe9929;
}

.alert__wrapper_warning::before {
  width: 40px;
  background: #fe9929 url("exclamation-triangle.svg") no-repeat center 14px;
  background-size: 22px;
}

.alert__wrapper_info {
  padding: 16px 16px 16px 54px;
  border: 2px solid #1696ff;
}

.alert__wrapper_info::before {
  width: 36px;
  background: #1696ff url("info-circle.svg") no-repeat center 8px;
  background-size: 20px;
}

.alert__wrapper_danger {
  padding: 16px 16px 16px 54px;
  border: 1px solid #ed6347;
}

.alert__wrapper_danger::before {
  width: 36px;
  background: #ed6347 url("exclamation-triangle.svg") no-repeat center 8px;
  background-size: 22px;
}

.alert__wrapper_with-top-margin {
  margin-top: 16px;
  margin-bottom: 0;
}

.alert__description {
  color: rgb(31 47 77 / 0.9);
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.alert-description__item:last-of-type {
  margin: 0;
}

.alert-description__caption {
  font-weight: 500;
}

.alert-description__link {
  color: rgb(31 47 77 / 0.8);
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0;
}

.alert-description__link:hover {
  cursor: pointer;
}

.alert-description__link:hover,
.alert-description__link:focus {
  color: rgb(0 34 61 / 0.87);
  outline: none;
}

.alert-description__link:active {
  color: rgb(31 47 77 / 0.6);
}

.alert-description__link_highlighted {
  padding: 0;
  background-color: transparent;
  border: none;
  color: #1696ff;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

.alert-description__link_highlighted:hover,
.alert-description__link_highlighted:focus {
  background-color: transparent;
  border: none;
  color: #147dd4;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

.alert-description__link_highlighted:active {
  background-color: transparent;
  border: none;
  color: #1897ff;
  text-decoration: none;
}

.alert-description__link_with-arrow {
  position: relative;
  display: inline-block;
  margin-right: 16px;
  margin-top: 15px;
}

.alert-description__link_with-arrow::after {
  content: "→";
  position: absolute;
  display: block;
  top: calc(50% - 0.65em);
  right: -17px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}

.alert-description__button-close {
  margin: 0 0 0 8px;
  padding: 0;
  width: 11px;
  height: 11px;
  border: none;
  color: transparent;
  background-color: rgb(31 47 77 / 0.6);
  mask-image: url("cross-sign.svg");
  mask-repeat: no-repeat;
}

.alert-description__button-close:hover,
.alert-description__button-close:focus {
  background-color: rgb(0 34 61 / 0.87);
  cursor: pointer;
}

.alert-description__button-close:active {
  background-color: rgb(31 47 77 / 0.2);
}
