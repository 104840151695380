.tt {
    height: 360px;
    width: 182px;
    margin: 0 auto;
    background: url("img/tik-tok-bg.png") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
}

.tt::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 330px;
    background: url("img/tik-tik-img.png") no-repeat center;
    background-size: 100% 100%;
}

.tt__adv {
    position: absolute;
    bottom: 46px;
    left: 4px;
    z-index: 2;
    border-radius: 1px;
    overflow: hidden;
    width: 120px;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(1px);
}

.tt__wrapper {
    display: flex;
    align-items: center;
    padding: 3px 3px 4px;
}

.tt__avatar {
    width: 38px;
    height: 38px;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    border-radius: 1px;
    margin-right: 4px;
    flex-shrink: 0;
}

.tt__title {
    font-size: 6px;
    color: #ffffff;
    line-height: 7px;
    font-weight: 500;
    margin-bottom: 2px;

}

.tt__descr {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 5px;
    line-height: 7px;
    color: #FFFFFF;
    max-width: 60px;
    margin-bottom: 2px;
}

.tt__sponsored {
    font-size: 4px;
    line-height: 5px;
}

.tt__btn {
    height: 14px;
    width: 100%;
    color: #FFFFFF;
    font-size: 5px;
    line-height: 6px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FE2B54;
    border-radius: 1px;
}