.change-email__wrapper {
  display: flex;
  height: 36px;
  margin: -8px 0 0;
}

.change-email__wrapper-error {
  display: flex;
  margin: -8px 0 -4px;
}

.change-email__wrapper span,
.change-email__wrapper-error span {
  margin-top: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgb(0 34 61 / 0.54);
}

.change-email__form,
.change-email-show-email {
  margin-left: 24px;
  position: relative;
}

.change-email__form {
  display: flex;
}

span.change-email-show-email {
  margin-top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgb(0 34 61 / 0.87);
}

.change-email-show-email::after {
  content: "";
  position: absolute;
  top: 4px;
  right: -24px;
  height: 16px;
  width: 16px;
  mask-size: 14px;
  background-color: rgb(0 34 61 / 0.6);
  mask-image: url("../../../components/Icons/pen.svg");
  mask-repeat: no-repeat;
}

.change-email-show-email:hover {
  cursor: pointer;
  color: #00223d;
  text-decoration: underline;
}

.change-email-show-email:hover::after {
  background-color: #00223d;
  cursor: pointer;
}

.change-email__form label {
  margin: 0;
  width: 240px;
  box-sizing: border-box;
  height: 36px;
}

.change-email__form-error {
  margin: 8px 0 2px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #fe1616;
}

.change-email__form-submit,
.change-email__form-cancel {
  margin: 0 0 0 8px;
  height: 36px;
  width: 36px;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

.change-email__form-submit:hover,
.change-email__form-cancel:hover {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
}

.change-email__form-submit:active,
.change-email__form-cancel:active {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
  outline: none;
}

.change-email__form-submit img,
.change-email__form-cancel img {
  height: 12px;
  width: 12px;
  text-align: center;
  margin-top: -4px;
}

.change-email__form .change-email__form-input {
  padding: 6px 12px 7px;
  width: 100%;
}

.change-email__form-pen::before {
  height: 16px;
  width: 16px;
  mask-size: 14px;
  margin-left: 8px;
  margin-bottom: -2px;
}

.change-email__form-pen::before:hover {
  cursor: pointer;
  background-color: #000;
}

.verify-email__wrapper {
  border: 2px solid #1696ff;
  border-radius: 2px;
  height: 53px;
  display: flex;
  margin: 0 0 -4px;
}

.verify-email__aside {
  background: #1696ff url("icon-info-extended.svg") 11px 10px no-repeat;
  background-size: 16px;
  flex: 0 0 40px;
}

.verify-email__main {
  padding: 14px 16px;
}
