.card-block-title {
  font-weight: 500;
  font-size: 18px;
  color: #00223d;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 27px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.card-block-title span {
  display: inline;
  vertical-align: middle;
  margin-right: 4px;
}

.card-block-title_subtitle {
  font-weight: 500;
  font-size: 14px;
  color: rgb(0 34 61 / 0.54);
  letter-spacing: 0.25px;
  line-height: 21px;
}
