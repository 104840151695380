.modal-backdrop {
    background-color: #00223d !important;
  }
  
  .modal-common,
  .modal-common__dialog.c-modal__inner,
  .c-modal.show .modal-common__dialog.c-modal__inner {
    position: absolute;
    top: 40%;
    right: 50%;
    transform: translate(50%, -40%);
    margin: 0;
    width: 100%;
    max-width: 505px;
  }

  .modal-common__close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    display: inline-block;
    width: 12px;
    height: 12px;
    font-size: 0;
    vertical-align: middle;
    background: url("./close.svg") no-repeat;
    background-size: cover;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .modal-common__buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-common__dialog .c-modal__content,
  .modal-common__dialog.c-modal__inner .c-modal__content {
    padding: 24px 40px 40px;
    border: 1px solid #D6DADF;
  }
  
  @media (max-width: 480px) {
    .modal-common__dialog .c-modal__content,
    .modal-common__dialog.c-modal__inner .c-modal__content {
      padding: 0 12px;
    }
  }
  
  .modal-common__body,
  .modal-common__body.c-modal-body {
    padding: 0;
  }

  .modal-common-body__title {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
    font-weight: normal;
  }

  .modal-common-body__content {
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 40px;
    font-weight: 600;
  }
  
  .modal-common__button--primary {
    margin-left: 16px;
  }

  .modal-common__link {
    text-decoration: none;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    cursor: pointer;
    color: #34a4ff;
  }
  
  .modal.fade .modal-common__dialog,
  .modal.fade .modal-common__dialog.modal-dialog {
    transform: translate(50%, -50%);
  }
  