.errorpage__wrapper {
  width: 100%;
  height: 100%;
  background: #f6f7fb;
}

.errorpage {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.errorpage__img {
  margin-bottom: 34px;
  width: 80px;
  height: 80px;
}

.errorpage__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.errorpage__title {
  color: #00223d;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.errorpage__description {
  color: rgb(0 34 61 / 0.67);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

hr.errorpage__bottom-line {
  width: 301px;
  height: 1px;
  background-color: #d5dae0;
  border: none;
  margin: 25px 0;
}

.errorpage__subtitle {
  color: rgb(0 34 61 / 0.87);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.errorpage__subtitle span {
  cursor: pointer;
}

.errorpage__message-us {
  color: rgb(0 34 61 / 0.87);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.errorpage__message-us a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.errorpage__ra-logo {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
