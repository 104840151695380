.dropdown-button__menu,
.dropdown-button__menu.dropdown-menu {
  padding: 0;
  margin: 0;
  width: 100%;
  border-color: #c4cdd5;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
}

.dropdown-button__menu,
.dropdown-button__menu .dropdown-button__menu-list {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 16px rgb(0 0 0 / 0.1);
}
