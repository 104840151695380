.ad-preview-standard {
  padding: 24px;
  border-left: 1px solid #e1e3e6;
}

.ad-preview-standard span {
  font-weight: 500;
  color: #00223d;
  line-height: 21px;
  font-size: 14px;
}

.ad-preview-standard p {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 8px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(0 34 61 / 0.38);
}

.ad-preview-standard__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 460px;
  margin: 0 auto;
}

.ad-preview-standard__card {
  width: 140px;
  height: 254px;
  border: 1px solid #dadcdf;
  margin-bottom: 10px;
}

.ad-preview-standard__image-wrapper {
  margin-top: 8px;
  width: 138px;
  height: 136px;
  display: flex;
  justify-content: center;
}

.ad-preview-standard__image {
  width: 100%;
  object-fit: contain;
}

.ad-preview-standard__desc {
  font-family: Arial, serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
  padding: 8px;
}

.ad-preview-standard__product-name {
  color: #1a0dab;
  margin-bottom: 8px;
  max-height: 32px;
  overflow: hidden;
}

.ad-preview-standard__price {
  font-weight: 700;
  margin-bottom: 2px;
}
