.modal-backdrop {
  background-color: #00223d !important;
}

.modal.fade .modal-widget__dialog,
.modal.fade .modal-widget__dialog.modal-dialog {
  transform: translate(50%, -300%);
}

.modal-widget__dialog,
.modal-widget__dialog.modal-dialog,
.modal.show .modal-widget__dialog.modal-dialog {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  margin: 0;
  width: 90%;
  max-width: 576px;
  max-height: 90%;
}

.modal-widget__dialog .modal-content,
.modal-widget__dialog.modal-dialog .modal-content {
  overflow-y: scroll;
}

.modal-widget__header,
.modal-widget__header.modal-header {
  position: relative;
  padding: 17px 20px;
  border: none;
  background-color: #f6f7fb;
  border-left: 4px solid rgb(31 47 77 / 0.4);
  border-bottom: 1px solid rgb(31 47 77 / 0.4);
  border-radius: 0.2rem 0.3rem 0 0;
  box-shadow: 0 1px 0 #f0f1f5;
}

.modal-widget__header_has-close-button,
.modal-widget__header_has-close-button.modal-header {
  padding: 17px 51px 17px 20px;
}

.modal-widget__dialog_info .modal-widget__header,
.modal-widget__dialog_info .modal-widget__header.modal-header {
  border-left-color: #1696ff;
}

.modal-widget__dialog_success .modal-widget__header,
.modal-widget__dialog_success .modal-widget__header.modal-header {
  border-left-color: #5bc920;
}

.modal-widget__dialog_error .modal-widget__header,
.modal-widget__dialog_error .modal-widget__header.modal-header {
  border-left-color: #fe1616;
}

.modal-widget__header .modal-title,
.modal-widget__header.modal-header .modal-title {
  color: #1e304d;
  font-size: 17px;
  text-align: left;
}

.modal-widget__header button.close,
.modal-widget__header.modal-header button.close {
  position: absolute;
  top: 0;
  right: 2px;
  bottom: 0;
  margin: auto 0;
  display: none;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  opacity: 0.8;
}

.modal-widget__header button.close:hover,
.modal-widget__header button.close:focus {
  border: none;
  outline: none;
  opacity: 1;
}

.modal-widget__header_has-close-button button.close,
.modal-widget__header_has-close-button.modal-header button.close {
  display: block;
}

.modal-widget__header button.close span {
  display: inline-block;
  width: 14px;
  height: 14px;
  font-size: 0;
  vertical-align: middle;
  background: url("./close-icon.svg");
}

.modal-widget__body,
.modal-widget__body.modal-body {
  padding: 24px 24px 0;
  max-height: 85vh;
  color: rgb(0 34 61 / 0.87);
  font-size: 13px;
  font-weight: 300;
  overflow-y: scroll;
}

.modal-widget__footer,
.modal-widget__footer.modal-footer {
  padding: 0 24px 24px;
  border: none;
}

.modal-widget-footer__container {
  margin: 12px 0 0;
}

.modal-widget-footer_extended .modal-widget-footer__container,
.modal-widget__footer_extended.modal-footer .modal-widget-footer__container {
  padding: 12px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f1f5;
}

.modal-widget__button_primary,
a.modal-widget__button_primary:not([href]):not([tabindex]) {
  padding: 8px 16px;
  border: 1px solid rgb(31 47 77 / 0.24);
  border-radius: 2px;
  background-color: #1696ff;
  color: #fff;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
}

.modal-widget__button_primary:hover,
.modal-widget__button_primary:focus,
a.modal-widget__button_primary:not([href]):not([tabindex]):hover,
a.modal-widget__button_primary:not([href]):not([tabindex]):focus {
  border-color: rgb(31 47 77 / 0.44);
  background-color: #1078cd;
  cursor: pointer;
  outline: none;
  color: #fff;
  text-decoration: none;
}

.modal-widget__button_primary:active,
a.modal-widget__button_primary:not([href]):not([tabindex]):active {
  background-color: #0d62a7;
  outline: none;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 480px) {
  .modal-widget__button_primary,
  a.modal-widget__button_primary:not([href]):not([tabindex]) {
    padding: 6px;
    font-size: 10px;
  }
}

.modal-widget__button_secondary {
  margin: 0 8px;
  padding: 8px 16px;
  border: 1px solid rgb(96 103 117 / 0.1);
  border-radius: 2px;
  background-color: #fff;
  color: rgb(30 48 77 / 0.64);
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
}

.modal-widget__button_secondary:hover,
.modal-widget__button_secondary:focus {
  border-color: rgb(96 103 117 / 0.3);
  cursor: pointer;
  outline: none;
}

.modal-widget__button_secondary:active {
  border-color: rgb(96 103 117 / 0.5);
  outline: none;
}

@media (max-width: 480px) {
  .modal-widget__button_secondary,
  a.modal-widget__button_secondary:not([href]):not([tabindex]) {
    padding: 6px 12px;
    font-size: 10px;
  }
}

.modal-widget__link,
a.modal-widget__link:not([href]):not([tabindex]) {
  padding: 0;
  border: none;
  background: transparent;
  color: rgb(31 47 77 / 0.6);
  font-size: 12px;
  text-decoration: underline;
}

.modal-widget__link:hover,
.modal-widget__link:focus,
a.modal-widget__link:not([href]):not([tabindex]):hover,
a.modal-widget__link:not([href]):not([tabindex]):focus {
  border: none;
  background: transparent;
  color: rgb(31 47 77 / 0.8);
  outline: none;
  cursor: pointer;
}

.modal-widget__link:active,
a.modal-widget__link:not([href]):not([tabindex]):active {
  border: none;
  background: transparent;
  color: rgb(31 47 77 / 0.4);
}

.modal-widget__link_with-arrow {
  position: relative;
  display: inline-block;
  margin-right: 16px;
}

.modal-widget__link_with-arrow::after {
  content: "→";
  position: absolute;
  display: block;
  top: calc(50% - 0.675em);
  right: -18px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}

.modal-widget__caption-text {
  font-weight: bolder;
}
