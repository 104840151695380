.no-wrap {
  white-space: nowrap;
}

.wizard-status-bar {
  min-height: 55px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.wizard-status-bar.create-campaign {
  border: 1px solid #d6dadf;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgb(214 218 223 / 20%), 0 2px 4px 0 rgb(0 0 0 / 4%);
}

.wizard-status-bar.full-width-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-right: 36px;
}

.wizard-status-bar.full-width-flex  .wizard-status-bar__link {
  font-size: 14px;
}

.wizard-status-bar__link {
  display: inline-block;
  font-size: 12px;
  color: #1f2f4d;
  padding: 0;
  pointer-events: none;
}

.wizard-status-bar .nav-item:not(:last-child) .wizard-status-bar__link {
  margin-right: 16px;
}

@media (max-width: 576px) {
  .wizard-status-bar__link {
    padding: 8px 0;
    max-width: initial;
    font-size: 12px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .wizard-status-bar__link {
    padding: 8px 0;
    max-width: 116px;
    font-size: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .wizard-status-bar__link {
    max-width: 156px;
    text-align: center;
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .wizard-status-bar__link {
    max-width: 220px;
  }
}

@media (min-width: 1024px) {
  .wizard-status-bar__link {
    max-width: 220px;
  }
}

.wizard-status-bar__link[disabled],
.wizard-status-bar__link[disabled]:hover,
.wizard-status-bar__link[disabled]:focus,
.wizard-status-bar__link[disabled]:active {
  color: #758896;
  cursor: default;
  user-select: none;
}

.wizard-status-bar__link::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  margin: 0 14px 0 0;
  box-shadow: 0 0 0.1px 2px #1696ff;
  border: 0.3em solid #fff;
  border-radius: 50%;
}

@media (min-width: 768px) and (max-width: 990px) {
  .wizard-status-bar__link::before {
    margin-left: 0;
  }
}

.wizard-status-bar__link[disabled]::before {
  box-shadow: 0 0 0.1px 2px #c4cdd5;
  background-color: #fff;
}

.nav-item:not(:last-of-type) .wizard-status-bar__link::before {
  background: url("../../../components/DropdownItem/check.svg") center no-repeat, #fff;
  background-size: contain;
}

.nav-item.current-step .wizard-status-bar__link::before {
  background: #1696ff;
}

.nav-item.current-step ~ .nav-item .wizard-status-bar__link::before {
  background: #fff;
}
