.popover__wrapper {
  position: relative;
}

.popover-inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 375px;
  height: 192px;
  border-radius: 4px;
  z-index: 10;
  border: 1px solid #e1e3e6;
}

.popover-inner::before,
.popover-inner::after {
  content: "";
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  margin-left: -8px;
}

.popover-inner::before {
  border-top: 8px solid #e1e3e6 !important;
  margin-top: 1px;
}

.popover-inner::after {
  border-top: 8px solid white;
  margin-top: 0;
  z-index: 1;
}

.popover-inner.bottom {
  top: 120%;
}

.popover-inner.bottom::before,
.popover-inner.bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(0) translateY(-50%);
  transform: rotate(180deg);
}

.popover-inner.bottom::before {
  margin-bottom: 1px;
}

.popover-inner.bottom::after {
  margin-bottom: 0px;
}