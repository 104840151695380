.choose-plan-banner {
    margin: 40px 0;
}

.choose-plan-banner__buttons {
    display: flex;
    margin-top: 16px;
}

.choose-plan-banner__buttons button + a {
    margin-left: 16px;
}

@media (max-width: 575px) {
    .choose-plan-banner__buttons {
        flex-direction: column;
    }

    .choose-plan-banner__buttons button + a {
        margin-left: 0;
        margin-top: 16px;
    }
}