.gl-onboarding-settings__verification-phone-number__content {
    font-size: 14px;
    color: #00223d;
    margin-bottom: 0;
}

.gl-onboarding-settings__verification-phone-number__link {
    font-size: 14px;
    color: rgba(22, 150, 255, 1);
    text-decoration-line: underline;
}

.gl-onboarding-settings__verification-phone-number__dropdown-wrapper {
    background: #f7f9fa;
    position: relative;
    padding: 8px 24px;
    display: flex;
    width: fit-content;
    margin-top: 22px;
}

.gl-onboarding-settings__verification-phone-number__dropdown-wrapper__title {
    margin-top: 6px;
    margin-right: 64px;
    font-size: 14px;
}

.gl-onboarding-settings__verification-phone-number__button {
    margin-top: 12px;
    float: right;
}
