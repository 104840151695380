.upgrade-plan__container {
  margin: 36px 0;
}

.upgrade-plan__wrapper {
  margin: 20px auto 0;
  padding: 40px;
  width: 1184px;
  color: rgba(0, 34, 61, 0.87);
  background-color: #fff;
  border: 1px solid #D6DADF;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.upgrade-plan__main-section {
  display: flex;
  justify-content: center;
}

.upgrade-plan__title {
  font-size: 64px;
  font-weight: 700;
  color: #07AE3A;
}

.upgrade-plan__subtitle {
  font-size: 24px;
  color: #758896;
  margin-bottom: 36px;
  font-weight: 300;
}

.upgrade-plan__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 252px;
  border: 1px solid #D6DADF;
  border-radius: 8px;
  padding: 20px;
  min-height: 420px;
}

.upgrade-plan__item--accent {
  border-color: #07AE3A;
}

.upgrade-plan__item h6 {
  font-size: 14px;
  font-weight: 300;
}

.upgrade-plan__item:not(:last-of-type) {
  margin-right: 32px;
}

.upgrade-plan__price {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  position: relative;
}

.upgrade-plan__price--accent {
  color: #07AE3A;
}

.upgrade-plan__price span {
  font-size: 20px;
  color: #758896;
  font-weight: 500;
}

.upgrade-plan__price span.upgrade-plan__price--old {
  color: #FEA038;
  text-decoration: line-through;
  position: absolute;
  top: -20px;
}

.upgrade-plan__description {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #758896;
  margin-bottom: 24px;
}

.upgrade-plan__list {
  list-style: none;
  padding: 0;
}

.upgrade-plan__list li {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 21px;
}

.upgrade-plan__list li span {
  color: #07AE3A;
}

.upgrade-plan__text {
  color: #758896;
  margin: 16px 0 0;
  font-size: 14px;
  line-height: 21px;
}

.upgrade-plan__text-link,
.upgrade-plan__text-link:hover,
.upgrade-plan__text-link:active {
  color: #33A3FF;
  font-weight: 300;
  text-decoration: none;
}

.upgrade-plan__text-link--underline {
  text-decoration: underline;
  color: #33A3FF;
}

.upgrade-plan__cta-button {
  margin-top: 8px;
}

.upgrade-plan__footer {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .upgrade-plan__title {
    font-size: 24px;
    padding: 0;
    max-width: 290px;
    text-align: center;
    margin: 24px auto 0;
  }

  .upgrade-plan__subtitle {
    font-size: 16px;
    padding: 0;
    max-width: 290px;
    text-align: center;
    margin: 24px auto 0;
  }

  .upgrade-plan__wrapper {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    max-width: 576px;
  }

  .upgrade-plan__main-section {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .upgrade-plan__item:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .upgrade-plan__description {
    margin-bottom: 24px;
    font-size: 13px;
  }

  .upgrade-plan__back-button {
    margin-left: 0;
  }

  .upgrade-plan__cta-button {
    position: relative;
    bottom: auto;
    left: auto;
  }

  .upgrade-plan__footer {
    flex-direction: column;
    max-width: 290px;
    padding: 0 16px 16px;
  }
}
