.choose-plan__icons {
    display: inline-flex;
    list-style-type: none;
    padding-left: 0;
    margin-left: 8px;
}

.choose-plan__icons li {
    margin-bottom: 0;
}

.choose-plan__icons-item + .choose-plan__icons-item {
    margin-left: 10px;
}