html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

html {
}

#root {
  height: 100%;
}

button {
  border: none;
}

.main-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f7fb;
}

.main-content {
  flex: 1 0 auto;
}

.main-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.main-container--lg {
  max-width: 1200px;
}

.main-container--lg .container {
  max-width: 1200px;
  padding: 0 8px;
}

@media (max-width: 1200px) {
  .main-container {
    box-sizing: border-box;
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .main-container .container {
    padding: 0;
  }
}
