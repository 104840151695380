.website-claimed__wrapper {
    margin: 0 auto;
    padding: 56px 16px;
    max-width: 656px;
}

.website-claimed__main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
}

.website-claimed__title {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 300;
}

.website-claimed__description {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 6px;
}

.website-claimed__checkbox {
    margin: 23px 5px 24px;
}

.website-claimed__buttons {
    display: flex;
}

.website-claimed__button {
    margin-right: 32px;
}

.website-claimed__text-link,
.website-claimed__text-link:hover,
.website-claimed__text-link:active {
    color: #1596ff;
    font-weight: 400;
    font-size: 14px;
    display: block;
}

.website-claimed__email {
    font-weight: 600;
}

@media (max-width: 576px) {
    .website-claimed__title {
        font-size: 19px;
        padding: 0;
        max-width: 290px;
        text-align: center;
        margin: 24px auto 0;
    }

    .website-claimed__wrapper {
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }

    .website-claimed__main-section {
        flex-direction: column;
        align-items: center;
        padding: 16px;
    }

    .website-claimed__description {
        margin-bottom: 24px;
        font-size: 13px;
    }
}