.pause-modal__dialog {
    position: absolute;
    top: 42%;
    right: 50%;
    transform: translate(50%, -40%);
    margin: 0;
    max-height: 90%;
    max-width: 500px;
    color: #00223D;
}

.pause-modal__dialog .pause-modal__close-button {
    position: absolute;
    top: 20px;
    right: 16px;
    display: inline-block;
    width: 14px;
    height: 11px;
    font-size: 0;
    vertical-align: middle;
    background: url("./close-icon.svg") no-repeat;
    background-position: 0 -11px;
    background-size: 80%;
    border: none;
    outline: none;
}

.pause-modal__dialog .pause-modal__close-button:focus {
    outline: none;
    border: none;
}
  
.pause-modal__dialog .pause-modal__close-button:hover {
    cursor: pointer;
    background-position: 0 0;
}
  
.pause-modal__dialog .c-modal__content {
    border: 1px solid #D6DADF;
    border-radius: 8px;
}
  
.pause-modal__header.c-modal-header {
    padding: 8px 16px;
    margin: 16px 24px 0;
    border: none;
}

.pause-modal__title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    margin: 0;
}

.pause-modal__body {
    padding: 8px 16px;
    margin: 8px 24px 0;
    border: none;
    max-width: 450px;
    font-size: 14px;
}

.pause-modal__footer {
    padding: 0 40px 24px;
    border: 0;
    justify-content: flex-start;
    gap: 8px;
}

.pause-modal__checkbox-label {
    margin: 8px 0 24px;
}

.pause-modal__button.button_link,
.pause-modal__button.button_link.button_transparent:active {
    font-size: 16px;
    text-decoration: none;
    color: #1596FF;
}
