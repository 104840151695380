.wizard-container {
  margin: 40px auto;
  font-family: sans-serif;
  max-width: 912px;
  border-radius: 0;
  border: none;
  background: none;
  box-shadow: 0 2px 26px 0 rgb(0 0 0 / 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.wizard-container--lg {
  max-width: 1200px;
}

.wizard-container--retargeting {
  box-shadow: none;
}

.wizard-container--retargeting .card-header {
  border-bottom: none;
}

@media (max-width: 576px) {
  .wizard-container {
    margin: 16px auto;
  }
}

.wizard-header {
  background: #fff;
  padding: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.wizard-body {
  padding: 0;
  background: #fff;
  flex: 1 1 auto;
}

.wizard-body--retargeting {
  background: #f6f7fb;
}

@media screen and (max-width: 576px) {
  .wizard-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.wizard-footer {
  background: #fff;
  font-size: 13px;
  border-bottom: 4px solid #fe9929;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid rgba(0,0,0,.125);
}

.wizard-footer,
.wizard-footer:last-child {
  border-radius: 0;
}

.wizard-footer__p-text {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: lighter;
  opacity: 0.8;
}

.wizard-footer__p-text > .tips__wrapper {
  height: auto;
  vertical-align: top;
}

.wizard-footer__p-text-first {
  font-size: 1.45em;
  width: 100%;
}

.wizard-footer__link {
  padding: 0;
  display: inline-block;
  background: transparent;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: #1696ff;
  text-decoration: none;
  outline: none;
}

.wizard-footer__link:hover,
.wizard-footer__link:focus {
  text-decoration: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.wizard-footer__button {
  margin: 8px auto 0;
  padding: 0 40px;
  display: block;
  height: 40px;
  background-color: rgb(26 40 68 / 0.96);
  border: none;
  border-radius: 2px;
  color: rgb(255 255 255 / 0.8);
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
}

.wizard-footer__button:hover,
.wizard-footer__button:focus {
  color: #28dcb4;
  cursor: pointer;
  outline: none;
}

.wizard-footer__button:active {
  color: #28dcb4;
  cursor: pointer;
  outline: none;
}

.wizard-footer-pricing-widget__tips-wrapper.tips__description .tooltip-inner {
  padding: 0;
  max-width: 320px;
  border-radius: 2px;
}

.wizard-wrapper {
  width: 100%;
}

@media (max-width: 991px) {
  .wizard-wrapper {
    max-width: 100%;
  }
}
