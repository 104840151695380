.dashboard-page-trial__block.dashboard-recommendation {
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  background-color: #06345a;
}

.dashboard-recommendation p {
  margin-right: 123px;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: sans-serif;
}

@media screen and (max-width: 767px) {
  .dashboard-recommendation p {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.dashboard-recommendation p::before {
  top: 4px;
  right: 8px;
}

.dashboard-recommendation div {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

@media screen and (max-width: 767px) {
  .dashboard-recommendation div {
    padding-bottom: 5px;
  }
}
