.special_offer-invite {
  width: 100%;
  padding: 24px 39px 24px 28px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #00223d;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.04);
  margin-bottom: 40px;
  position: relative;
  background: white url('./assets/special-offer-invite-v2.svg') no-repeat 100% 0;
  background-size: contain;
}


@media (max-width: 780px) {
    .special_offer-invite {
      background: white;
    }
}

.special_offer-invite-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 0;
}

.special_offer-invite__body {
  font-size: 13px;
  line-height: 20px;
  margin: 10px 0 24px;
  flex-basis: auto;
  font-weight: 400;
  max-width: 430px;
}

@media (max-width: 780px) {
  .special_offer-invite__body {
    max-width: 500px;
  }
}

.special_offer-invite-title {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 780px) and (min-width: 700px) {
  .special_offer-invite-title {
    font-size: 28px;
  }
}

.special_offer-invite-cta {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.special_offer-invite-cta a {
  margin-right: 24px;
  background: #07ae3a;
  border-color: transparent;
  box-shadow: none;
  border-radius: 4px;
}

@media (max-width: 420px) {
  .special_offer-invite-cta a {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .special_offer-invite-cta {
    flex-direction: column;
  }
}

.special_offer-invite-cta a:hover {
  background: #07ae3a;
}

.special_offer-invite-cta a:active {
  background: #07ae3a;
  opacity: 1;
}

.special_offer-invite-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  line-height: 11px;
  text-align: center;
  cursor: pointer;
  height: 11px;
  width: 11px;
  background-image: url("./assets/close-sign.svg");
  z-index: 25;
}

.special_offer-invite-cta .special_offer-invite-link {
  background: none;
  color: #00223d;
  font-weight: 300;
  font-size: 15px;
}

.special_offer-invite-cta button {
  margin-right: 24px;
}
