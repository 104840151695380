.modal-backdrop {
  background-color: #00223d !important;
}

.modal-special__dialog{
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -40%);
  margin: 0;
  width: 90%;
  max-width: 680px;
  max-height: 90%;
}

.modal.fade .modal-special__dialog {
  transform: translate(50%, -300%);
}

.modal-special__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  width: 14px;
  height: 14px;
  font-size: 0;
  vertical-align: middle;
  background: url("./close-icon.svg");
  border: none;
  outline: none;
  z-index: 1;
}

.modal-special__close-button:focus {
  outline: none;
  border: none;
}

.modal-special__close-button:hover {
  cursor: pointer;
}

.modal-special__dialog .c-modal__content,
.modal-special__dialog.c-modal__inner .c-modal__content {
  padding: 199px 54px 56px; /* 199 = image height (171) + bottom padding (28) */
  background: #fff url("modal-add-email-illustration.png") no-repeat center 20px;
  border: 1px solid #f0f1f5;
  border-radius: 2px;
  background-size: auto 171px;
}

@media (max-width: 767px) {
  .modal-special__dialog .c-modal__content,
  .modal-special__dialog.c-modal__inner .c-modal__content {
    padding: 175px 64px 24px; /* 175 = image height (147) + bottom padding (28) */
    background-size: auto 147px;
  }
}

@media (max-width: 576px) {
  .modal-special__dialog .c-modal__content,
  .modal-special__dialog.c-modal__inner .c-modal__content {
    padding: 155px 64px 24px; /* 155 = image height (127) + bottom padding (28) */
    background-size: auto 127px;
  }
}

@media (max-width: 480px) {
  .modal-special__dialog .c-modal__content,
  .modal-special__dialog.c-modal__inner .modal-content {
    padding: 125px 24px 24px; /* 155 = image height (97) + bottom padding (28) */
    background-size: auto 97px;
  }
}

.modal-special__header,
.modal-special__header.modal-header {
  margin: 0 auto 24px;
  padding: 0;
  border: none;
}

.modal-special__header .modal-title,
.modal-special__header.modal-header .modal-title {
  width: 100%;
  text-align: center;
}

.modal-special__title {
  margin: 0;
  font-size: 24px;
  color: #1f2f4d;
}

@media (max-width: 480px) {
  .modal-special__title {
    font-size: 22px;
    line-height: 1.25em;
  }
}

.modal-special__body,
.modal-special__body.modal-body {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
}

.modal-special-body__content {
  margin: 0 0 8px;
  color: #1f2f4d;
  font-size: 13px;
  font-weight: normal;
}

.modal-special__footer,
.modal-special__footer.modal-footer {
  margin: 0 auto;
  padding: 0;
  border: none;
  justify-content: center;
}

.modal-special__footer > :not(:last-child),
.modal-special__footer.modal-footer > :not(:last-child) {
  margin-right: 16px;
}

.modal-special__button {
  padding: 10px 20px;
  display: block;
  border: 1px solid;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(22 29 37 / 0.05);
  font-size: 13px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1em;
}

.modal-special__button:hover,
.modal-special__button:focus {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.modal-special__button:active {
  outline: none;
}

.modal-special__button_primary {
  background-color: #1696ff;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
}

.modal-special__button_primary:hover,
.modal-special__button_primary:focus {
  background-color: #1078cd;
  border-color: rgb(31 47 77 / 0.4);
  color: #fff;
}

.modal-special__button_primary:active {
  background-color: #0d62a7;
  color: #fff;
}

.modal-special-body__underline,
.modal-special-body__underline:hover,
.modal-special-body__underline:focus {
  color: #1f2f4d;
  text-decoration: underline;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.modal-special-body__underline:hover {
  cursor: pointer;
}
