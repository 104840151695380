.confirm-upgrade-plan__modal .modal-content {
    border-radius: 8px;
}

.confirm-upgrade-plan__modal {
    max-width: 500px;
}

.confirm-upgrade-plan__modal__body {
    width: 458px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px;
}

.confirm-upgrade-plan__modal__title {
    color: #00223D;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
}

.confirm-upgrade-plan__modal__content {
    color: rgba(0, 34, 61, 0.87);
    text-align: center;
    margin-bottom: 12px;
    font-size: 14px;
}

.confirm-upgrade-plan-cta-button {
    margin: 20px 16px 0 0;
}
