.gl-search {
    width: 266px;
    height: 172px;
    margin: 0 auto;
    background: url("img/background.svg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;
}

.gl-search::after {
    content: '';
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 75px;
    position: absolute;
    left: 0;
    bottom: 4px;
}

.gl-search__content {
    width: 187px;
    height: 36px;
    position: absolute;
    top: 55px;
    left: 53px;
    background: #ffffff;
}

.gl-search__content-url {
    display: flex;
    align-items: center;
}

.gl-search__ad-icon {
    padding: 2px;
    margin-right: 2px;
    font-size: 4px;
    line-height: 1;
    font-weight: bold;
    color: #202124;
}

.gl-search__content-title {
    font-size: 6px;
    line-height: 7px;
    margin-bottom: 2px;
    color: #1A0DAB;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gl-search__content-descr {
    font-size: 4px;
    line-height: 6px;
    color: #4D5156;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.gl-search__url {
    font-size: 4px;
    line-height: 1;
    color: #000000;
}

.gl-search__search {
    position: absolute;
    height: 10px;
    width: 150px;
    overflow: hidden;
    font-size: 6px;
    line-height: 7px;
    color: #000000;
    top: 11px;
    left: 53px;
    background: #ffffff;
}