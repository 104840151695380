.ad-preview {
  position: relative;
}

.ad-preview.ad-preview_disabled::before,
.ad-preview.ad-preview_disabled.card::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(255 255 255 / 0.6);
  z-index: 999;
}

.ad-preview-section__navbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

@media screen and (max-width: 480px) {
  .ad-preview-section-navbar__section_dropdown {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .ad-preview-section-navbar__section_link {
    display: none;
  }
}

.ad-preview-section-navbar__section_pagination .button:first-of-type {
  margin-right: 4px;
}

@media screen and (max-width: 480px) {
  .ad-preview-section-navbar__section_pagination {
    display: none;
  }
}

.ad-preview__frame-container {
  width: 100%;
  border: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ad-preview__frame-container_empty {
  min-height: 460px;
}

.ad-preview-section-iframe__content {
  margin: auto;
  text-align: center;
}

.ad-preview-pagination__title {
  margin-right: 8px;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
}

.ad-preview-dropdown__menu,
.ad-preview-dropdown__menu.dropdown-menu_list {
  padding: 0;
  width: 320px;
  background: #fff;
  border: 1px solid #c4cdd5;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.04);
  margin-top: -1px;
}

@media screen and (max-width: 480px) {
  .ad-preview-dropdown__menu,
  .ad-preview-dropdown__menu.dropdown-menu_list {
    width: 100%;
  }
}

.ad-preview__link,
.ad-preview__link.btn-link {
  padding: 0;
  background: transparent;
  border: none;
  color: #1696ff;
  font-size: 11px;
  font-weight: 300;
  text-decoration: none;
}

.ad-preview__link:hover,
.ad-preview__link:focus,
.ad-preview__link.btn-link:hover,
.ad-preview__link.btn-link:focus {
  color: #1378cc;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
}
