.home-preview {
    background: #F6F7FB;
    border: 1px solid #D6DADF;
    border-radius: 8px;
    padding: 24px;
    position: relative;
}

.home-preview__badge {
    color: #FFFFFF;
    position: absolute;
    left: 16px;
    top: -7px;
    font-size: 10px;
    line-height: 16px;
    padding: 0 8px;
    background: #A1ADB7;
    border-radius: 16px;

}

.home-preview__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.home-preview__top.home-preview__top--home {
    position: relative;
    justify-content: center;
}

.home-preview__top.home-preview__top--home .home-preview__title-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}

.home-preview__title-wrapper {
    display: flex;
    align-items: center;
}

.home-preview__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #00223D;
    margin-right: 4px;
}