.button {
  padding: 7px 16px;
  position: relative;
  background-image: linear-gradient(-180deg, #fff 0%, #f9fafb 100%);
  border: 1px solid #c4cdd5;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 #f2f4f5;
  color: #00223d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  outline: none;
}

.button_large {
  padding: 13px 30px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 21px;
  border-radius: 4px;
}

.button_medium {
  min-width: 80px;
}

.button_square {
  padding: 10px 11px;
  font-size: 12px;
  line-height: 12px;
  min-width: 36px;
}

.button_primary {
  border-color: #1270bc;
  color: #fff;
  background-image: linear-gradient(-179deg, #33a3ff 0%, #008cff 100%);
  box-shadow: 0 1px 0 0 #e7eef3;
}

.button_green {
  border-color: #07AE3A;
  color: #fff;
  background: #07AE3A;
  box-shadow: 0 1px 0 0 #e7eef3;
}

.button_yellow {
  border-color: #f4cd2b;
  color: #00223d;
  background: #f4cd2b;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.06);
}

.button_danger {
  border: none;
  color: #fff;
  background: rgb(254 22 22 / 1);
  box-shadow: 0 1px 0 0 #e7eef3;
}

.button_transparent {
  border-color: transparent;
  background: none;
  background-image: none;
  box-shadow: none;
  color: rgb(0 34 61 / 0.54);
}

.button_transparent-bordered {
  color: #00223d;
  background: none;
  border-color: #00223d;
  box-shadow: 0 1px 0 0 rgb(242 186 174 / 0.4);
}

.button_transparent-no-border {
  color: #00223d;
  background: none;
  border: none;
}

.button_light {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #28dcb4;
}

.button_link,
.button_link.button_transparent:active {
  font-size: 13px;
  color: rgb(0 34 61 / 0.54);
  text-decoration: underline;
  font-weight: 400;
  padding: 0;
}

.button_link_with-arrow::after {
  content: "→";
  position: absolute;
  display: block;
  top: calc(50% - 0.7em);
  right: -18px;
  font-size: 1em;
  height: 1em;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
  text-decoration: none;
  color: #00223d;
  outline: none;
}

.button_transparent:hover,
.button_transparent:focus {
  border-color: transparent;
  background: rgb(0 34 61 / 0.04);
  background-image: none;
  box-shadow: none;
}

.button_link.button_transparent:hover,
.button_link.button_transparent:focus {
  background: none;
  color: #1696ff;
  text-decoration: underline;
}

.button_transparent-bordered:hover,
.button_transparent-bordered:focus {
  background: rgb(0 34 61 / 0.03);
  box-shadow: 0 1px 0 0 rgb(0 34 61 / 0.08);
}

.button_primary:hover,
.button_primary:focus {
  background-image: linear-gradient(-179deg, #319df5 0%, #0087f5 100%);
  color: #fff;
}

.button_green:hover,
.button_green:focus {
  background-image: linear-gradient(-179deg, #07ae3a 0%, #0b9e46 100%);
  color: #fff;
}

.button_yellow:hover,
.button_yellow:focus {
  background: #f4cd2b;
  color: #00223d;
}

.button_danger:hover,
.button_danger:focus {
  background: rgb(254 22 22 / 1);
  color: #fff;
  border: none;
}

.button_light:hover,
.button_light:focus {
  background: rgb(255 255 255 / 0.3);
  border-color: transparent;
  box-shadow: none;
  color: #28dcb4;
}

.button:active {
  background-image: linear-gradient(-180deg, #fff 0%, #f3f5f7 100%);
  box-shadow: 0 1px 0 0 #f2f4f5, inset 0 1px 1px 0 rgb(31 47 77 / 0.6), inset 0 1px 4px 0 rgb(31 47 77 / 0.2);
}

.button_transparent:active {
  border-color: transparent;
  background-image: none;
  box-shadow: none;
}

.button_transparent-bordered:active {
  background: rgb(0 34 61 / 0.03);
  box-shadow:
    0 1px 0 0 rgb(0 34 61 / 0.08),
    inset 0 1px 1px 0 rgb(31 47 77 / 0.6),
    inset 0 1px 4px 0 rgb(31 47 77 / 0.2);
}

.button_primary:active {
  background-image: linear-gradient(-179deg, #319df5 0%, #0087f5 100%);
  border: 1px solid #11568f;
  box-shadow: 0 1px 0 0 #e7eef3, inset 0 1px 1px 0 rgb(31 47 77 / 0.6), inset 0 1px 4px 0 rgb(31 47 77 / 0.2);
}

.button_danger:active {
  background: rgb(254 22 22 / 1);
  border: none;
  color: #fff;
}

.button_light:active {
  background: rgb(255 255 255 / 0.3);
  background-image: none;
  border-color: transparent;
  box-shadow: none;
  color: #28dcb4;
}

.button_transparent-no-border:hover,
.button_transparent-no-border:active,
.button_transparent-no-border:focus {
  background: none;
  box-shadow: none;
  background-image: none;
}

.button:disabled,
.button_disabled,
.button:disabled:hover,
.button_disabled:active,
.button_disabled:hover,
.button:disabled:focus,
.button_disabled:focus {
  cursor: not-allowed;
  background-image: linear-gradient(-180deg, #f7f9fa 0%, #f1f4f6 100%);
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.05);
  outline: none;
  color: rgb(0 34 61 / 0.54);
  border-color: #c4cdd5;
  pointer-events: none;
}

.button_disabled i,
.button:disabled i {
  opacity: 0.54;
}

.button_transparent:disabled,
.button_transparent.button_disabled,
.button_transparent:disabled:hover,
.button_transparent.button_disabled:active,
.button_transparent.button_disabled:hover,
.button_transparent:disabled:focus,
.button_transparent.button_disabled:focus {
  cursor: not-allowed;
  border-color: transparent;
  background: none;
  background-image: none;
  box-shadow: none;
  outline: none;
  color: rgb(0 34 61 / 0.37);
}

.button_transparent-bordered:disabled,
.button_transparent-bordered.button_disabled,
.button_transparent-bordered:disabled:hover,
.button_transparent-bordered.button_disabled:active,
.button_transparent-bordered.button_disabled:hover,
.button_transparent-bordered:disabled:focus,
.button_transparent-bordered.button_disabled:focus {
  cursor: not-allowed;
  background: rgb(0 34 61 / 0.03);
  border-color: #b9b9bd;
  color: rgb(0 34 61 / 0.54);
  box-shadow: 0 1px 0 0 rgb(0 34 61 / 0.08);
}

.button_primary:disabled,
.button_primary.button_disabled,
.button_primary:disabled:hover,
.button_primary.button_disabled:active,
.button_primary.button_disabled:hover,
.button_primary:disabled:focus,
.button_primary.button_disabled:focus {
  background-image: linear-gradient(-180deg, #aed4f2 0%, #a9cfee 100%);
  border: 1px solid #5f94c0;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: #fff;
}

.button_green:disabled,
.button_green:active,
.button_green.button_disabled,
.button_green:disabled:hover,
.button_green.button_disabled:active,
.button_green.button_disabled:hover,
.button_green:disabled:focus,
.button_green.button_disabled:focus {
  background-image: linear-gradient(-179deg, #07ae3a 0%, #0b9e46 100%);
  opacity: 0.6;
  border-color: #069130;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: #fff;
}

.button_yellow:disabled,
.button_yellow:active,
.button_yellow.button_disabled,
.button_yellow:disabled:hover,
.button_yellow.button_disabled:active,
.button_yellow.button_disabled:hover,
.button_yellow:disabled:focus,
.button_yellow.button_disabled:focus {
  background: #f4cd2b;
  opacity: 0.6;
  border-color: #f4cd2b;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: #00223d;
}

.button_danger:disabled,
.button_danger.button_disabled,
.button_danger:disabled:hover,
.button_danger.button_disabled:active,
.button_danger.button_disabled:hover,
.button_danger:disabled:focus,
.button_danger.button_disabled:focus {
  background: rgb(254 22 22 / 0.6);
  color: #fff;
}

.button_light:disabled,
.button_light.button_disabled,
.button_light:disabled:hover,
.button_light.button_disabled:active,
.button_light.button_disabled:hover,
.button_light:disabled:focus,
.button_light.button_disabled:focus {
  color: rgb(40 220 180 / 0.56);
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

/* Campaign Details Loader Start */
.button_pending,
.button_pending:focus,
.button_pending:disabled {
  position: relative;
  cursor: default;
  pointer-events: none;
  background-image: linear-gradient(-180deg, #f7f9fa 0%, #f1f4f6 100%);
  box-shadow: 0 1px 0 0 rgb(22 29 37 / 0.05);
  outline: none;
  border-color: #c4cdd5;
  color: transparent;
}

.button_primary.button_pending,
.button_primary.button_pending:focus,
.button_primary.button_pending:disabled {
  background-image: linear-gradient(-180deg, #aed4f2 0%, #a9cfee 100%);
  border: 1px solid #5f94c0;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: transparent;
}

.button_green.button_pending,
.button_green.button_pending:focus,
.button_green.button_pending:disabled {
  background-image: linear-gradient(-179deg, #07ae3a 0%, #0b9e46 100%);
  opacity: 0.6;
  border-color: #069130;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: transparent;
}

.button_yellow.button_pending,
.button_yellow.button_pending:focus,
.button_yellow.button_pending:disabled {
  background-color: #f4cd2b;
  opacity: 0.6;
  border-color: #f4cd2b;
  box-shadow: 0 1px 0 0 #e7eef3;
  color: transparent;
}

.button_danger.button_pending,
.button_danger.button_pending:focus,
.button_danger.button_pending:disabled {
  background: rgb(254 22 22 / 0.6);
  color: transparent;
}

.button_light.button_pending,
.button_light.button_pending:focus,
.button_light.button_pending:disabled {
  color: transparent;
  background: rgb(255 255 255 / 0.3);
  border-color: transparent;
  box-shadow: none;
}

.button_transparent-bordered.button_pending,
.button_transparent-bordered.button_pending:focus,
.button_transparent-bordered.button_pending:disabled {
  background: rgb(0 34 61 / 0.03);
  border-color: #b9b9bd;
  box-shadow: 0 1px 0 0 rgb(0 34 61 / 0.08);
}

.button_transparent.button_pending,
.button_transparent.button_pending:focus,
.button_transparent.button_pending:disabled {
  background: rgb(0 34 61 / 0.03);
  border: none;
  box-shadow: 0 1px 0 0 rgb(0 34 61 / 0.08);
}

.button_pending:hover,
.button_pending:focus,
.button_pending:active {
  color: transparent;
}

.button_pending::before {
  content: "";
  position: absolute;
  left: calc(50% - 8px);
  bottom: calc(50% - 7px);
  width: 16px;
  height: 16px;
  background-color: #00223d;
  mask-image: url("spinner-icon.svg");
  mask-repeat: no-repeat;
  animation: load 0.8s linear infinite;
}

.button_pending.button_large::before {
  width: 24px;
  height: 24px;
  left: calc(50% - 12px);
  bottom: calc(50% - 12px);
  mask-image: url("spinner-icon-large.svg");
}

.button_primary.button_pending::before {
  background-color: #fff;
}

.button_danger.button_pending::before {
  background-color: #fff;
}

.button_light.button_pending::before {
  background-color: #28dcb4;
}

.button_transparent-bordered.button_pending::before {
  background-color: rgb(0 34 61 / 0.38);
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Campaign Details Loader End */

.custom-calendly-button .calendly-badge-widget {
  position: unset !important;
}

.custom-calendly-button .calendly-badge-widget .calendly-badge-content {
  border-radius: 2px;
  box-shadow: none;
  height: 36px;
}