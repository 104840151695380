.dropdown_wrapper {
    position: relative;
    max-width: 340px;
}

.dropdown-menu_list {
    position: absolute;
    margin-top: -9px;
    border: 1px solid #c4cdd5;
    width: 100%;
    overflow: hidden;
    max-height: 224px; 
    overflow-y: auto;
    z-index: 10;
}