.gl-mp {
    width: 266px;
    height: 196px;
    position: relative;
    background: url("img/google-maps-desktop.png") no-repeat center;
    background-size: 100% 100%;
    font-family: 'Roboto', sans-serif;
}

.gl-mp::after {
    content: '';
    position: absolute;
    width: 112px;
    height: 160px;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
}

.gl-mp::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 12px;
    width: 34px;
    height: 8px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
}

.gl-mp__content {
    background: #ffffff;
    position: absolute;
    font-size: 5px;
    line-height: 6px;
    left: 6px;
    top: 21px;
}

.gl-mp__url {
    font-size: 4px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gl-mp__url span {
    font-weight: 700;
    color: #202124;
    margin-right: 3px;
}
.gl-mp__title {
    font-weight: 700;
    color: #3C4043;
    margin-top: 1px;
    font-size: 4px;
}