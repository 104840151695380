.gl-onboarding-countries-dropdown-input-wrapper {
    display: flex;
    border: 1px solid #c4cdd5;
    border-radius: 2px;
    position: relative;
}

.gl-onboarding-countries-dropdown-input-wrapper_error {
    border: 1px solid #fe1616;
}

.gl-onboarding-countries-dropdown-input__error {
    color: #fe1616;
    font-size: 12px;
    margin-left: 12px;
    font-weight: 600;
}

.gl-onboarding-countries-dropdown-input-wrapper > input {
    border: none;
    font-weight: 100;
    width: 95px;
    letter-spacing: 1px;
}

.gl-onboarding-countries-dropdown-input-wrapper .button_square {
    border: none;
    box-shadow: none;
    border-right: 1px solid #c4cdd5;
}

.gl-onboarding-countries-dropdown__country {
    color: #203e56;
    font-size: 14px;
    margin-right: 8px;
}

.gl-onboarding-countries-dropdown__country-code {
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: #fff;
    font-size: 14px;
}

.gl-onboarding-countries-dropdown {
    position: static;
}

.dropdown-menu_list.ad-message-tag-dropdown__menu.gl-onboarding-countries-dropdown-list {
    left: 230px;
    height: fit-content;
    max-height: 280px;
    overflow-y: auto;
    width: 100%;
    transform: translate3d(0px, 32px, 0px) !important;
    margin-top: 0;
}

.gl-onboarding-countries-dropdown__country-input::-webkit-outer-spin-button,
.gl-onboarding-countries-dropdown__country-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.gl-onboarding-countries-dropdown__country-input[type='number'] {
    -moz-appearance: textfield;
    font-size: 14px;
}

.gl-onboarding-countries__search {
    border: 0;
    padding-left: 56px;
    border-bottom: 1px solid #d6dadf;
    width: 100%;
    background: #fff url('./img/search-icon.svg') 16px center no-repeat;
    font-size: 14px;
    position: sticky;
    top: 0;
    height: 56px;
    z-index: 1;
}

.gl-onboarding-countries__search::placeholder {
    color: rgba(0, 34, 61, 1);
    font-size: 14px;
}

.gl-onboarding-dropdown__item {
    height: 56px;
    border-bottom: 1px solid #d6dadf;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 34, 61, 1);
    font-size: 14px;
    padding: 0.25rem 1.5rem;
}

.gl-onboarding-dropdown__item__code {
    margin-left: 10px;
}

.gl-onboarding-dropdown__item_selected {
    background: #ecedf1 !important;
    position: relative;
    padding-left: 55px;
    scroll-margin-top: 56px;
}

.gl-onboarding-dropdown__item_selected::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 20px;
    width: 18px;
    height: 100%;
    background: url('./img/check.svg') 0 no-repeat;
    background-size: 18px;
}
