.datepicker__wrapper {
  position: absolute;
  top: -1px;
  right: 100%;
  background: #fff;
  width: 464px;
  padding: 16px 16px 24px;
  border: 1px solid #d6dadf;
  box-sizing: border-box;
  box-shadow: 0 2px 16px rgb(0 0 0 / 0.1);
  border-radius: 4px 0 0 4px;
}

.datepicker-selectable {
  font-size: 12px;
  height: 244px;
}

.datepicker-selectable .DayPicker-Caption > div {
  color: rgb(0 34 61);
}

.datepicker-selectable .DayPicker-Weekday {
  color: rgb(0 34 61 / 0.56);
}
/* stylelint-disable max-line-length */
.datepicker-selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgb(235 246 255);
  color: rgb(22 150 255);
  outline: none;
}
/* stylelint-enable max-line-length */

.datepicker-selectable .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: rgb(0 34 61);
  color: #f0f8ff;
  outline: none;
  font-weight: 400;
}

.datepicker-selectable .DayPicker-Day {
  border-radius: 0;
  color: rgb(0 34 61);
  padding: 0.45em 0.5em;
}

.datepicker-selectable .DayPicker-Day--disabled {
  color: rgb(0 34 61 / 0.37);
  cursor: default;
}

.datepicker-selectable .DayPicker-Day--today {
  color: rgb(22 150 255);
  box-sizing: border-box;
  padding: 0.4em;
}

.datepicker-selectable .DayPicker-Day--start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.datepicker-selectable .DayPicker-Day--end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.datepicker-buttons__wrapper {
  margin: 8px 0 0;
  display: flex;
  align-items: center;
}

.datepicker-buttons__wrapper button {
  margin: 0 10px;
  width: 88px;
}

.datepicker-buttons__wrapper p {
  margin: 0 0 0 24px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: rgb(0 34 61 / 0.56);
}
