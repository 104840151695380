.home__board__container {
    background: #fff;
    border: 1px solid #e3e3e3;
    box-shadow: 0 1px 0 rgba(214, 218, 223, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    padding: 32px;
}

.home-board-inner {
    display: flex;
}

.home__board__block {
    border: 1px solid #DADCDF;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 24px;
    max-width: 421px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: all .3s;
    position: relative;
}

.home__board__block__icon {
    width: 18px;
    height: 18px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #D6DADF;
    border-radius: 50%;
    transition: all .3s;
}

.home__board__block__icon + .home__board__block__icon {
    margin-left: 8px;
}

.home__board__block__icon img {
    max-width: 100%;
}

.home__board__block:not(.home__board__block--active) {
    cursor: pointer;
}

.home__board__block.home__board__block--active {
    border: 1px solid #0087F5;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
}

.home__board__block:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
}

.home__board__block__icons {
    position: absolute;
    left: 24px;
    top: -8px;
    display: flex;
}

.home__board__block.home__board__block--active .home__board__block__icon {
    border: 1px solid #0087F5;
}

.home__board__block.home__board__block--active .home__board__block__inner {
    max-height: 600px;
}

.home__board__block + .home__board__block {
    margin-top: 16px;
}

.home-board-inner__cards {
    margin-right: 32px;
    flex-basis: 418px;
}

.home-board-inner__media {
    flex-basis: calc(100% - 418px - 32px);
    min-width: 0;
}

.home-board-inner__preview {
    /*flex-basis: 400px;*/
    /*max-width: 400px;*/
    background: #F6F7FB;
    border: 1px solid #D6DADF;
    border-radius: 8px;
    padding: 24px;
}

.home__board__block__content__btn {
    position: relative;
    padding-left: 46px;
}

.home__board__block__content__btn-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 34px;
    height: 34px;
}

.home__board__block__content__btn-icon img {
    width: 100%;
    height: 100%;
}

.home__board__block__logo_meta {
    height: 20px;
    width: 100px;
    background: transparent url("./images/home.meta.logo.svg") no-repeat center;
}

.home__board__block__logo_google {
    height: 30px;
    width: 100px;
    background: transparent url("./images/home.google.logo.svg") no-repeat center;
}

.home__board__block__inner {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: all .3s;
}

.home__board__block__content {
    margin-bottom: 20px;
}

.home__board__block__content__title {
    color: #00223D;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    letter-spacing: -0.01em;
}

.home__board__block__content__text {
    color: #00223DDE;
    font-weight: normal;
    font-size: 14px;
    max-width: 316px;
}

.home__board__block__connect__button {
    border-radius: 50px;
    text-align: center;
    padding: 0 10px;
    height: 30px;
    font-size: 10px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home__board__block__connect__button_start {
    background: #EBF6FF;
    color: #289FFF;
}

.home__board__block__connect__button_progress {
    background: #FEF4E9;
    color: #FE9929;
}

.home__board__block__connect__button_finish {
    background: #E9FBE4;
    color: #07AE3A;

}

.home__board__block__connect__button_finish svg {
    margin-left: 4px;
}

.home__board__block__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}



.home__board {
    width: 912px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.home__board__tabs {
    display: flex;
}

.home__board__tab {
    border-radius: 7px 7px 0 0;
    padding: 16px 14px;
    width: 203px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #758796;
    cursor: not-allowed;
}

.home__board__tab_active {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    color: #00223D;
}

.home__banner {
    background: #FEF4E9;
    border: 1px solid #FFD59E;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    padding-bottom: 24px;
    max-width: 912px;
    width: 100%;
    margin: 0 auto;
    padding-right: 24px;
    padding-top: 6px;
    display: flex;
    border-top: 4px solid #FE9929;
    margin-bottom: 40px;
    margin-top: 40px;
    text-align: left;
}

.home__banner__title {
    color: #00223D;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 9px;
    margin-top: 5px;
}

.home__banner__text {
    color: #00223D;
    font-weight: normal;
    font-size: 14px;
}

.home__banner__button {
    margin-top: 20px;
}

.notification-icon {
    width: 80px;
    height: 52px;
    background: url("./images/warning-icon.svg") center no-repeat;
    background-size: 52px;
    border-color: #fe9929;
}

.campaign {
    background: #fff url('./images/adwisely-icon.svg') 14px no-repeat;
    padding-left: 35px;
}

.facebook {
    background: url('./images/facebook.tab.logo.svg') 28px no-repeat;
    padding-left: 40px;
    width: 270px;
    cursor: pointer;
}

.google {
    background: url('./images/google.tab.logo.svg') 37px no-repeat;
    padding-left: 40px;
    position: relative;
    width: 265px;
    cursor: pointer;
}

.home__banner__text ul {
    margin: 0;
    padding-left: 10px;
    margin-bottom: 16px;
}

.home__board__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 912px;
}

.home__board__loader {
    margin: 0 auto;
}

.home__board__tab__link {
    text-decoration: none !important;
}

.home__board__block__beta {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    border-radius: 8px;
    border: 1px solid #0087F5;
    width: 34px;
    height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #0087F5;
    position: absolute;
    right: -34px;
    top: -4px;
}


@media (max-width: 991px) {
    .home-board-inner {
        flex-direction: column;
        align-items: center;
    }

    .home__board {
        max-width: 100%;
    }

    .home__board__container {
        padding: 16px;
    }

    .home-board-inner__cards {
        margin-right: 0;
        flex-basis: auto;
        margin-bottom: 32px;
    }

    .home__board__block__content__title {
        font-size: 16px;
    }

    .home__board__block__header {
        margin-bottom: 8px;
    }

    .home__board__block {
        padding: 24px 16px;
    }

    .home-board-inner__preview {
        max-width: 100%;
    }
}

.home__block-label {
    margin:0 auto 24px;
    padding: 0 10px;
    background: #A1ADB7;
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    color: #FFFFFF;
    text-align: center;
    justify-content: center;
}

.campaign-advise {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #00223D;
    margin-bottom: 24px;
}

.campaign-advise button {
    color: #33A3FF;
    text-decoration: underline;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    padding-left: 2px;
}

.home__block-label.home__block-label--recommended {
    background: #07AE3A;
}

.home__block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__block.home__block--recommended .home__board__block.home__board__block--active,
.home__block.home__block--recommended .home__board__block,
.home__block.home__block--recommended .home__board__block.home__board__block--active .home__board__block__icon,
.home__block.home__block--recommended .home__board__block .home__board__block__icon {
    border: 1px solid #07AE3A
}

.home__block + .home__block {
    margin-top: 24px;
}