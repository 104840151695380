.instagram-desktop {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    max-width: 238px;
    margin: 0 auto;
}

.instagram-desktop__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
}

.instagram-desktop__avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #c4cdd5 url("../../img/star.svg") center no-repeat;
    margin-right: 6px;
}

.instagram-desktop__avatar-wrapper {
    display: flex;
    align-items: center;
}

.instagram-desktop__name {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 2px;
    font-weight: 500;
}

.instagram-desktop__description {
    font-size: 6px;
    line-height: 1;
}

.instagram-desktop__img-wrapper {
    height: 240px;
    width: 100%;
}

.instagram-desktop__img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.instagram-desktop__menu img {
    width: 100%;
    display: flex;
}

.instagram-desktop__post-info {
    font-size: 7px;
    line-height: 1.1;
    padding: 1px 3.5%;
    margin-top: -1px;
    background: #ffffff;
    position: relative;
    z-index: 2;
}

.instagram-desktop__author {
    font-weight: 600;
    margin-right: 4px;
}

.instagram-desktop__post-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.instagram-desktop__more {
    position: absolute;
    bottom: 1px;
    color: #363636;
    right: 3.5%;
    background: #ffffff;
}