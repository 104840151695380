.boost-banner {
    padding: 32px 24px 24px;
    margin-bottom: 40px;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top left;
}

.boost-banner--1 {
    background-image: url('./img/google-1.jpg');
}

.boost-banner--2 {
    background-image: url('./img/google-2.jpg');
}

.boost-banner--3 {
    background-image: url('./img/google-3.jpg');
}

.boost-banner--4 {
    background-image: url('./img/google-4.jpg');
}

.boost-banner--5 {
    background-image: url('./img/google-5.jpg');
}

.boost-banner__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
}

.boost-banner__text {
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
}

.boost-banner__features {
    display: flex;
    gap: 32px;
    margin-bottom: 28px;
}

.boost-banner__features-item {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 28px;
    color: #D0F61C;
    font-weight: 700;

}

.boost-banner__features-item span {
    font-size: 14px;
    line-height: 1;
    color: #fff;
    font-weight: 400;
}