.textarea-input-wrapper {
  position: relative;
  width: 100%;
}

.textarea-input {
  background: #fff;
  border: 1px solid #d6dadf;
  height: calc(15px + (1.5em * 3) + 15px);
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.25px;
  line-height: 21px;
  resize: none;
  padding-right: 46px;
}

.textarea-input:focus {
  border: 1px solid #1696ff;
  outline: none;
  box-shadow: 0 0 0 1px #1696ff;
}

.textarea-input_error,
.textarea-input_error:focus {
  border: 1px solid #fe1616;
  outline: none;
  box-shadow: 0 0 0 1px #fe1616;
}

.textarea-input:disabled,
.textarea-input.disabled {
  background: #f7f9fa;
  border: 1px solid #d6dadf;
  box-shadow: none;
  color: rgb(0 34 61 / 0.54);
}

.textarea-counter {
  position: absolute;
  bottom: 2px;
  right: 8px;
  font-size: 10px;
  color: rgb(0 34 61 / 0.54);
}

.textarea-counter_error {
  color: #fe1616;
}
