.campaign-info {
  position: relative;
}

.campaign-info__title {
  position: relative;
  margin: 0;
  line-height: 26px;
}

.campaign-info__title-img--special-offer {
  position: absolute;
  right: -55px;
  top: -15px;
}

.campaign-info__title-img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: auto;
  height: 25px;
}

.campaign-info__title--green {
  color: #07AE3A;
}

.campaign-info__link,
.campaign-info__link.btn-link {
  padding: 0;
  background: transparent;
  border: none;
  color: #1696ff;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  margin-right: 24px;
}

.campaign-info__link:hover,
.campaign-info__link:focus,
.campaign-info__link.btn-link:hover,
.campaign-info__link.btn-link:focus {
  color: #1378cc;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
}

.campaign-info__beta-badge {
  background: #00223d;
  color: #efffeb;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2px 12px;
  width: 60px;
  font-size: 8px;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 12px;
  display: inline-block;
  border-radius: 10px;
  position: absolute;
  left: 25px;
  top: -8px;
}
