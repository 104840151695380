.card-block {
  background: #fff;
  border: 1px solid #d6dadf;
  box-shadow: 0 1px 0 0 rgb(214 218 223 / 0.2), 0 2px 4px 0 rgb(0 0 0 / 0.04);
  padding: 16px 24px 24px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(0 34 61 / 0.87);
  letter-spacing: 0.25px;
  line-height: 21px;
}

.card-block_no-pad {
  padding: 0;
}
