.verification-notification {
    margin-top: 36px;
}

.notification__description.verification-notification-description {
    color: #00223D;
    margin-bottom: 20px;
}

.verification-notification__btn {
    margin-right: 16px;
}

.verification-notification-btns {
    margin-top: 20px;
}

.verification-notification-btns .button_transparent-no-border {
    box-shadow: none;
}