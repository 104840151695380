.ai-invite {
    padding: 32px 24px 24px;
    width: 100%;
    margin: 40px 0;
    color: #fff;
    background: url("./assets/illustration.png") no-repeat right -1px top / auto 100%, #1696FF;
    position: relative;
}

.ai-invite__content {
    max-width: 500px;
}

.ai-invite__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 12px;
}

.ai-invite__description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 24px;
}

.ai-invite__btns {
    display: flex;
    align-items: center;
    gap: 24px;
}

.ai-invite__close-btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}