.context-notification {
  width: 516px;
  border: 2px solid #1696ff;
  border-radius: 10px;
  display: flex;
  height: 100px;
  text-align: left;
}

.context-notification_warning {
  border: 2px solid #fe9929;
}

.context-notification__sign-block {
  width: 146px;
  background: #1696ff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.context-notification__sign-block_warning {
  background: #fe9929;
}

.context-notification__sign-block img {
  width: 58px;
}

.context-notification ul,
.context-notification p {
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.context-notification p {
  padding: 0 0 0 20px;
}

.context-notification ul li {
  text-align: left;
  margin: 1px 0;
}
